import React from 'react';
import * as D3 from 'd3';

const format = D3.format('.2f');

function Arc({ index, pieData, innerRadius, outerRadius, cornerRadius, colors }) {
  const arcGenerator = D3.arc()
    .innerRadius(innerRadius)
    .outerRadius(outerRadius)
    .cornerRadius(cornerRadius);
  const progressArc = (_pieData) =>
    arcGenerator({
      startAngle: _pieData.startAngle,
      endAngle: _pieData.endAngle
    });
  return (
    <g key={index} className="arc">
      <path className="arc" d={progressArc(pieData)} fill={colors(index)} />
    </g>
  );
}

function Pie(props) {
  const createPie = D3.pie()
    .value((d) => d.value)
    .sort(null);
  const createArc = D3.arc().innerRadius(props.innerRadius).outerRadius(props.outerRadius);
  const data = createPie(props.data);
  return (
    <svg width={props.width} height={props.height}>
      <g transform={`translate(${props.outerRadius} ${props.outerRadius})`}>
        {data.map((d, i) => (
          <Arc
            innerRadius={props.innerRadius}
            outerRadius={props.outerRadius}
            key={i}
            index={i}
            pieData={d}
            createArc={createArc}
            colors={props.colors}
            format={format}
            cornerRadius={0}
          />
        ))}
      </g>
    </svg>
  );
}

export default Pie;
