import React, { useContext } from 'react';
import styled from 'styled-components';
import { Speaker, BulletPoint } from 'components';
import { LocalContext, FirebaseContext } from 'context';

function Speakers({ speakers, colors }) {
  const { user } = useContext(FirebaseContext);
  const { theme } = useContext(LocalContext);

  return (
    <Container>
      {/* {user && (
        <BulletPoint
          top="1rem"
          left="1rem"
          content={`
              <p>Your speakers can be the main draw card of your event. Having strong speaker bio’s with pictures and a synopsis of their subject matter is vital.</p>
              <p>Consider a short video ‘promo’ from one or 2 of your Keynote speakers encouraging registration and promoting the key points of their presentation.</p>
              <p>Where you have permission, share links to your speakers social channels such as linked in , twitter etc which will help build their audience too.</p>
            `}
        />
      )} */}
      <H3 colors={colors}>
        Our Keynote Speakers
        <div />
      </H3>
      <div>
        {speakers.map((speaker) => (
          <Speaker
            key={speaker.id}
            data={speaker}
            colors={colors}
            buttonText="Read Overview"
            popupType="speaker"
            speakers={speakers}
            theme={theme}
          />
        ))}
      </div>
    </Container>
  );
}

const Container = styled.section`
  align-items: center;
  background: rgba(196, 196, 196, 0.1);
  display: flex;
  flex-direction: column;
  grid-column: 1/13;
  padding: 4.1rem 0 0;

  > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const H3 = styled.h3`
  align-items: center;
  color: #fff;
  display: inline-flex;
  flex-direction: column;
  font-size: 1.5rem;
  margin-bottom: 1.95rem;
  text-align: center;

  > div {
    background: ${({ colors }) => colors.primary};
    box-shadow: 0px 1px 7px #e3007d, 0px 0px 4px #db017b;
    height: 2px;
    margin: 8px 0 50px;
    width: 117.75%;
  }

  @media screen and (min-width: 620px) {
    font-size: 2.625rem;
  }
`;

export default Speakers;
