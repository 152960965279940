import * as React from 'react';

function SvgLinkedinIcon(props) {
  return (<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={12} cy={12} r={12} fill="#BDBDBD" />
    <path
      d="M9.336 16.68v-6.12H7.501v6.12h1.835Zm0-8.305c0-.546-.36-.947-.971-.947-.54 0-.936.401-.936.947 0 .547.36.947.936.947.612.037.971-.4.971-.947Zm3.06 8.305v-3.497c0-.182 0-.364.072-.51.144-.364.468-.764 1.044-.764.756 0 1.188.437 1.188 1.274v3.497h1.872v-3.57c0-1.93-1.008-2.804-2.34-2.804-1.116 0-1.584.62-1.872 1.056v-.801h-1.836c.036.583 0 6.155 0 6.155h1.872v-.036Z"
      fill="#fff"
    />
  </svg>)
}

export default SvgLinkedinIcon;
