const firebaseConfig = {
  apiKey: 'AIzaSyAUEhYnLs9XyvpURulaSWfyifHFCGx4LAE',
  authDomain: 'virtuosity-b21d5.firebaseapp.com',
  projectId: 'virtuosity-b21d5',
  databaseURL: 'https://virtuosity-realtime-db.europe-west1.firebasedatabase.app/',
  storageBucket: 'virtuosity-b21d5.appspot.com',
  messagingSenderId: '557351270915',
  appId: '1:557351270915:web:2e21af09eec239815f2c36'
};

export default firebaseConfig;
