import React, { useState, useEffect, useContext } from 'react';
import { FirebaseContext } from 'context';
import styled from 'styled-components';

import * as D3 from 'd3';
import Pie from '../Shared/PieChart';

const chartColors = [
  '#ED6E85',
  '#FF9559',
  '#54A1E5',
  '#6CBEBF',
  '#F8CD6B',
  '#613F75',
  '#003E1F',
  '#FCD581',
  '#FF9F1C',
  '#DC6ACF'
];
const colors = D3.scaleOrdinal(chartColors);

function AnalyticsChartSection({ eid }) {
  const [showCompanies, setShowCompanies] = useState(true);
  const [companiesList, setCompaniesList] = useState([]);
  const [professionsList, setProfessionsList] = useState([]);
  const [registereeProffessions, setRegistereeProfessions] = useState([]);
  const [registereeCompanies, setRegistereeCompanies] = useState([]);
  const { firebase } = useContext(FirebaseContext);

  useEffect(() => {
    let unsubscribeFromEventData;
    if (firebase) {
      unsubscribeFromEventData = firebase.subscribeToEventData({
        eid,
        snapshot: (snapshot) => {
          if (!snapshot.empty) {
            const _companiesList = [];
            const _professionsList = [];
            snapshot.forEach((doc) => {
              const { company, profession } = doc.data();
              _companiesList.push(company);
              _professionsList.push(profession);
            });
            setCompaniesList(_companiesList);
            setProfessionsList(_professionsList);
          }
        }
      });
    }
    return () => {
      if (unsubscribeFromEventData) {
        unsubscribeFromEventData();
      }
    };
  }, [firebase]);

  useEffect(() => {
    const companyCounts = companiesList.reduce((obj, company) => {
      obj[company] = obj[company] + 1 || 1;
      return obj;
    }, {});
    Object.entries(companyCounts).forEach((array) => {
      const [company, count] = array;
      setRegistereeCompanies((currentState) => [...currentState, { label: company, value: count }]);
    });
    return () => setRegistereeCompanies([]);
  }, [companiesList]);

  useEffect(() => {
    const professionCounts = professionsList.reduce((obj, profession) => {
      obj[profession] = obj[profession] + 1 || 1;
      return obj;
    }, {});
    Object.entries(professionCounts).forEach((array) => {
      const [profession, count] = array;
      setRegistereeProfessions((currentState) => [
        ...currentState,
        { label: profession, value: count }
      ]);
    });
    return () => setRegistereeProfessions([]);
  }, [professionsList]);

  const handleButtonToggle = (event) => {
    event.persist();
    setShowCompanies((prevState) => !prevState);
  };

  return (
    <Wrapper>
      <ToggleWrapper>
        <button
          type="button"
          onClick={handleButtonToggle}
          style={
            showCompanies
              ? { background: '#007fc0', color: 'white' }
              : { background: 'none', color: '#007fc0', border: '1px solid #007fc0' }
          }>
          Companies
        </button>
        <button
          type="button"
          onClick={handleButtonToggle}
          style={
            !showCompanies
              ? { background: '#007fc0', color: 'white' }
              : { background: 'none', color: '#007fc0', border: '1px solid #007fc0' }
          }>
          Profession
        </button>
      </ToggleWrapper>
      <p style={{ width: '100%', padding: '1.125rem' }}>
        Toggle between companies and industries to see participants breakdown.
      </p>
      <ChartWrapper>
        <Legends>
          {showCompanies
            ? registereeCompanies.map((d, index) => (
                <div style={{ display: 'flex', justifyContent: 'center' }} key={index}>
                  <Color style={{ background: colors(index), alignSelf: 'center' }} />
                  <p
                    style={{
                      fontSize: '12px',
                      margin: '0 0.5rem',
                      padding: '0',
                      width: '100%'
                    }}>
                    {d.label}
                  </p>
                </div>
              ))
            : registereeProffessions.map((d, i) => (
                <div style={{ display: 'flex', justifyContent: 'center' }} key={i}>
                  <Color style={{ background: colors(i), alignSelf: 'center' }} />
                  <p
                    style={{
                      fontSize: '12px',
                      margin: '0 0.5rem',
                      padding: 'none',
                      width: '100%'
                    }}>
                    {d.label}
                  </p>
                </div>
              ))}
        </Legends>
        <Pie
          data={showCompanies ? registereeCompanies : registereeProffessions}
          width={460}
          height={460}
          innerRadius={0}
          outerRadius={230}
          cornerRadius={0}
          colors={colors}
        />
      </ChartWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  p {
    width: 100%;
  }
`;

const ToggleWrapper = styled.div`
  display: flex;
  padding: 1.175rem;
  button {
    border: none;
    border-radius: 1.875rem;
    height: 3rem;
    margin: 0 0.625rem;
    outline: none;
    width: 7.5rem;
  }
`;

const ChartWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0rem 4.75rem 4.75rem 4.75rem;
  @media only screen and (max-width: 799px) {
    width: 100vw;
  }
`;

const Legends = styled.div`
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 3rem;
  > div {
    margin: 0 1rem 2rem 1rem;
  }
`;

const Color = styled.div`
  height: 0.625rem;
  min-width: 2.5rem;
`;
export default AnalyticsChartSection;
