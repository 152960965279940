import React, { useContext, useEffect, useState } from 'react';
import { LoadingSpinner } from 'components';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { FirebaseContext, LocalContext } from 'context';
import { hexToRGB, capitaliseFirstLetterOfText } from 'utils';
import { fadeInAndOutVariants } from 'styles';

const findPercentage = (partialValue, totalValue) => Math.round((100 * partialValue) / totalValue);

function AnalyticsModal({ poll, setIsAnalyticsModalOpen }) {
  const { selectedEvent } = useContext(LocalContext);
  const { eid } = selectedEvent;
  const { pid } = poll;
  const { firebase, user } = useContext(FirebaseContext);
  const [arePollAnalyticsLoading, setArePollAnalyticsLoading] = useState(true);
  const [pollAnalytics, setPollAnalytics] = useState(null);

  const pollHasCorrectAnswers = poll.answers.some((answer) => answer.isCorrect);

  useEffect(() => {
    let unsubscribeFromPollAnalytics;

    if (firebase) {
      unsubscribeFromPollAnalytics = firebase.subscribeToPollAnalytics({
        eid,
        pid,
        snapshot: (snapshot) => {
          if (snapshot.exists()) {
            const { answers, totalParticipants } = snapshot.val();
            const totalVotes = Object.values(answers).reduce((acc, num) => {
              const sum = acc + num;
              return sum;
            }, 0);

            setPollAnalytics({
              answers,
              totalVotes,
              totalParticipants
            });
            setArePollAnalyticsLoading(false);
          } else if (!snapshot.exists()) {
            // TODO: No analytics. Do we need to do anything here?
          }
        }
      });
    }
    return () => {
      if (unsubscribeFromPollAnalytics) {
        unsubscribeFromPollAnalytics();
      }
    };
  }, [firebase, eid, pid]);

  return (
    <Underlay
      data-underlay
      colors={selectedEvent.colors}
      onClick={(e) => {
        if (e.target.dataset.underlay === 'true' && user?.isModerator?.includes(eid)) {
          setIsAnalyticsModalOpen(false);
        }
      }}>
      <Modal colors={selectedEvent.colors} pollTimerEnabled={poll.timer.enabled}>
        {user && !user?.isModerator?.includes(eid) && (
          <Title colors={selectedEvent.colors}>Poll Results</Title>
        )}
        <Question colors={selectedEvent.colors}>
          <p>{capitaliseFirstLetterOfText(poll.question.text)}</p>
        </Question>
        <AnimatePresence>
          {pollAnalytics && (
            <ParticipantsAndAnswers
              variants={fadeInAndOutVariants()}
              initial="initial"
              animate="animate"
              exit="exit">
              <Participants>
                Participants: <span>{pollAnalytics.totalParticipants}</span>
              </Participants>
              {poll.answers.map((answer) => (
                <Answer
                  colors={selectedEvent.colors}
                  id={answer.id}
                  key={answer.id}
                  isCorrect={answer.isCorrect}
                  pollHasCorrectAnswers={pollHasCorrectAnswers}>
                  <div>
                    <p>{capitaliseFirstLetterOfText(answer.text)}</p>
                    <PercentageAndVotes>
                      <Percentage>
                        {pollAnalytics.totalVotes > 0 && pollAnalytics.answers[answer.id] > 0
                          ? findPercentage(
                              pollAnalytics.answers[answer.id],
                              pollAnalytics.totalVotes
                            )
                          : 0}
                        %&nbsp;
                      </Percentage>
                      <Votes>{`(${pollAnalytics.answers[answer.id] || 0} Votes)`}</Votes>
                    </PercentageAndVotes>
                  </div>
                  <Bar
                    colors={selectedEvent.colors}
                    isCorrect={answer.isCorrect}
                    pollHasCorrectAnswers={pollHasCorrectAnswers}
                    percentage={findPercentage(
                      pollAnalytics.answers[answer.id],
                      pollAnalytics.totalVotes
                    )}>
                    <div />
                  </Bar>
                </Answer>
              ))}
            </ParticipantsAndAnswers>
          )}
        </AnimatePresence>
        {arePollAnalyticsLoading && (
          <LoadingSpinnerWrapper
            variants={fadeInAndOutVariants()}
            initial="initial"
            animate="animate"
            exit="exit">
            <LoadingSpinner style={{ width: '6rem', color: selectedEvent.colors.secondary }} />
          </LoadingSpinnerWrapper>
        )}
      </Modal>
    </Underlay>
  );
}

const Underlay = styled(motion.div).attrs(fadeInAndOutVariants())`
  backdrop-filter: blur(2px);
  background: rgba(0, 0, 0, 0.6);
  height: 100%;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 1.625rem 1.625rem;
  position: absolute;
  top: 0;
  width: 100%;

  ::-webkit-scrollbar {
    width: 0.5rem;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0.31rem grey;
    border-radius: 0.625rem;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ colors }) => colors.secondary};
    border-radius: 0.625rem;
  }
`;

const Modal = styled.div`
  background-color: #fff;
  margin: 3rem auto 0;
  max-width: 23.75rem;
  position: relative;
`;

const Title = styled.div`
  background-color: ${({ colors }) => colors.secondary};
  color: #fff;
  font-weight: 700;
  padding: 0.75rem 1.5rem;
  text-align: center;
  width: 100%;
`;

const Question = styled.div`
  background-color: ${({ colors }) => hexToRGB({ color: colors.secondary, alpha: 0.2 })};
  color: ${({ colors }) => colors.secondary};
  padding: 0.75rem 1.5rem;
  width: 100%;

  p:nth-of-type(1) {
    font-weight: 700;
  }

  p:nth-of-type(2) {
    font-weight: 300;
  }
`;

const ParticipantsAndAnswers = styled(motion.div)`
  display: flex;
  flex-direction: column;
  padding: 1.1rem;
`;

const Participants = styled.p`
  color: #c4c4c4;
  line-height: 1.5rem;
  margin-bottom: 0.95rem;
  padding-left: 0.425rem;

  span {
    color: #000;
  }
`;

const Answer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.125rem;

  > div:first-of-type {
    display: flex;
    justify-content: space-between;
    padding-left: 0.425rem;

    p {
      color: #000;
    }
  }
`;

const PercentageAndVotes = styled.div`
  white-space: nowrap;

  span {
    font-size: 0.75rem;
  }
`;

const Percentage = styled.span`
  color: #000;
`;

const Votes = styled.span`
  color: #c4c4c4;
`;

const Bar = styled.div`
  background-color: rgba(196, 196, 196, 0.2);
  height: 0.25rem;
  margin-top: 0.375rem;
  width: 100%;

  > div {
    background-color: ${({ colors, pollHasCorrectAnswers, isCorrect }) =>
      pollHasCorrectAnswers && isCorrect
        ? 'rgba(51, 204, 102, 0.3)'
        : pollHasCorrectAnswers && !isCorrect
        ? 'rgba(245, 81, 81, 0.4)'
        : colors.secondary};
    height: 100%;
    transition: width 200ms ease-in-out;
    width: ${({ percentage }) => percentage || 0}%;
  }
`;

const LoadingSpinnerWrapper = styled(motion.div)`
  align-items: center;
  background-color: #fff;
  display: flex;
  height: 100%;
  justify-content: center;
  margin-top: 1.15rem;
  width: 100%;

  > svg {
    margin-bottom: 0.9rem;

    @media screen and (min-width: 410px) {
      margin-bottom: 1.125rem;
    }

    @media screen and (min-width: 1150px) and (max-width: 1365px) {
      margin-bottom: 0.9rem;
    }

    @media screen and (min-width: 1366px) {
      margin-bottom: 1.125rem;
    }
  }
`;

export default AnalyticsModal;
