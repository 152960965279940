import React, { useEffect, useState, useContext } from 'react';
import { Link, navigate } from 'gatsby';
import styled, { css } from 'styled-components';
import { GetBroadcastingLogo, LogoSmallContrast, User } from 'assets/svgs';
import {
  Modal,
  AccessibilityBar,
  RegistrationForm,
  LoginModal,
  UserProfile,
  BulletPoint,
  EventList
} from 'components';
import { LocalContext, FirebaseContext, SmootherContext } from 'context';
import { useLocation } from '@reach/router';
import { motion, AnimatePresence } from 'framer-motion';
import { isDesktop } from 'react-device-detect';
import { useWindowSize, useEventData } from 'hooks';
import { easeInOut } from '@popmotion/easing';
import { scrollToRef } from 'utils';
import { AnimatedEyeIcon, HelpIcon } from 'assets/animated-svgs';

function Navbar({ colors }) {
  const { edges: events } = useEventData();
  const {
    theme,
    isMobile,
    livestreamRef,
    homeRef,
    galleryRef,
    contactRef,
    videosRef,
    selectedPollDocumentation,
    setSelectedPollDocumentation
  } = useContext(LocalContext);
  const smoother = useContext(SmootherContext);
  const { windowWidth, windowHeight } = useWindowSize();
  const { pathname } = useLocation();
  const { user, firebase, loading } = useContext(FirebaseContext);
  const [navOpen, setNavOpen] = useState(false);
  const [themeToggleOpen, setThemeToggleOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [activeLink, setActiveLink] = useState(pathname);
  const [hoveredLink, setHoveredLink] = useState(pathname);
  let isMounted = true;
  const location = useLocation();

  useEffect(
    () => () => {
      isMounted = false;
    },
    []
  );

  useEffect(() => setActiveLink(pathname), [pathname]);

  const handleScroll = () => {
    const scrollNavTop = window.scrollY;
    if (scrollNavTop >= 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleNav = () => {
    setThemeToggleOpen(false);
    setNavOpen(!navOpen);
  };

  const toggleTheme = () => {
    setNavOpen(false);
    setThemeToggleOpen(!themeToggleOpen);
  };

  const toggleActiveLink = (link) => {
    toggleNav();
    setActiveLink(link);
  };

  const handleUnderlayClick = (e) => {
    if (isMobile && navOpen && Array.from(e.target.classList).includes('NavLinks')) {
      toggleNav();
    }
  };

  const handleLogout = () =>
    firebase
      .logout()
      .then(() => {
        document.querySelector('html').classList.remove('scroll-lock');
        navigate('/');
      })
      .catch((error) => isMounted && console.error(error.message));

  return (
    <>
      <StyledNavbar scrolled={isScrolled} isMobile={isMobile}>
        <a
          href="https://www.getbroadcasting.com/"
          style={{ gridColumn: '2/4', width: '136px' }}
          target="_blank"
          rel="noreferrer">
          <GetBroadcastingLogo />
        </a>
        <AnimatePresence>
          {(navOpen || !isMobile) && (
            <NavLinks
              $theme={theme}
              windowHeight={windowHeight}
              initial={isMobile ? { x: '100vw' } : { x: 0 }}
              animate={{ x: 0 }}
              exit={{ x: '100vw' }}
              className="NavLinks"
              transition={{ duration: 0.5, ease: easeInOut }}
              onClick={handleUnderlayClick}>
              {isMobile && !loading && firebase?.auth?.currentUser && user && (
                <LoginButton colors={colors}>
                  <Modal
                    hideCloseButton={isDesktop}
                    trigger={
                      <>
                        <p style={{ marginRight: '.625rem', fontWeight: '700' }}>
                          {user?.name?.split(' ')[0]}
                        </p>
                        {user.avatarUrl ? (
                          <ProfileImage src={user.avatarUrl} alt={user?.name} $theme={theme} />
                        ) : (
                          <User />
                        )}
                      </>
                    }
                    modalContent={<UserProfile handleLogout={handleLogout} />}
                  />
                </LoginButton>
              )}
              <NavLink
                to="/"
                $theme={theme}
                colors={colors}
                className={activeLink === '/' ? 'active' : null}
                onMouseEnter={() => !isMobile && setHoveredLink('/')}
                onMouseLeave={() => !isMobile && setHoveredLink('')}
                onClick={(e) => {
                  e.preventDefault();
                  toggleActiveLink('/');
                  if (location.pathname === '/') {
                    scrollToRef({ smoother, ref: homeRef, position: 'top top' });
                  } else {
                    navigate('/');
                  }
                }}>
                Home
                {!isMobile && (
                  <Underscore
                    colors={colors}
                    active={activeLink === '/'}
                    hovered={hoveredLink === '/'}
                  />
                )}
              </NavLink>
              {user &&
                (events?.length > 1 ? (
                  <EventList toggleNav={toggleNav} />
                ) : (
                  <NavLink
                    $theme={theme}
                    colors={colors}
                    to="/livestream"
                    className={activeLink === '/livestream' ? 'active' : null}
                    onClick={(e) => {
                      e.preventDefault();
                      toggleActiveLink('/livestream');
                      if (location.pathname === '/livestream') {
                        scrollToRef({ smoother, ref: livestreamRef, position: 'top top' });
                      } else {
                        navigate('/livestream');
                      }
                    }}
                    onMouseEnter={() => !isMobile && setHoveredLink('/livestream')}
                    onMouseLeave={() => !isMobile && setHoveredLink('')}>
                    Livestream
                    {!isMobile && (
                      <Underscore
                        colors={colors}
                        active={activeLink === '/livestream'}
                        hovered={hoveredLink === '/livestream'}
                      />
                    )}
                  </NavLink>
                ))}
              <NavLink
                $theme={theme}
                colors={colors}
                to="#videos"
                className={activeLink === 'videos' ? 'active' : null}
                onMouseEnter={() => !isMobile && setHoveredLink('videos')}
                onMouseLeave={() => !isMobile && setHoveredLink('')}
                onClick={(e) => {
                  e.preventDefault();
                  scrollToRef({ smoother, ref: videosRef, position: 'top 84px' });
                  toggleActiveLink('videos');
                }}>
                Videos
                {!isMobile && (
                  <Underscore
                    colors={colors}
                    active={activeLink === 'videos'}
                    hovered={hoveredLink === 'videos'}
                  />
                )}
              </NavLink>
              <NavLink
                $theme={theme}
                colors={colors}
                to="#contact"
                className={activeLink === 'contact' ? 'active' : null}
                onMouseEnter={() => !isMobile && setHoveredLink('contact')}
                onMouseLeave={() => !isMobile && setHoveredLink('')}
                onClick={(e) => {
                  e.preventDefault();
                  scrollToRef({ smoother, ref: contactRef, position: 'bottom bottom' });
                  toggleActiveLink('contact');
                }}>
                Contact
                {!isMobile && (
                  <Underscore
                    colors={colors}
                    active={activeLink === 'contact'}
                    hovered={hoveredLink === 'contact'}
                  />
                )}
              </NavLink>
              {isMobile && !loading && !firebase?.auth?.currentUser && !user && (
                <>
                  <RegisterButton colors={colors}>
                    <Modal
                      trigger={<p>Register</p>}
                      modalContent={<RegistrationForm colors={colors} />}
                    />
                  </RegisterButton>
                  <LoginButton colors={colors}>
                    <Modal
                      trigger={
                        <>
                          <p>Login </p>
                          <User />
                        </>
                      }
                      modalContent={<LoginModal colors={colors} />}
                    />
                  </LoginButton>
                </>
              )}
            </NavLinks>
          )}
        </AnimatePresence>
        {!isMobile &&
          !loading &&
          firebase &&
          (user ? (
            <UserIcon $theme={theme}>
              <Modal
                hideCloseButton={isDesktop}
                trigger={
                  <>
                    <p style={{ marginRight: '.625rem', fontWeight: '700' }}>
                      {user?.name?.split(' ')[0]}
                    </p>
                    {user.avatarUrl ? (
                      <ProfileImage src={user.avatarUrl} alt={user?.name} colors={colors} />
                    ) : (
                      <User />
                    )}
                  </>
                }
                modalContent={<UserProfile handleLogout={handleLogout} />}
              />
            </UserIcon>
          ) : (
            <UserIcon $theme={theme}>
              <Modal
                transparentCloseButtonBackground
                hideCloseButton
                trigger={
                  <>
                    <p style={{ marginRight: '.625rem', fontWeight: '700' }}>Login</p>
                    <User />
                  </>
                }
                modalContent={<LoginModal colors={colors} />}
              />
            </UserIcon>
          ))}
        {/* <HelpHub
          to="/help-hub"
          $theme={theme}
          className={activeLink === '/help-hub' ? 'active' : null}
          onClick={() => {
            toggleActiveLink('/help-hub');
          }}>
          <HelpIcon colors={colors} />
        </HelpHub> */}
        {location.hostname === 'panacea.eventxlive.com' && user && (
          <BulletPoint top=".5rem" right="1rem" width="11rem" content="<p>Accessibility Bar</p>" />
        )}
        {/* <ThemeToggle onClick={() => toggleTheme()} open={themeToggleOpen} $theme={theme}>
          <AnimatedEyeIcon />
        </ThemeToggle>
        <AnimatePresence>{themeToggleOpen && <AccessibilityBar />}</AnimatePresence> */}
        <AnimatePresence>
          {windowWidth < 1150 && (
            <NavToggler onClick={() => toggleNav()} colors={colors} aria-label="Menu">
              <NavBurger $theme={theme} navOpen={navOpen}>
                <BurgerBar $theme={theme} navOpen={navOpen} />
                <BurgerBar $theme={theme} navOpen={navOpen} />
                <BurgerBar $theme={theme} navOpen={navOpen} />
              </NavBurger>
            </NavToggler>
          )}
        </AnimatePresence>
      </StyledNavbar>
      {/* {pathname.includes('help-hub') && (
        <SecondaryNavigationBar
          theme={theme}
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          scrolled={isScrolled}
          themeToggle={themeToggleOpen}
          $theme={theme}>
          <HelpHubLink to="/help-hub" theme={theme}>
            Help Hub
          </HelpHubLink>
          {(pathname === '/help-hub/q-and-a' || pathname === '/help-hub/polls') && (
            <>
              <p style={{ margin: '0 0.5rem', fontSize: '1.25rem' }}>{'>'}</p>
              <HelpHubLink
                to={pathname === '/help-hub/q-and-a' ? '/help-hub/q-and-a' : '/help-hub/polls'}
                theme={theme}
                style={{
                  textDecoration: 'underline',
                  textUnderlinePosition: 'under',
                  fontWeight: '500'
                }}>
                {pathname === '/help-hub/q-and-a' ? 'Q&A Guidelines' : 'Poll Guidelines'}
              </HelpHubLink>
            </>
          )}
          {pathname === '/help-hub/polls' && (
            <div>
              <HelpHubButton
                type="button"
                onClick={() => setSelectedPollDocumentation('general')}
                active={selectedPollDocumentation === 'general'}
                theme={theme}>
                GENERAL POLL
              </HelpHubButton>
              <HelpHubButton
                type="button"
                onClick={() => setSelectedPollDocumentation('feedback')}
                active={selectedPollDocumentation === 'feedback'}
                theme={theme}>
                FEEDBACK POLL
              </HelpHubButton>
              <HelpHubButton
                type="button"
                onClick={() => setSelectedPollDocumentation('wordcloud')}
                active={selectedPollDocumentation === 'wordcloud'}
                theme={theme}>
                WORD CLOUD
              </HelpHubButton>
            </div>
          )}
        </SecondaryNavigationBar>
      )} */}
    </>
  );
}

const ProfileImage = styled.img`
  background: #c4c4c4;
  border: 0.125rem solid ${({ colors }) => colors.primary};
  border-radius: 50%;
  height: 1.625rem;
  object-fit: cover;
  width: 1.625rem;
`;

const RegisterButton = styled.div`
  background: ${({ colors }) => colors.primary};
  border: none;
  color: #fff;
  cursor: pointer;
  font-family: Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 3rem;
  outline: none;
  padding-top: 0.125rem;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
`;

const LoginButton = styled.button`
  background: ${({ colors }) => colors.secondary};
  border: none;
  cursor: pointer;
  font-family: Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 3rem;
  max-width: 100%;
  outline: none;
  padding-top: 0.125rem;
  text-align: center;
  text-transform: uppercase;
  width: 100%;

  div {
    align-items: center;
    color: #fff;
    display: flex;
    justify-content: center;
  }

  svg {
    margin-left: 0.5em;
    max-height: 1.25rem;

    path {
      fill: #fff;
    }

    circle {
      stroke: #fff;
    }
  }

  @media only screen and (min-width: 1150px) {
    background: transparent;
    justify-content: flex-end;
  }
`;

const UserIcon = styled.button`
  background: transparent;
  border: 0;
  cursor: pointer;
  grid-column: 11/12;
  height: 100%;
  outline: 0;
  width: 100%;

  div {
    align-items: center;
    color: ${({ $theme }) => $theme.textColor};
    display: flex;
    font-family: Helvetica, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    justify-content: center;
    svg {
      max-height: 1.25rem;
    }
  }

  @media only screen and (min-width: 1150px) {
    justify-content: end;

    div {
      height: 100%;
      justify-content: flex-end;
      width: 100%;
    }
  }

  svg {
    circle {
      stroke: ${({ $theme }) => $theme.textColor};
    }
    path {
      fill: ${({ $theme }) => $theme.textColor};
    }
  }
`;

const NavToggler = styled(motion.button).attrs({
  initial: {
    opacity: 0
  },
  animate: {
    opacity: 1
  },
  exit: {
    opacity: 0
  }
})`
  align-items: center;
  background: ${({ colors }) => colors.secondary};
  cursor: pointer;
  display: flex;
  height: 3.75rem;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  width: 3.75rem;
`;

const NavBurger = styled.div`
  height: 1rem;
  position: relative;
  width: ${({ navOpen }) => (navOpen ? '1rem' : '1.25rem')};
`;

const BurgerBar = styled.span`
  background: ${({ $theme }) => $theme.contrastTextColor};
  content: '';
  display: block;
  height: 0.185rem;
  left: 0;
  position: absolute;
  transform-origin: center center;
  transition: 0.375s;
  width: 1.25rem;
  &:first-child {
    top: ${({ navOpen }) => (navOpen === true ? '50%' : '0')};
    transform: ${({ navOpen }) => (navOpen === true ? 'rotate(45deg)' : 'none')};
  }
  &:nth-child(2) {
    opacity: ${({ navOpen }) => (navOpen === true ? '0' : '1')};
    top: 50%;
    transform: translateY(-50%);
  }
  &:last-child {
    bottom: ${({ navOpen }) => (navOpen === true ? 'initial' : '0')};
    top: ${({ navOpen }) => (navOpen === true ? '50%' : 'initial')};
    transform: ${({ navOpen }) => (navOpen === true ? 'rotate(-45deg)' : 'none')};
  }
`;

const ThemeToggle = styled.div`
  align-items: center;
  background: ${({ open, $theme }) => (open ? $theme.bgLight : 'transparent')};
  cursor: pointer;
  display: flex;
  height: 3.75rem;
  justify-content: center;
  justify-self: end;
  position: relative;
  transition: 0.375s;
  width: 3.75rem;
  z-index: 1;
  svg {
    height: 1.25rem;
    max-height: 1.25rem;
    path {
      stroke: ${({ $theme }) => $theme.textColor};
      :last-child {
        fill: ${({ $theme }) => $theme.textColor};
      }
    }
  }
  @media only screen and (min-width: 1150px) {
    grid-column: 13/14;
    height: 4rem;
    justify-self: start;
    width: 4rem;
  }
`;

const HelpHub = styled(Link)`
  align-items: center;
  background: ${({ open, $theme }) => (open ? $theme.bgLight : 'transparent')};
  cursor: pointer;
  display: flex;
  height: 3.75rem;
  justify-content: center;
  justify-self: end;
  position: relative;
  transition: 0.375s;
  width: 3.75rem;
  z-index: 1;
  svg {
    height: 1.25rem;
    max-height: 1.25rem;
    path {
      stroke: ${({ $theme }) => $theme.textColor};
      :last-child {
        fill: ${({ $theme }) => $theme.textColor};
      }
    }
  }
  @media only screen and (min-width: 1150px) {
    grid-column: 12/13;
    height: 4rem;
    justify-self: center;
    width: 100%;
  }
`;

const NavLinks = styled(motion.div)`
  align-items: center;
  backdrop-filter: blur(2px);
  box-shadow: 0px 0.185rem 0.75rem rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  grid-column: 1/13;
  height: 100vh;
  justify-content: flex-start;
  position: absolute;
  top: 60px;
  width: 100%;

  @media only screen and (min-width: 1150px) {
    align-items: center;
    background: transparent;
    box-shadow: none;
    flex-direction: row;
    grid-column: 4/10;
    height: 4rem;
    top: unset;
    justify-content: space-around;
  }
`;

const NavLink = styled(Link)`
  align-items: center;
  background-color: ${({ colors }) => colors.secondary};
  color: #fff;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-family: Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  height: 3rem;
  justify-content: center;
  letter-spacing: 0.5px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  width: 100%;

  @media only screen and (min-width: 1150px) {
    background-color: transparent;
    border: 0px solid transparent;
    border-radius: 0.375em;
    transition: border 0.15s ease-in-out;
    height: unset;
    padding: 0.375rem 1.3125rem;
    line-height: 1.5rem;
    width: auto;

    &.active {
      border: 0.125em solid ${({ colors }) => colors.primary};
      filter: drop-shadow(0px 0px 0.875em ${({ colors }) => colors.primary});
      color: ${({ colors }) => colors.primary};
    }
  }
`;

const Underscore = styled.span`
  animation: ${({ hovered, active }) => (hovered || active ? 'reveal' : 'hide')} 0.15s ease-in-out
    forwards;
  background-color: ${({ colors }) => colors.primary};
  bottom: ${({ active }) => (active ? '-0.75em' : '0')};
  box-shadow: ${({ colors }) => `0px 0.063em 0.438em #e3007d, 0px 0px 0.25em ${colors.primary}`};
  height: 0.125em;
  position: absolute;
  transition: bottom 0.15s ease-in-out;

  @keyframes reveal {
    from {
      width: 0%;
    }

    to {
      width: 67.95%;
    }
  }

  @keyframes hide {
    from {
      width: 67.95%;
    }

    to {
      width: 0%;
    }
  }
`;

const StyledNavbar = styled(motion.nav).attrs({
  initial: { opacity: 0, y: -50 },
  animate: { opacity: 1, y: 0 }
})`
  align-items: center;
  background: ${({ scrolled, isMobile }) => (scrolled || isMobile ? 'black' : 'transparent')};
  box-shadow: ${({ scrolled, isMobile }) =>
    scrolled || isMobile ? '0px 0.185rem 0.75rem rgba(0, 0, 0, 0.25)' : 'transparent'};
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  height: 3.75rem;
  left: 0;
  position: fixed;
  top: 0;
  transition-duration: 0.75s;
  width: 100%;
  z-index: 2;

  @media only screen and (min-width: 1150px) {
    box-shadow: ${({ scrolled }) =>
      scrolled ? '0px .185rem .75rem rgba(0,0,0,.25)' : 'none'} !important;
    grid-column-gap: 20px;
    /* padding: 0 20px; */
    height: ${({ scrolled }) => (scrolled ? '5.25rem' : '7.35rem')};
  }
`;

const SecondaryNavigationBar = styled(motion.nav)`
  align-items: center;
  background: ${({ theme }) => theme.fg};
  color: ${({ theme }) => theme.contrastTextColor};
  display: flex;
  flex-direction: row;
  height: 3.75rem;
  justify-content: flex-start;
  padding: 0 8.25rem;
  position: fixed;
  top: 3.75rem;
  width: 100vw;
  z-index: 4;
  div {
    display: flex;
    justify-content: space-between;
    margin-left: auto;
  }
`;

const HelpHubButton = styled.button`
  background: transparent;
  color: ${({ theme }) => theme.contrastTextColor};
  cursor: pointer;
  font-weight: 700;
  margin: 0 1rem;
  ${(props) =>
    props.active &&
    css`
      text-decoration: underline;
      text-underline-position: under;
    `}
  &:hover {
    text-decoration: underline;
    text-underline-position: under;
  }
`;

const HelpHubLink = styled(Link)`
  color: ${({ theme }) => theme.contrastTextColor};
  font-size: 1.25rem;
  font-weight: 400;
`;

export default Navbar;
