import React from 'react';
import styled from 'styled-components';

function ModalTrigger({ buttonRef, trigger, showModal }) {
  return (
    <ModalButton ref={buttonRef} onClick={showModal}>
      {trigger}
    </ModalButton>
  );
}

const ModalButton = styled.div`
  background: transparent;
  cursor: pointer;
`;

export default ModalTrigger;
