import * as React from 'react';

function SvgFbFooterIcon(props) {
  return (<svg viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#fb_footer_icon_svg__a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.6 8.1v7.7c0 .1.1.2.2.2h2.9c.1 0 .2-.1.2-.2V7.9H7c.1 0 .2-.1.2-.2l.2-2.4c0-.1-.1-.2-.2-.2H4.9V3.5c0-.4.3-.7.7-.7h1.6c.1 0 .2-.1.2-.2V.2c0-.1-.1-.2-.2-.2H4.5C2.9 0 1.6 1.3 1.6 2.9v2.3H.2c-.1 0-.2.1-.2.2v2.4c0 .1.1.2.2.2h1.4v.1Z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="fb_footer_icon_svg__a">
        <path fill="#fff" d="M0 0h7.4v16H0z" />
      </clipPath>
    </defs>
  </svg>)
}

export default SvgFbFooterIcon;
