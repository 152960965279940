import * as React from 'react';

function SvgUser(props) {
  return (<svg viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15.443 11.841c.925-.703 1.52-1.79 1.52-3.005C16.962 6.726 15.178 5 13 5c-2.18 0-3.962 1.726-3.962 3.836 0 1.215.594 2.302 1.519 3.005C7.915 12.801 6 15.294 6 18.171c0 3.772 14 3.772 14 0 0-2.877-1.85-5.37-4.557-6.33Z"
      fill="#C4C4C4"
    />
    <circle cx={13} cy={13} r={12.5} stroke="#C4C4C4" />
  </svg>)
}

export default SvgUser;
