import * as React from 'react';

function SvgAgencyXLogo(props) {
  return (<svg viewBox="0 0 211 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M41.042 0c3.838 0 6.626 1.539 8.749 3.954L48.29 5.022C46.66 3.077 44 1.795 41.042 1.795c-5.468 0-9.692 4.125-9.692 10.173 0 5.984 4.224 10.215 9.692 10.215 3.11 0 5.554-1.538 6.84-2.82V13.87h-9.627l.793-1.817h10.807v8.1C47.754 22.44 44.687 24 41.042 24c-6.562 0-11.794-4.873-11.794-12.032C29.248 4.83 34.48 0 41.042 0ZM90.361 3.548V23.55h-1.994V.385h2.016l14.581 19.725V.385h1.994V23.55h-1.951L90.361 3.548ZM118.344 11.99c0-7.117 5.19-11.99 11.708-11.99 3.774 0 6.562 1.624 8.535 4.103l-1.673 1.069c-1.458-2.009-4.01-3.377-6.862-3.377-5.403 0-9.628 4.125-9.628 10.173 0 5.984 4.225 10.173 9.628 10.173 2.852 0 5.404-1.347 6.862-3.377l1.673 1.047c-2.059 2.543-4.782 4.125-8.535 4.125-6.518.043-11.708-4.83-11.708-11.947ZM155.505 13.656 146.263.385h2.402l7.848 11.39 7.848-11.39h2.402l-9.242 13.271v9.895h-2.016v-9.895ZM11.987.385H9.52L0 23.55h2.23L10.743 2.33l5.575 13.72H9.414l-.751 1.816h8.277l2.337 5.685h2.23L11.987.385ZM76.83.385H61.307V2.2H76.83V.385ZM72.842 10.386H61.306v1.795h10.743l.793-1.795ZM76.83 21.756H61.307v1.816H76.83v-1.816Z"
      fill="#fff"
    />
    <path d="m198.113 13.037-8.342 10.557h2.509l7.055-9.126-1.222-1.431Z" fill="#CE9200" />
    <path
      d="M201.651 11.648 210.485.385h-2.508l-7.57 9.83-7.526-9.83h-2.552l18.205 23.166H211l-9.349-11.903Z"
      fill="#fff"
    />
  </svg>)
}

export default SvgAgencyXLogo;
