import React, { useEffect, useState, useContext, useRef } from 'react';
import styled from 'styled-components';
import { LivestreamOverlays } from 'components';
import { FirebaseContext, LocalContext } from 'context';
import { useIntersection } from 'hooks';
import { motion } from 'framer-motion';
import Vimeo from '@vimeo/player';
import throttle from 'just-throttle';

function Livestream({
  eid,
  colors,
  eventName,
  streamUrl,
  questionCurrentlyBeingAnsweredLive,
  currentlyOpenWordCloudPollId
}) {
  const [vimeoPlayer, setVimeoPlayer] = useState(null);
  const { livestreamRef } = useContext(LocalContext);
  const [livestreamIsPlaying, setLivestreamIsPlaying] = useState(false);
  const [pipRef, vimeoPlayerObserver] = useIntersection({
    initialInView: true,
    threshold: 0.75
  });
  const [isPipModeEnabled, setIsPipModeEnabled] = useState(false);
  const { firebase, user } = useContext(FirebaseContext);

  useEffect(() => {
    const livestreamIframe = document.getElementById('livestream-iframe');

    if (livestreamIframe && user && eid) {
      const _vimeoPlayer = new Vimeo(livestreamIframe);

      setVimeoPlayer(_vimeoPlayer);

      // I'm defining a video "session" as a discrete period of time someone is on the page watching
      // a video. If someone watches an hour one day, then navigates away and comes back to the same
      // page the next day, then those are two seperate sessions. Likewise, if they watch 20 mins
      // then navigate to another part of the site and then come back to the same page again and
      // watches another 20 mins, then those are two seperate sessions. But if someone watches 20
      // mins and pauses the video, then unpauses and continues watching, then that's a single
      // session. Likewise, if someone watches 20 mins and then fast-forwards an hour and continues
      // watching, then that's also a single session.
      const updateVideoSessionData = async () => {
        const data = await _vimeoPlayer.getPlayed();

        const timeRange = data.map((arr) => ({
          start: parseFloat(arr[0].toFixed(2)),
          end: parseFloat(arr[1].toFixed(2))
        }));

        return firebase.updateVideoSessionData({
          uid: user.uid,
          eid,
          timeRange
        });
      };

      _vimeoPlayer.on('loaded', () => {
        _vimeoPlayer.on('timeupdate', throttle(updateVideoSessionData, 60000));
        _vimeoPlayer.on('play', (data) => {
          // Using an 'if' statement here because when the 'play' event is fired
          // after any 'seek' event 'data' is undefined.
          if (data) {
            setLivestreamIsPlaying(true);
          }
        });
        _vimeoPlayer.on('pause', (data) => {
          setLivestreamIsPlaying(false);

          // The 'pause' event is also fired when the video ends, along with the 'ended' event.
          // We want to ignore it when the video has ended, so we'll check the percent value.
          if (data.percent !== 1) {
            updateVideoSessionData();
          }
        });
        _vimeoPlayer.on('seeked', updateVideoSessionData);
        _vimeoPlayer.on('ended', updateVideoSessionData);

        _vimeoPlayer.on('enterpictureinpicture', () => {
          setIsPipModeEnabled(true);
        });
        _vimeoPlayer.on('leavepictureinpicture', () => {
          setIsPipModeEnabled(false);
        });
      });
    }

    return () => {
      // document.removeEventListener('keydown', keyDownListener);
      vimeoPlayer?.off('timeupdate');
      vimeoPlayer?.off('play');
      vimeoPlayer?.off('pause');
      vimeoPlayer?.off('seeked');
      vimeoPlayer?.off('ended');
      vimeoPlayer?.off('enterpictureinpicture');
      vimeoPlayer?.off('leavepictureinpicture');
    };
  }, [user, eid]);

  useEffect(() => {
    if (
      document.pictureInPictureEnabled &&
      vimeoPlayer &&
      !vimeoPlayer.disablePictureInPicture &&
      livestreamIsPlaying &&
      !vimeoPlayerObserver.isIntersecting
    ) {
      try {
        vimeoPlayer.requestPictureInPicture();
      } catch (err) {
        console.error(err);
      }
    }
  }, [vimeoPlayer, vimeoPlayerObserver]);

  const handlePipOverlayClick = () => vimeoPlayer.exitPictureInPicture();

  return (
    <Container ref={livestreamRef}>
      <PIPContainer ref={pipRef}>
        <iframe
          title={eventName}
          id="livestream-iframe"
          src={`${streamUrl}?pip=1&autoplay=${user.isAdmin ? '0' : '1'}&fullscreen=1&controls=1`}
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
        />
        <LivestreamOverlays
          colors={colors}
          eid={eid}
          handlePipOverlayClick={handlePipOverlayClick}
          isPipModeEnabled={isPipModeEnabled}
          livestreamRef={livestreamRef}
          questionCurrentlyBeingAnsweredLive={questionCurrentlyBeingAnsweredLive}
          currentlyOpenWordCloudPollId={currentlyOpenWordCloudPollId}
        />
      </PIPContainer>
    </Container>
  );
}

const Container = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
})`
  grid-row: 2/3;
  grid-column: 1/13;

  @media screen and (min-width: 768px) {
    grid-column: 2/12;
  }

  @media screen and (min-width: 1150px) {
    grid-column: 1/9;
  }

  iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;

const PIPContainer = styled.div`
  height: 100%;
  overflow: hidden;
  padding: 56.25% 0 0 0;
  position: relative;
`;

export default Livestream;
