import React, { useState, useContext, useEffect } from 'react';
import { FirebaseContext } from 'context';
import styled from 'styled-components';
import { EmojiClap, EmojiLike, EmojiLove, EmojiSmile } from 'assets/svgs';
import AnalyticsBar from './AnalyticsBar';

function AnalyticsEmojiBar({ eid }) {
  const { firebase } = useContext(FirebaseContext);
  const [emojis, setEmojis] = useState([]);
  const [emojiValues, setEmojiValues] = useState({
    smile: 0,
    thumbsUp: 0,
    heart: 0,
    clap: 0
  });
  useEffect(() => {
    let unsubscribeFromEventEmojis;
    if (firebase) {
      unsubscribeFromEventEmojis = firebase.subscribeToEventEmojis({
        eid,
        snapshot: (snapshot) => {
          if (snapshot.size > 0) {
            const emids = Object.keys(snapshot.val());
            setEmojis(emids.map((emid) => snapshot.val()[emid].emojiType));
          }
        }
      });
    }
    return () => {
      if (unsubscribeFromEventEmojis) {
        unsubscribeFromEventEmojis();
      }
    };
  }, [firebase]);

  useEffect(() => {
    setEmojiValues({
      smile: emojis.filter((emoji) => emoji === 'smile').length,
      thumbsUp: emojis.filter((emoji) => emoji === 'like').length,
      heart: emojis.filter((emoji) => emoji === 'love').length,
      clap: emojis.filter((emoji) => emoji === 'clap').length
    });
  }, [emojis]);

  return (
    <AnalyticsBar title="Emojis" padding="1.25rem" height="225px">
      <ElementWrapper>
        <h4>{emojis.length}</h4>
        <p>Total number of emoji reactions</p>
      </ElementWrapper>
      <ElementWrapper>
        <EmojiSmile />
        <h4>{emojiValues.smile}</h4>
        <p>Total number of smiles</p>
      </ElementWrapper>
      <ElementWrapper>
        <EmojiLike />
        <h4>{emojiValues.thumbsUp}</h4>
        <p>Total number of thumbs-up</p>
      </ElementWrapper>
      <ElementWrapper>
        <EmojiLove />
        <h4>{emojiValues.heart}</h4>
        <p>Total number of hearts</p>
      </ElementWrapper>
      <ElementWrapper>
        <EmojiClap />
        <h4>{emojiValues.clap}</h4>
        <p>Total number of claps</p>
      </ElementWrapper>
    </AnalyticsBar>
  );
}

const ElementWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media only screen and (max-width: 799px) {
    padding: 1rem auto;
  }
`;

export default AnalyticsEmojiBar;
