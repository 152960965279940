import React, { createContext, useState, useEffect, useRef } from 'react';
import { useWindowSize, useLocalStorage } from 'hooks';
import { defaultColors } from 'styles';

const LocalContext = createContext();

const themes = {
  default: {
    className: 'default',
    fg: defaultColors.tertiary,
    bg: defaultColors.primary,
    muted: defaultColors.primary,
    bgLight: '#EAF5F9',
    textColor: '#fff',
    textMuted: defaultColors.secondary,
    contrastTextColor: 'white'
  },
  contrast: {
    className: 'contrast',
    fg: 'yellow',
    bg: 'black',
    muted: 'rgb(50,50,50)',
    bgLight: 'black',
    textColor: 'yellow',
    textMuted: 'yellow',
    contrastTextColor: 'yellow'
  },
  grayscale: {
    className: 'grayscale',
    fg: '#808080',
    bg: '#282828',
    muted: '#787878',
    bgLight: '#F2F2F2',
    textColor: '#282828',
    contrastTextColor: 'white'
  }
};

const fontSizes = {
  default: '16px',
  medium: '18px',
  large: '20px'
};

function LocalContextProvider(props) {
  const siteName = 'Virtuosity';
  const [theme, setTheme] = useLocalStorage(`${siteName.toLowerCase()}-theme`, themes.default);
  const [fontSizing, setFontSizing] = useLocalStorage(
    `${siteName.toLowerCase()}-fontSize`,
    fontSizes.default
  );
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedPollDocumentation, setSelectedPollDocumentation] = useState('general');
  const [aNewUserHasRegisteredForAnEvent, setANewUserHasRegisteredForAnEvent] = useState(false);
  const [showUserAccountDeletedModal, setShowUserAccountDeletedModal] = useState(false);
  const [browserTabIsActive, setBrowserTabIsActive] = useState(true);
  const { windowWidth } = useWindowSize();
  const [isMobile, setIsMobile] = useState(true);
  const contactRef = useRef(null);
  const faqRef = useRef(null);
  const galleryRef = useRef(null);
  const homeRef = useRef(null);
  const livestreamRef = useRef(null);
  const registerRef = useRef(null);
  const streamRef = useRef(null);
  const videosRef = useRef(null);

  useEffect(() => {
    if (windowWidth >= 1150) {
      setIsMobile(false);
    } else setIsMobile(true);
  }, [isMobile, windowWidth]);

  const handleBrowserTabActivity = (forcedFlag) => {
    if (typeof forcedFlag === 'boolean') {
      return forcedFlag ? setBrowserTabIsActive(true) : setBrowserTabIsActive(false);
    }
    return document.hidden ? setBrowserTabIsActive(false) : setBrowserTabIsActive(true);
  };

  useEffect(() => {
    const handleBrowserTabActivityFalse = () => handleBrowserTabActivity(false);
    const handleBrowserTabActivityTrue = () => handleBrowserTabActivity(true);

    document.addEventListener('visibilitychange', handleBrowserTabActivity);
    document.addEventListener('blur', handleBrowserTabActivityFalse);
    window.addEventListener('blur', handleBrowserTabActivityFalse);
    window.addEventListener('focus', handleBrowserTabActivityTrue);
    document.addEventListener('focus', handleBrowserTabActivityTrue);

    return () => {
      window.removeEventListener('blur', handleBrowserTabActivity);
      document.removeEventListener('blur', handleBrowserTabActivityFalse);
      window.removeEventListener('focus', handleBrowserTabActivityFalse);
      document.removeEventListener('focus', handleBrowserTabActivityTrue);
      document.removeEventListener('visibilitychange', handleBrowserTabActivityTrue);
    };
  }, []);

  const toggleTheme = (themeName) => {
    if (themeName === 'contrast') setTheme(themes.contrast);
    else if (themeName === 'grayscale') setTheme(themes.grayscale);
    else setTheme(themes.default);
  };

  const toggleFontSizing = (fontSize) => {
    if (fontSize === fontSizes.large) setFontSizing(fontSizes.large);
    else if (fontSize === fontSizes.medium) setFontSizing(fontSizes.medium);
    else setFontSizing(fontSizes.default);
  };

  return (
    <LocalContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        theme,
        toggle: toggleTheme,
        isMobile,
        fontSizing,
        toggleFont: toggleFontSizing,
        galleryRef,
        registerRef,
        contactRef,
        videosRef,
        homeRef,
        livestreamRef,
        faqRef,
        streamRef,
        selectedEvent,
        setSelectedEvent,
        aNewUserHasRegisteredForAnEvent,
        setANewUserHasRegisteredForAnEvent,
        showUserAccountDeletedModal,
        setShowUserAccountDeletedModal,
        browserTabIsActive,
        selectedPollDocumentation,
        setSelectedPollDocumentation,
        siteName
      }}>
      {props.children}
    </LocalContext.Provider>
  );
}

export { LocalContextProvider };
export default LocalContext;
