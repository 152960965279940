import { Matcher } from 'interweave';
import React, { useContext, useEffect, useState } from 'react';
import { FirebaseContext } from 'context';

export const mentionsPattern = /\B@(\w+)/;

export function Mention({ colors, taggedUsers, handleParticipantAvatarOrNameClick, ...props }) {
  const { firebase } = useContext(FirebaseContext);
  const [user, setUser] = useState(null);
  useEffect(() => {
    if (taggedUsers && taggedUsers.length > 0) {
      firebase
        .getUserFromDatabaseWithUID({ uid: taggedUsers[props.index] })
        .then((res) => {
          if (!res.empty) {
            setUser(res.docs[0].data());
          } else {
            setUser(null);
          }
        })
        .catch((err) => {
          console.log(err.message);
          setUser(null);
        });
    }
  }, [taggedUsers]);
  return (
    <q
      style={{ color: colors.secondary, cursor: user ? 'pointer' : 'default' }}
      role="button"
      tabIndex="0"
      onClick={(e) => (user ? handleParticipantAvatarOrNameClick(user) : e.preventDefault())}>
      {props.children}
    </q>
  );
}

export class MentionsMatcher extends Matcher {
  match(string) {
    return this.doMatch(string, mentionsPattern, (matches) => ({
      string: matches[0]
    }));
  }

  // eslint-disable-next-line class-methods-use-this
  replaceWith(match, props) {
    return React.createElement(Mention, { index: props.key, ...props }, match);
  }

  // eslint-disable-next-line class-methods-use-this
  asTag() {
    return 'q';
  }
}
