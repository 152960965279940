import * as React from 'react';

function SvgIgFooterIcon(props) {
  return (<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12.013 4.887a.889.889 0 1 0-.002-1.778.889.889 0 0 0 .002 1.778ZM8.06 4.255C6 4.255 4.31 5.948 4.31 8.015c0 2.065 1.69 3.758 3.752 3.758s3.752-1.693 3.752-3.759-1.69-3.76-3.752-3.76Zm0 6.17a2.423 2.423 0 0 1-2.405-2.41c0-1.32 1.088-2.411 2.406-2.411a2.423 2.423 0 0 1 2.406 2.41c0 1.32-1.089 2.41-2.406 2.41Z"
      fill="#fff"
    />
    <path
      d="M11.038 15.619H4.966c-2.52 0-4.583-2.066-4.583-4.592V4.972C.383 2.447 2.445.381 4.966.381h6.072c2.52 0 4.583 2.066 4.583 4.591v6.084c-.029 2.526-2.062 4.563-4.583 4.563ZM4.966 1.816a3.145 3.145 0 0 0-3.151 3.156v6.084c0 1.722 1.403 3.157 3.15 3.157h6.073a3.163 3.163 0 0 0 3.15-3.157V4.972c0-1.721-1.403-3.156-3.15-3.156H4.966Z"
      fill="#fff"
    />
  </svg>)
}

export default SvgIgFooterIcon;
