import React, { useState, useContext, useRef } from 'react';
import styled, { css } from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { Button, SubmittedQuestion } from 'components';
import { SelectQuestionCheckbox } from 'components/Events/Interaction/QAndA/SubmittedQuestion';
import { LocalContext, FirebaseContext } from 'context';

function QAndA({
  eid,
  questionCurrentlyBeingAnsweredLive,
  submittedQuestions,
  textArea,
  setTextArea,
  submitAnonymousQuestion,
  setSubmitAnonymousQuestion,
  selectedQuestion,
  setSelectedQuestion
}) {
  const { selectedEvent, theme } = useContext(LocalContext);
  const { colors } = selectedEvent;
  const { firebase, user } = useContext(FirebaseContext);

  const submittedQuestionsRef = useRef();

  const handleTextAreaChange = (e) => {
    e.persist();
    if (e.target.value !== ' ') {
      setTextArea(e.target.value);
    }
  };

  const handleSubmitNewQuestion = (e) => {
    e.preventDefault();
    if (textArea !== '' || textArea !== ' ') {
      firebase
        .submitNewQuestion({
          uid: user.uid,
          eid,
          text: textArea,
          name: submitAnonymousQuestion ? '' : user.name
        })
        .then(() => {
          setTextArea('');
          setSubmitAnonymousQuestion(false);
        });
    }
  };

  const handleAnswerQuestionLive = () => {
    if (questionCurrentlyBeingAnsweredLive === selectedQuestion?.text) {
      firebase.stopShowingAnswerLiveOverlay({ eid });
      setSelectedQuestion(null);
    } else {
      firebase.answerThisQuestionLive({
        eid,
        text: selectedQuestion.text
      });
    }
  };

  const handleSendAnswer = () => {
    if (textArea !== '' || textArea !== ' ') {
      firebase
        .submitAnswer({
          text: textArea,
          eid,
          qid: selectedQuestion.qid,
          uid: selectedQuestion.uid
        })
        .then(() => {
          setTextArea('');
          setSelectedQuestion(null);
        });
    }
  };

  return (
    <Wrapper colors={colors} isModerator={user?.isModerator?.includes(eid)}>
      {user?.isModerator?.includes(eid) ? (
        <p>Moderator&apos;s Control Panel</p>
      ) : (
        <p>
          Here you can submit questions,
          <br />
          which will be discussed during the livestream.
        </p>
      )}
      <div>
        <SubmittedQuestions
          ref={submittedQuestionsRef}
          submittedQuestions={submittedQuestions}
          colors={colors}>
          <AnimatePresence initial={false}>
            {submittedQuestions?.map((submittedQuestion, index, arr) => (
              <SubmittedQuestion
                key={submittedQuestion.qid}
                eid={eid}
                submittedQuestion={submittedQuestion}
                selectedQuestion={selectedQuestion}
                setSelectedQuestion={setSelectedQuestion}
                submittedQuestionsRef={submittedQuestionsRef}
                index={index}
                arr={arr}
                colors={colors}
              />
            ))}
          </AnimatePresence>
        </SubmittedQuestions>
        {user?.isModerator?.includes(eid) ? (
          <AnswerQuestion colors={colors}>
            <TextArea
              $theme={theme}
              id="answer"
              name="answer"
              colors={colors}
              value={textArea}
              disabled={!selectedQuestion}
              onChange={handleTextAreaChange}
              placeholder="Select a question then type your answer here to send an answer privately to the user, or click 'Answer Live' to answer it publically during the livestream."
            />
            <div>
              <AnswerLiveButton
                onClick={handleAnswerQuestionLive}
                disabled={!selectedQuestion}
                colors={colors}
                thisQuestionIsCurrentlyBeingAnsweredLive={
                  questionCurrentlyBeingAnsweredLive === selectedQuestion?.text
                }
              />
              <SendAnswerButton
                onClick={handleSendAnswer}
                disabled={!textArea || !selectedQuestion}
                colors={colors}
              />
            </div>
          </AnswerQuestion>
        ) : (
          <SubmitNewQuestion onSubmit={handleSubmitNewQuestion} colors={colors}>
            <TextArea
              $theme={theme}
              id="question"
              name="question"
              colors={colors}
              value={textArea}
              onChange={handleTextAreaChange}
              placeholder="Type your question..."
            />
            <div>
              <label htmlFor="anonymousCheckbox">
                <AnonymousCheckbox
                  checked={submitAnonymousQuestion}
                  id="anonymousCheckbox"
                  name="anonymousCheckbox"
                  onChange={(e) => setSubmitAnonymousQuestion(e.target.checked)}
                  colors={colors}
                  type="checkbox"
                />
                Submit Anonymously
              </label>
              <Button
                colors={colors}
                type="submit"
                disabled={!textArea}
                whileTap={{ scale: textArea ? 0.9 : 1 }}
                whileHover={{
                  backgroundColor: textArea ? colors.primary : colors.secondary
                }}>
                Submit Question
              </Button>
            </div>
          </SubmitNewQuestion>
        )}
      </div>
    </Wrapper>
  );
}

const AnswerLiveButton = styled(Button).attrs(
  ({ thisQuestionIsCurrentlyBeingAnsweredLive, disabled }) => ({
    children: thisQuestionIsCurrentlyBeingAnsweredLive ? 'Stop Showing' : 'Answer Live',
    whileTap: {
      scale: !disabled && 0.95
    }
  })
)`
  border-style: solid;
  border-width: 0.063rem;
  margin-right: 0.625rem;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  transition-property: background-color, border, color;

  ${({ thisQuestionIsCurrentlyBeingAnsweredLive, colors }) =>
    thisQuestionIsCurrentlyBeingAnsweredLive
      ? css`
          background-color: #ff7aa1;
          border-color: #ff7aa1;
          color: #fff;
        `
      : css`
          background-color: #fff;
          border-color: ${colors.secondary};
          color: ${colors.secondary};

          &:not([disabled]):hover {
            background-color: ${colors.tertiary};
            border-color: ${colors.tertiary};
            color: #fff;
          }
        `}
`;

const SendAnswerButton = styled(Button).attrs(({ disabled }) => ({
  children: 'Send Answer',
  whileTap: {
    scale: !disabled && 0.95
  }
}))`
  background-color: ${({ colors }) => colors.secondary};
  margin-left: 0.625rem;
  transition: background-color 300ms ease-in-out;

  &:not([disabled]):hover {
    background-color: ${({ colors }) => colors.tertiary};
  }
`;

const Wrapper = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
})`
  background-color: #fff;
  padding: 0.75rem 0.75rem 0.5rem;

  @media screen and (min-width: 410px) {
    padding: 1.275rem 1.0125rem 0.95rem;
  }

  @media screen and (min-width: 1150px) and (max-width: 1365px) {
    padding: 0.75rem 0.75rem 0.5rem;
  }

  @media screen and (min-width: 1366px) {
    padding: 1.275rem 1.0125rem 1.15rem;
  }

  > p {
    color: ${({ colors }) => colors.secondary};
    font-size: 0.875rem;
    font-style: ${({ isModerator }) => (isModerator ? 'normal' : 'italic')};
    font-weight: ${({ isModerator }) => (isModerator ? 600 : 300)};
    line-height: 1.25em;
    margin: 0.5em 0 1em;
    text-align: center;
    ${({ isModerator }) => isModerator && `transform: scale3d(1.125, 1.125, 1.125)`}

    @media screen and (min-width: 410px) {
      font-size: 1rem;
      margin: 0.063em 0 1.25em;
    }

    @media screen and (min-width: 1150px) and (max-width: 1365px) {
      font-size: 0.875rem;
      margin: 0.5em 0 1em;
    }

    @media screen and (min-width: 1366px) {
      font-size: 1rem;
      margin: 0.063em 0 1.25em;
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    height: calc(100% - 2.6rem);
    justify-content: space-between;

    @media screen and (min-width: 410px) {
      height: calc(100% - 2.15rem);
    }

    @media screen and (min-width: 1150px) and (max-width: 1365px) {
      height: calc(100% - 2.65rem);
    }

    @media screen and (min-width: 1366px) {
      height: calc(100% - 2.2rem);
    }
  }
`;

const SubmittedQuestions = styled.div`
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  ${({ submittedQuestions }) =>
    submittedQuestions &&
    submittedQuestions.length > 0 &&
    css`
      ::-webkit-scrollbar {
        width: 0.5rem;
      }

      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 0.31rem grey;
        border-radius: 0.625rem;
      }

      ::-webkit-scrollbar-thumb {
        background-color: ${({ colors }) => colors.secondary};
        border-radius: 0.625rem;
      }
    `}
`;

const TextArea = styled.textarea`
  border: 0.063em solid ${({ colors }) => colors.secondary};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'text')};
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 1rem;
  height: 6.25em;
  margin-top: 0.5em;
  outline-color: ${({ colors }) => colors.secondary};
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0.625em;
  resize: none;
  width: 100%;

  &::placeholder {
    color: #c4c4c4;
    font-size: 1rem;
  }

  ::-webkit-scrollbar {
    width: 0.5rem;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0.31rem grey;
    border-radius: 0.625rem;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ colors }) => colors.secondary};
    border-radius: 0.625rem;
  }
`;

const AnswerQuestion = styled.div`
  border-top: 0.063rem solid #bdbdbd;

  > div {
    display: flex;
    justify-content: flex-end;
    margin-top: 9px;

    @media screen and (min-width: 1150px) {
      justify-content: space-between;
    }

    button {
      border-radius: 0.125rem;
      max-width: 11.875rem;
    }

    button:first-of-type {
      /* background-color: #fff;
      border: 1px solid ${({ colors }) => colors.secondary};
      color: ${({ colors }) => colors.secondary}; */

      @media screen and (max-width: 1149px) {
        margin-right: 30px;
      }
    }

    button:last-of-type {
      /* background-color: ${({ colors }) => colors.secondary}; */
    }
  }
`;

const AnonymousCheckbox = styled(SelectQuestionCheckbox)`
  border: 1px solid #bdbdbd;
  height: 1rem;
  margin-right: 0.375rem;
  margin-top: unset;
  position: relative;
  width: 1rem;

  &:focus {
    border: 2px solid ${({ colors }) => colors.secondary};
    margin-right: 0.375rem;
  }

  &:checked {
    background-color: ${({ colors }) => colors.secondary};
    border: 2px solid ${({ colors }) => colors.secondary};
    margin-right: 0.375rem;
  }

  &:checked:before {
    color: ${({ contrast }) => (contrast ? `#000` : `#fff`)};
    font-size: 0.8rem;
    height: 0.8rem;
    width: 0.8rem;
  }
`;

const SubmitNewQuestion = styled.form`
  border-top: 0.063rem solid #bdbdbd;

  > div {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0.438rem 0 1rem;

    button {
      border-radius: 0.125rem;
      max-width: 11.875rem;
    }

    label {
      align-items: center;
      color: #c4c4c4;
      display: flex;
      font-size: 0.875rem;
    }

    @media screen and (min-width: 500px) and (max-width: 1149px) {
      justify-content: flex-end;

      label {
        white-space: nowrap;
      }
    }

    @media screen and (min-width: 500px) and (max-width: 1300px) {
      label {
        margin-right: 1em;
      }
    }
  }
`;

export default QAndA;
