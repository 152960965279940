import { useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import { onAuthStateChanged } from 'firebase/auth';
import firebaseConfig from './config';
import getFirebaseInstance from './firebase';

function useAuth() {
  const [user, setUser] = useState(null);
  const [firebase, setFirebase] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let userUnsubscribe;
    const app = initializeApp(firebaseConfig);
    const firebaseInstance = getFirebaseInstance(app);
    setFirebase(firebaseInstance);
    const unsubscribe = onAuthStateChanged(firebaseInstance.auth, (userAuthInfo) => {
      if (userAuthInfo) {
        userUnsubscribe = firebaseInstance.subscribeToUserUpdates({
          uid: userAuthInfo.uid,
          snapshot: (_user) => {
            firebaseInstance.auth.currentUser
              .getIdTokenResult(true)
              .then(async (token) => {
                if (_user.exists) {
                  const userDatabaseInfo = _user.data();
                  setUser({
                    ...userAuthInfo,
                    ...userDatabaseInfo,
                    isAdmin: token.claims.isAdmin,
                    isModerator: token.claims.isModerator
                  });
                }
              })
              .catch((error) => {
                console.error(error.message);
              });
          }
        });
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }

      if (userUnsubscribe) {
        userUnsubscribe();
      }
    };
  }, []);

  return { user, setUser, firebase, loading };
}

export default useAuth;
