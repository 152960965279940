import React from 'react';
import { motion } from 'framer-motion';

function SvgUpvote(props) {
  return (
    <motion.svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M2.1958 12.813L1.11182 11.729C0.652832 11.27 0.652832 10.5278 1.11182 10.0737L10.5991 0.581543C11.0581 0.122559 11.8003 0.122559 12.2544 0.581543L21.7417 10.0688C22.2007 10.5278 22.2007 11.27 21.7417 11.7241L20.6577 12.8081C20.1938 13.272 19.437 13.2622 18.9829 12.7886L13.3823 6.90967V20.9429C13.3823 21.5923 12.8599 22.1147 12.2104 22.1147H10.6479C9.99854 22.1147 9.47607 21.5923 9.47607 20.9429V6.90967L3.87061 12.7935C3.4165 13.272 2.65967 13.2817 2.1958 12.813V12.813Z"
        fill={props.fill}
      />
    </motion.svg>
  );
}

export default SvgUpvote;
