import React, { useContext } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { LocalContext } from 'context';
import { hexToRGB } from 'utils';

function Button({
  props,
  className,
  children,
  colors,
  disabled,
  height = '3rem',
  glow,
  onClick,
  style,
  type = 'button',
  whileFocus,
  whileHover,
  whileTap,
  width,
  variants,
  onMouseEnter,
  onMouseLeave
}) {
  const { theme } = useContext(LocalContext);
  return (
    <Wrapper
      {...props}
      glow={glow}
      className={className}
      $colors={colors}
      $theme={theme}
      animate="animate"
      disabled={disabled}
      exit="exit"
      height={height}
      initial="initial"
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={style}
      type={type}
      variants={variants}
      whileFocus={whileFocus}
      whileTap={whileTap}
      whileHover={whileHover}
      width={width}>
      {children}
    </Wrapper>
  );
}

const Wrapper = styled(motion.button)`
  align-items: center;
  animation: ${({ glow }) => glow && `glowButton 2.25s ease-in-out infinite alternate`};
  background-color: ${({ $theme, $colors }) =>
    $theme.className === 'contrast' ? 'yellow' : $colors ? $colors.secondary : $theme.fg};
  border: 2px solid
    ${({ $theme, $colors }) =>
      $theme.className === 'contrast' ? 'yellow' : $colors ? $colors.secondary : $theme.fg};
  border-radius: 6px;
  box-shadow: 0px 1px 7px ${({ $colors }) => hexToRGB({ color: $colors.secondary, alpha: 0 })},
    0px 0px 4px ${({ $colors }) => hexToRGB({ color: $colors.secondary, alpha: 0 })};
  color: ${({ $theme }) => ($theme.className === 'contrast' ? '#000' : 'white')};
  cursor: pointer;
  display: flex;
  filter: drop-shadow(
    0px 0px 14px ${({ $colors }) => hexToRGB({ color: $colors.secondary, alpha: 0 })}
  );
  font-family: Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  height: ${({ height }) => height};
  justify-content: center;
  padding: 1rem;
  text-transform: uppercase;
  width: ${(props) => (props.width ? props.width : '100%')};
  ${({ style }) => style}

  @keyframes glowButton {
    from {
      box-shadow: 0px 1px 7px ${({ $colors }) => hexToRGB({ color: $colors.secondary, alpha: 0 })},
        0px 0px 4px ${({ $colors }) => hexToRGB({ color: $colors.secondary, alpha: 0 })};
      filter: drop-shadow(
        0px 0px 14px ${({ $colors }) => hexToRGB({ color: $colors.secondary, alpha: 0 })}
      );
    }

    to {
      box-shadow: 0px 1px 7px ${({ $colors }) => hexToRGB({ color: $colors.secondary, alpha: 1 })},
        0px 0px 4px ${({ $colors }) => hexToRGB({ color: $colors.secondary, alpha: 1 })};
      filter: drop-shadow(
        0px 0px 14px ${({ $colors }) => hexToRGB({ color: $colors.secondary, alpha: 1 })}
      );
    }
  }

  :disabled {
    cursor: default;
  }
`;

export default Button;
