import * as React from 'react';

function SvgDefaultParticipantAvatar(props) {
  return (<svg viewBox="0 0 163 163" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={81.5} cy={81.5} r={79.906} stroke="#C4C4C4" fill="#fff" strokeWidth={3.188} />
    <path
      d="M97.033 74.185c5.877-4.44 9.656-11.3 9.656-18.97C106.689 41.898 95.354 31 81.5 31S56.311 41.897 56.311 55.216c0 7.668 3.779 14.53 9.656 18.969C49.175 80.239 37 95.979 37 114.14c0 23.812 89 23.812 89 0 0-18.162-11.755-33.902-28.967-39.956Z"
      fill="#C4C4C4"
    />
  </svg>)
}

export default SvgDefaultParticipantAvatar;
