import React, { useContext, useEffect } from 'react';
import { LocalContext } from 'context';
import styled from 'styled-components';
import { clearQueryParams } from 'utils';
import { defaultColors } from 'styles';
import { FormContainer } from '../FormComponents';

function Unsubscribe() {
  const { theme, selectedEvent } = useContext(LocalContext);
  const colors = selectedEvent?.colors || defaultColors;

  useEffect(() => {
    setTimeout(() => clearQueryParams(), 1000);
  }, []);

  return (
    <FormContainer $theme={theme} colors={colors}>
      <H3>You have been unsubscribed</H3>
    </FormContainer>
  );
}

const H3 = styled.h3`
  align-items: center;
  display: flex;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: bold;
  justify-content: center;
  line-height: 1.75rem;
  text-align: center;
`;

export default Unsubscribe;
