import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { gridContainer } from 'styles';
import { LocalContext } from 'context';
import { HelloImg } from 'components';
import { motion, AnimatePresence } from 'framer-motion';
import { wrap } from '@popmotion/popcorn';
import {
  Arrow,
  FbIcon,
  EmailIcon,
  TwitterIcon,
  LinkedinIcon,
  BgMoon1,
  BgMoon2,
  BrokenCircle
} from 'assets/svgs';
import { BlueBall, YellowBall, StyledGBBall } from '../Speaker';

const variants = {
  enter: (direction) => ({
    opacity: 0,
    x: direction > 0 ? 100 : -100
  }),
  center: {
    opacity: 1,
    x: 0,
    zIndex: 1
  },
  exit: (direction) => ({
    opacity: 0,
    x: direction < 0 ? 100 : -100,
    zIndex: 0
  })
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => Math.abs(offset) * velocity;

function SpeakerPopup({ startPoint, speakers, colors }) {
  const { isMobile, theme } = useContext(LocalContext);
  const [[page, direction], setPage] = useState([startPoint, 0]);

  const paginate = (newDirection) => {
    setPage([page + newDirection, newDirection]);
  };

  const speakerIndex = wrap(0, speakers.length, page);

  return (
    <Backdrop>
      <AnimatePresence exitBeforeEnter custom={direction}>
        {!isMobile && (
          <>
            <Prev
              $theme={theme}
              onClick={() => paginate(-1)}
              whileTap={{ scale: 0.9 }}
              whileHover={{ opacity: 1 }}
              transition={{ duration: 0.25 }}
              $colors={colors}>
              <Arrow />
            </Prev>
            <Container
              key={page}
              custom={direction}
              variants={variants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{ duration: 0.5 }}
              drag="x"
              dragConstraints={{ left: 0, right: 0 }}
              dragElastic={1}
              onDragEnd={(e, { offset, velocity }) => {
                const swipe = swipePower(offset.x, velocity.x);

                if (swipe < -swipeConfidenceThreshold) {
                  paginate(1);
                } else if (swipe > swipeConfidenceThreshold) {
                  paginate(-1);
                }
              }}>
              <Slide>
                <SpeakerImgContainer>
                  <SpeakerImg image={getImage(speakers[speakerIndex].image)} />
                  <YellowBall color={colors.tertiary} />
                  <BlueBall color={colors.secondary} />
                  <StyledGBBall />
                  <HelloImg showHelloImg delay={0.3} stiffness={200} />
                </SpeakerImgContainer>
                <SpeakerDetails>
                  <SpeakerName>{speakers[speakerIndex].name}</SpeakerName>
                  <SpeakerPosition>{speakers[speakerIndex].position}</SpeakerPosition>
                  <SpeakerSocials $colors={colors}>
                    <SpeakerSocial href={speakers[speakerIndex].fbLink}>
                      <FbIcon />
                    </SpeakerSocial>
                    <SpeakerSocial href={speakers[speakerIndex].linkedinLink}>
                      <LinkedinIcon />
                    </SpeakerSocial>
                    <SpeakerSocial href={speakers[speakerIndex].twLink}>
                      <TwitterIcon />
                    </SpeakerSocial>
                    <SpeakerSocial href={speakers[speakerIndex].emailLink}>
                      <EmailIcon />
                    </SpeakerSocial>
                  </SpeakerSocials>
                  <SpeakerTitle>{speakers[speakerIndex].title}</SpeakerTitle>
                  <SpeakerSummary>{speakers[speakerIndex].summary}</SpeakerSummary>
                </SpeakerDetails>
              </Slide>
            </Container>
            <Next
              $theme={theme}
              onClick={() => paginate(1)}
              whileTap={{ scale: 0.9 }}
              whileHover={{ opacity: 1 }}
              transition={{ duration: 0.25 }}
              $colors={colors}>
              <Arrow />
            </Next>
          </>
        )}

        {isMobile && (
          <Container>
            <Slide>
              <SpeakerImgContainer>
                <SpeakerImg image={getImage(speakers[speakerIndex].image)} />
                <YellowBall color={colors.tertiary} />
                <BlueBall color={colors.secondary} />
                <StyledGBBall />
                <HelloImg showHelloImg delay={0.3} stiffness={200} />
              </SpeakerImgContainer>
              <SpeakerDetails>
                <SpeakerName>{speakers[speakerIndex].name}</SpeakerName>
                <SpeakerPosition>{speakers[speakerIndex].position}</SpeakerPosition>
                <SpeakerSocials $colors={colors}>
                  <SpeakerSocial href={speakers[speakerIndex].fbLink}>
                    <FbIcon />
                  </SpeakerSocial>
                  <SpeakerSocial href={speakers[speakerIndex].linkedinLink}>
                    <LinkedinIcon />
                  </SpeakerSocial>
                  <SpeakerSocial href={speakers[speakerIndex].twLink}>
                    <TwitterIcon />
                  </SpeakerSocial>
                  <SpeakerSocial href={speakers[speakerIndex].emailLink}>
                    <EmailIcon />
                  </SpeakerSocial>
                </SpeakerSocials>
                <SpeakerTitle>{speakers[speakerIndex].title}</SpeakerTitle>
                <SpeakerSummary>{speakers[speakerIndex].summary}</SpeakerSummary>
              </SpeakerDetails>
            </Slide>
          </Container>
        )}
      </AnimatePresence>
    </Backdrop>
  );
}

const Paginate = styled(motion.button)`
  align-items: center;
  background-color: ${({ $colors }) => $colors.primary};
  border: none;
  cursor: pointer;
  display: flex;
  height: 3rem;
  justify-content: center;
  justify-self: end;
  left: initial;
  opacity: 0.8;
  outline: none;
  position: absolute;
  right: initial;
  top: calc(50% - 1.5rem);
  width: 3rem;
  z-index: 3;

  svg {
    transform: rotate(90deg);
    transform-origin: center;
    width: 1.5rem;

    path {
      stroke: #fff;
    }
  }
`;

const Prev = styled(Paginate)`
  border-bottom-left-radius: 50%;
  border-top-left-radius: 50%;
  left: 0;
  opacity: 0.8;
  padding-left: 0.625rem;
  svg {
    margin-right: 0.5rem;
    transform: rotate(90deg);
  }
`;

const Next = styled(Paginate)`
  border-bottom-right-radius: 50%;
  border-top-right-radius: 50%;
  padding-right: 0.625rem;
  right: 0;
  svg {
    margin-left: 0.5rem;
    transform: rotate(-90deg);
  }
`;

const SpeakerImgContainer = styled.div`
  grid-column: 1/7;
  justify-self: center;
  margin-bottom: 1.25rem;
  margin-left: -1.25rem;
  margin-right: -1.25rem;
  margin-top: -50%;
  max-width: 100%;
  position: relative;
  width: 300px;

  @media only screen and (min-width: 1150px) {
    align-self: center;
    grid-column: 1/5;
    height: auto;
    margin-left: -50%;
    margin-right: 50%;
    margin-top: 0px;
    max-width: 100%;
    width: 300px;

    ${StyledGBBall} {
      opacity: 1;
      z-index: 3;
    }

    ${YellowBall} {
      opacity: 1;
      top: 1rem;
    }

    ${BlueBall} {
      opacity: 1;
      bottom: 1.25rem;
    }
  }
`;

const SpeakerImg = styled(GatsbyImage)`
  animation: fadeIn 0.625s ease-in-out;
  border-radius: 50%;
  box-shadow: 0px 0.375rem 0.75rem rgba(0, 0, 0, 0.25);
  cursor: pointer;
  height: auto;
  min-height: 275px;
  object-fit: cover !important;
  object-position: center !important;
  position: relative;
  width: 100%;
  z-index: 2;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @media only screen and (min-width: 1150px) {
    min-height: 300px;
  }
`;

const SpeakerName = styled.h3`
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 0px;
  margin-top: 0px;
  text-align: left;
`;

const SpeakerPosition = styled.p`
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2rem;
  margin-bottom: 0.375rem;
  margin-top: 0px;
  text-align: left;
`;

const SpeakerSocial = styled.a`
  margin-right: 0.625rem;
`;

const SpeakerSocials = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1rem;

  ${SpeakerSocial} {
    svg {
      width: 1.5rem;
      circle {
        fill: ${({ $colors }) => $colors.secondary};
        transition: 0.5s;
      }
      path {
        fill: #fff;
        transition: 0.5s;
      }
    }
    :last-child {
      svg {
        path {
          fill: none;
          stroke: #fff;
          transition: 0.5s;
        }
      }
    }
    :hover {
      svg {
        circle {
          fill: ${({ $colors }) => $colors.primary};
        }
        path {
          fill: #fff;
        }
      }
      :last-child {
        svg {
          path {
            fill: none;
            stroke: #fff;
            transition: 0.5s;
          }
        }
      }
    }
  }
`;

const SpeakerTitle = styled.span`
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 1rem;
  margin-right: 0.375rem;
  margin-top: auto;
`;

const SpeakerSummary = styled.p`
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
`;

const SpeakerDetails = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1/7;
  margin-left: 0px;

  @media only screen and (min-width: 1150px) {
    grid-column: 5/11;
    margin-left: -150px;
    padding: 2.5rem;
  }
`;

const SVG = styled(motion.div)`
  bottom: 0;
  left: 0;
  opacity: 1;
  position: absolute;
  right: 0;
  top: 0;
  transition: 1s;
  width: auto;
  z-index: 0;
  :nth-child(5) {
    z-index: 5;
  }
  svg {
    path {
      transition: 0.5s;
    }
  }
`;

const Circle1 = styled(BgMoon1)`
  height: 100%;
  left: -15%;
  position: absolute;
  top: -15%;
  transform: rotate(60deg);
  width: 100%;
  path {
    fill: ${({ colors }) => colors.primary};
  }
`;
const Circle2 = styled(BgMoon2)`
  bottom: -15%;
  height: 100%;
  position: absolute;
  right: -15%;
  transform: rotate(60deg);
  width: 100%;

  path {
    fill: ${({ colors }) => colors.primary};
  }
`;
const Circle3 = styled(BrokenCircle)`
  bottom: -15%;
  height: 50%;
  left: -10%;
  position: absolute;
  width: 50%;

  path {
    fill: ${({ colors }) => colors.primary};
  }
`;

const Slide = styled.div`
  align-items: center;
  ${gridContainer}
  background: #161616;
  color: #fff;
  height: auto;
  margin-bottom: 3rem;
  margin-top: 10rem;
  max-width: 100%;
  padding: 1.25rem;
  position: relative;
  width: 1200px;

  @media only screen and (min-width: 1150px) {
    margin-left: 200px;
    margin-right: 5rem;
    margin-top: 0px;
  }
`;

const Container = styled(motion.div)`
  align-items: center;
  display: flex;
  justify-content: center;
  max-width: 100%;
  padding-top: 1rem;
  width: 1200px;
  @media only screen and (min-width: 768px) {
    max-width: 400px;
  }
  @media only screen and (min-width: 1150px) {
    max-width: 100%;
    padding-top: 0;
  }
`;

const Backdrop = styled.div`
  height: auto;
  width: auto;
  @media (max-width: 1150px) {
    /* overflow-x: hidden; */
  }
`;

export default SpeakerPopup;
