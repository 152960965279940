import React, { useContext } from 'react';
import { Button } from 'components';
import { navigate } from 'gatsby';
import { LocalContext } from 'context';
import styled, { css } from 'styled-components';
import { Markup } from 'interweave';
import { defaultColors } from 'styles';
import { FormContainer } from '../FormComponents';

function UserAccountVerification({
  setShowUserAccountVerificationModal,
  userAccountVerified,
  userName
}) {
  const { theme } = useContext(LocalContext);
  return (
    <FormContainer $theme={theme} colors={defaultColors}>
      <Status userAccountVerified={userAccountVerified}>
        <Markup
          content={
            !userAccountVerified
              ? 'Verifying Account'
              : `${
                  userName && `Welcome, ${userName.split(' ')[0]}!<br><br>`
                }Your account has been successfully verified. Come back on Mar 21st - 23rd to watch the livestream!<br><br>`
          }
        />
      </Status>
      {userAccountVerified && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            colors={defaultColors}
            onClick={() => {
              setShowUserAccountVerificationModal(false);
              navigate('/');
            }}
            whileTap={{ scale: 0.9 }}
            type="button"
            width="9.25rem"
            style={{ alignSelf: 'center', marginTop: '0.5rem' }}>
            Continue
          </Button>
        </div>
      )}
    </FormContainer>
  );
}

const Status = styled.h3`
  align-items: center;
  display: flex;

  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  justify-content: center;
  line-height: 1.75rem;
  text-align: center;

  ${({ userAccountVerified }) =>
    !userAccountVerified &&
    css`
      &:after {
        animation: dots 1s steps(5, end) infinite;
        content: ' .';
        margin-left: 0.25rem;
      }

      @keyframes dots {
        0%,
        20% {
          color: rgba(0, 0, 0, 0);
          text-shadow: 0.5rem 0 0 rgba(0, 0, 0, 0), 1rem 0 0 rgba(0, 0, 0, 0);
        }
        40% {
          color: #fff;
          text-shadow: 0.5rem 0 0 rgba(0, 0, 0, 0), 1rem 0 0 rgba(0, 0, 0, 0);
        }
        60% {
          text-shadow: 0.5rem 0 0 #fff, 1rem 0 0 rgba(0, 0, 0, 0);
        }
        80%,
        100% {
          text-shadow: 0.5rem 0 0 #fff, 1rem 0 0 #fff;
        }
      }
    `}
`;

export default UserAccountVerification;
