import styled from 'styled-components';
import { hexToRGB } from 'utils';

export const defaultColors = {
  primary: '#E4097E',
  secondary: '#189BD7',
  tertiary: '#FFEC00'
};

export const glowModal = (color) => `
  animation: glowForm 2.25s ease-in-out infinite alternate;
  background: rgba(000, 000, 000, 0.95);
  border: 0.125rem solid ${color};

  @keyframes glowForm {
    from {
      box-shadow: 0.3rem 0.3rem 1.25rem 0.188rem
        ${hexToRGB({ color, alpha: 0 })};
    }

    to {
      box-shadow: 0.3rem 0.3rem 1.25rem 0.188rem
        ${hexToRGB({ color, alpha: 1 })};
    }
  }
`;

export const Grid = styled.div`
  align-items: start;
  display: grid;
  grid-column: 1/7;
  grid-column-gap: 1rem;
  grid-row-gap: 2rem;
  grid-template-columns: repeat(6, 1fr);
  justify-content: center;
  @media only screen and (min-width: 1150px) {
    grid-column: 2/12;
    grid-template-columns: repeat(10, 1fr);
  }
`;

export const H1 = styled.h1`
  color: inherit;
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 1.4em;
  margin-bottom: 0.5em;
  margin-top: 0px;
  @media only screen and (min-width: 1150px) {
    font-size: 3rem;
    margin-bottom: 1em;
  }
`;

export const H2 = styled.h2`
  color: inherit;
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 1.4em;
  margin-bottom: 0.5em;
  margin-top: 0px;
  @media only screen and (min-width: 1150px) {
    font-size: 2.75rem;
    margin-bottom: 1em;
  }
`;

export const H3 = styled.h3`
  color: inherit;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.4em;
  margin-bottom: 0.5em;
  margin-top: 0px;
  @media only screen and (min-width: 1150px) {
    font-size: 2.25rem;
    margin-bottom: 1em;
  }
`;

export const H4 = styled.h4`
  color: inherit;
  font-size: 1.75rem;
  font-weight: 600;
  line-height: 1.4em;
  margin-bottom: 0.5em;
  margin-top: 0px;
  @media only screen and (min-width: 1150px) {
    font-size: 2rem;
    margin-bottom: 1em;
  }
`;

export const H5 = styled.h5`
  color: inherit;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.4em;
  margin-bottom: 0.5em;
  margin-top: 0px;
  @media only screen and (min-width: 1150px) {
    font-size: 1.5rem;
    margin-bottom: 1em;
  }
`;

export const H6 = styled.h6`
  color: inherit;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.4em;
  margin-bottom: 0.5em;
  margin-top: 0px;
  @media only screen and (min-width: 1150px) {
    font-size: 1.25rem;
    margin-bottom: 1em;
  }
`;

export const P = styled.p`
  color: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.3em;
  margin-bottom: 0.5em;
  margin-top: 0px;
  @media only screen and (min-width: 1150px) {
    margin-bottom: 1em;
  }
`;

export const CardDeck = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-row-gap: 2.5rem;
  grid-template-columns: 1fr;
  padding: 0 1.25rem;
  position: relative;
  width: 100%;
  @media only screen and (min-width: 768px) {
    grid-column-gap: ${({ large }) => (large ? '3rem' : '2rem')};
    grid-row-gap: 4rem;
    grid-template-columns: repeat(2, minmax(200px, 1fr));
    justify-content: space-between;
  }
  @media only screen and (min-width: 1150px) {
    grid-column-gap: ${({ large }) => (large ? '3rem' : '2rem')};
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    justify-content: space-between;
  }
`;

export const container = () =>
  ` padding: 0px 1.25rem;
    grid-column: 1/7;
   @media only screen and (min-width: 768px){
      grid-column: 2/6;
    }
    @media only screen and (min-width: 1150px){
        grid-column: 2/12;
    }
    @media only screen and (min-width: 1441px){
      grid-column: 3/11;
    }
    `;

export const gridContainer = () =>
  `
    padding: 0px 1.25rem;
    grid-column: 1/7;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap:.625rem;
   @media only screen and (min-width: 768px){
      grid-column: 2/6;
    }
    @media only screen and (min-width: 1150px){
        grid-column: 2/12;
        grid-template-columns: repeat(10, 1fr);
        grid-gap: 1.25rem;
    }
    @media only screen and (min-width: 1441px){
      grid-column: 3/11;
    }
    `;

export const fullGridContainer = () =>
  `
    padding:0px 1.25rem;
    grid-column: 1/7;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap:.625rem;
    @media only screen and (min-width: 1150px){
        grid-column: 1/13;
        grid-template-columns: repeat(12, 1fr);
        grid-gap: 1.25rem;
    }
    `;

export const flexContainer = () =>
  `
    grid-column: 1/7;
    display: flex;
   @media only screen and (min-width: 768px){
      grid-column: 2/6;
    }
    @media only screen and (min-width: 1150px){
        grid-column: 2/12;
    }
    @media only screen and (min-width: 1441px){
      grid-column: 3/11;
    }
    `;

export const noPadding = () => `
    padding:0px;`;

export const fullWidth = () => `
grid-column: 1/7;
@media only screen and (min-width: 768px){
  grid-column: 2/6;
}
@media only screen and (min-width: 1150px){
  grid-column: 2/12;
}
@media only screen and (min-width: 1441px){
  grid-column: 3/11;
}
`;

export const fadeInAndOutVariants = ({ animateDelay = 0, exitDelay = 0 } = {}) => ({
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: {
      delay: animateDelay
    }
  },
  exit: {
    opacity: 0,
    transition: {
      delay: exitDelay
    }
  }
});

export const fadeInAndOutAndAnimateHeightVariants = () => ({
  initial: {
    opacity: 0,
    height: 0
  },
  animate: {
    opacity: 1,
    height: 'auto',
    transition: {
      ease: 'easeInOut',
      duration: 0.2,
      opacity: {
        delay: 0.1
      }
    }
  },
  exit: {
    opacity: 0,
    height: 0,
    transition: {
      ease: 'easeInOut',
      duration: 0.2,
      height: {
        delay: 0.1
      }
    }
  }
});

export default {
  container,
  gridContainer,
  fullGridContainer,
  flexContainer,
  noPadding,
  fullWidth
};
