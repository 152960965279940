import * as React from 'react';
import { useState, useContext } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { LocalContext } from 'context';
import { DownArrow } from 'assets/svgs';
import { defaultColors } from 'styles';
import { hexToRGB } from 'utils';
import Answer from './Answer';

const faqs = [
  {
    question: 'How do I register?',
    answer:
      'Simply fill out the register form above and confirm your email. Sign in and you will be able to access our livestream on November 6th.'
  },
  {
    question: 'Is there a fee for registration?',
    answer: 'No this event is sponsored by Agency X as a service to entertainment and education!'
  },
  {
    question: 'How do I make a donation?',
    answer:
      'Please click on the Make A Donation button, choose a charity of your choice and make a donation to that charity as you wish.'
  },
  {
    question: 'Is there a networking opportunity at the event?',
    answer:
      'Yes, the event includes a live chat function for registered guests, the opportunity to ask questions of the speakers and also to link in with all other attendees.'
  },
  {
    question: 'Are CPD points available for attending the event?',
    answer:
      'Yes - participation at the event qualifies for 10 CPD points for this year. You will be required to complete a short form at the end of the event with your CPD request information.'
  },
  {
    question: 'Are the speakers materials available post event?',
    answer:
      'Yes - all slide decks and video as presented will be made available for all registered attendees post event. That will include edited video of each individual presentation itself.'
  },
  {
    question: 'Can I upload my work to the site?',
    answer:
      'Yes - for participating students there is a gallery section for you to upload your research posters for subsequent peer review.'
  },
  {
    question: `I can't make this time/date, but would like to see the show, what are my options?`,
    answer:
      'Provided you have registered, the entire show will be made available via video link. Please contact support@eventxlive.com '
  }
];

function Accordion({ i, expanded, setExpanded, question, answer }) {
  const isOpen = i === expanded;
  const { theme } = useContext(LocalContext);

  // By using `AnimatePresence` to mount and unmount the contents, we can animate
  // them in and out while also only rendering the contents of open accordions
  return (
    <>
      <Question $theme={theme} onClick={() => setExpanded(isOpen ? false : i)} isOpen={isOpen}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {question}
          <DownArrow />
        </div>
      </Question>
      <AnimatePresence exitBeforeEnter>
        {isOpen && (
          <AnswerText
            $theme={theme}
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            isOpen={isOpen}
            variants={{
              open: {
                opacity: 1,
                height: 'auto',
                marginBottom: '2rem',
                transition: {
                  when: 'beforeChildren',
                  staggerChildren: 0.3
                }
              },
              collapsed: {
                opacity: 0,
                height: 0,
                marginBottom: '0',
                transition: {
                  delay: 0.5,
                  when: 'afterChildren'
                }
              }
            }}
            transition={{ duration: 0.375, ease: [0.04, 0.62, 0.23, 0.98] }}>
            <Answer answer={answer} />
          </AnswerText>
        )}
      </AnimatePresence>
    </>
  );
}

const Faq = () => {
  // This approach is if you only want max one section open at a time. If you want multiple
  // sections to potentially be open simultaneously, they can all be given their own `useState`.
  const [expanded, setExpanded] = useState(false);

  return faqs.map((faq, i) => (
    <Accordion
      i={i}
      key={i}
      expanded={expanded}
      setExpanded={setExpanded}
      question={faq.question}
      answer={faq.answer}
    />
  ));
};

const Question = styled(motion.div)`
  background: ${({ $theme }) => ($theme.className === 'contrast' ? $theme.bgLight : '#f9f9f9')};
  color: ${({ $theme }) => $theme.textColor};
  cursor: pointer;
  font-size: 1.25rem;
  font-weight: 600;
  grid-column: 1/7;
  line-height: 1.75rem;
  margin-bottom: 2rem;
  padding: 1.5rem;
  div {
    svg {
      height: 24px;
      min-width: 24px;
      path {
        stroke: ${({ $theme }) => $theme.fg};
      }
    }
  }
  @media only screen and (min-width: 1150px) {
    grid-column: 2/10;
  }
`;

const AnswerText = styled(motion.section)`
  background: ${({ $theme }) =>
    $theme.className === 'contrast'
      ? $theme.bgLight
      : hexToRGB({ color: defaultColors.tertiary, alpha: 0.15 })};
  color: ${({ $theme }) => $theme.textColor};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  grid-column: 1/7;
  line-height: 24px;
  overflow: hidden;
  @media only screen and (min-width: 1150px) {
    grid-column: 2/10;
  }
`;

export default Faq;
