import * as React from 'react';

function SvgEmojiLove(props) {
  return (<svg viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M70 37.596C70 58.341 56.002 70 35.295 70 14.587 70 .589 58.341.589 37.596.589 16.063 13.823.464 35.295.464 56.789.464 70 16.064 70 37.596Z"
      fill="#F55151"
    />
    <path
      d="M25.9 22.275c4.929 0 7.52 2.735 9.417 4.937 1.897-2.202 4.466-4.937 9.417-4.937 5.738 0 10.458 4.983 10.458 11.242 0 9.04-11.754 17.175-17.214 20.072a5.689 5.689 0 0 1-5.322 0c-5.46-2.897-17.214-11.033-17.214-20.072 0-6.259 4.72-11.242 10.458-11.242Z"
      fill="#fff"
    />
  </svg>)
}

export default SvgEmojiLove;
