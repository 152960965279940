import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { LocalContext } from 'context';
import TextInput from 'components/Events/Interaction/Polls/Shared/TextInput';

function WordCloudOptions({
  allowMultipleInputs,
  setAllowMultipleInputs,
  pollDescription,
  setPollDescription,
  characterLimit,
  setCharacterLimit,
  numberOfCharactersAllowed,
  setNumberOfCharactersAllowed
}) {
  const { selectedEvent } = useContext(LocalContext);
  return (
    <Wrapper>
      <Option>
        <OptionCheckbox
          colors={selectedEvent.colors}
          isEnabled={allowMultipleInputs}
          onClick={() => setAllowMultipleInputs((currentState) => !currentState)}>
          <div>
            <div />
          </div>
        </OptionCheckbox>
        Allow multiple inputs
      </Option>
      <Option>
        <OptionCheckbox
          colors={selectedEvent.colors}
          isEnabled={pollDescription.allowed}
          onClick={() =>
            setPollDescription((currentState) => ({
              ...currentState,
              allowed: !currentState.allowed
            }))
          }>
          <div>
            <div />
          </div>
        </OptionCheckbox>
        Include poll description
      </Option>
      <Option>
        <OptionCheckbox
          colors={selectedEvent.colors}
          isEnabled={characterLimit}
          onClick={() => {
            setCharacterLimit((currentState) => !currentState);
          }}>
          <div>
            <div />
          </div>
        </OptionCheckbox>
        Set character limit&nbsp;&nbsp;
        <AnimatePresence>
          {characterLimit && (
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <CharacterLimitInput
                type="number"
                min={12}
                max={68}
                value={numberOfCharactersAllowed}
                onChange={(e) => setNumberOfCharactersAllowed(parseInt(e.target.value, 10))}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </Option>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  left: -6px;
  margin-top: 1.1rem;
  position: relative;
`;

const Option = styled(motion.div)`
  align-items: center;
  color: #4f4f4f;
  display: flex;
  font-size: 0.875rem;
`;

const OptionCheckbox = styled.div`
  cursor: pointer;
  margin-right: 8px;
  padding: 6px;

  > div {
    align-items: center;
    border: 1px solid ${({ isEnabled, colors }) => (isEnabled ? colors.secondary : '#c4c4c4')};
    display: flex;
    height: 1rem;
    justify-content: center;
    transition: border 200ms ease-in-out;
    width: 1rem;

    > div {
      background-color: ${({ isEnabled, colors }) =>
        isEnabled ? colors.secondary : 'transparent'};
      height: 8px;
      transition: background-color 200ms ease-in-out;
      width: 8px;
    }

    &,
    > div {
      border-radius: 2px;
    }
  }
`;

const CharacterLimitInput = styled(TextInput)`
  margin-top: 1px;
  width: 40px;
`;

export default WordCloudOptions;
