import * as React from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';

function Heart({ fill, stroke, onClick, disabled }) {
  return (
    <Wrapper
      viewBox="0 0 17 17"
      xmlns="http://www.w3.org/2000/svg"
      fill={fill}
      stroke={stroke}
      onClick={() => !disabled && onClick()}
      disabled={disabled}>
      <path fillRule="evenodd" d="M 8.5 5 C 12.9 -2.2 24 5.7 8.5 16 C -7 5.7 4.1 -2.2 8.5 5 z" />
    </Wrapper>
  );
}

const Wrapper = styled(motion.svg).attrs(({ disabled }) => ({
  whileTap: !disabled && { scale: 0.8 }
}))`
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  fill: ${({ fill }) => fill};
  stroke: ${({ stroke }) => stroke};
  stroke-width: 0.063rem;
  width: 0.75rem;
`;

export default Heart;
