import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { LocalContext } from 'context';
import { H5, P, fullGridContainer } from 'styles';
import { Markup } from 'interweave';
import { useWindowSize } from 'hooks';
import { hexToRGB } from 'utils';

function Agenda({ agenda, colors }) {
  const { agendaRef, theme } = useContext(LocalContext);
  const { windowWidth } = useWindowSize();
  const [selected, setSelected] = useState(agenda[0]);
  const { slots } = selected;

  return (
    <Container>
      <AgendaList>
        <div ref={agendaRef} style={{ position: 'absolute', top: '-10rem' }} />
        <GridContainer>
          <GridTitle $colors={colors} $theme={theme}>
            Agenda
            <div />
          </GridTitle>
          {/* <Days>
          {agenda.map((agenda) => (
            <Selector
              key={agenda.day}
              $colors={colors}
              $theme={theme}
              $active={selected.day === agenda.day}
              onClick={() => {
                setSelected(agenda);
              }}>
              Day {agenda.day}
            </Selector>
          ))}
        </Days> */}

          <Entry $colors={colors} $theme={theme}>
            {/* <Title>{selected.title}</Title> */}
            {windowWidth >= 768 && (
              <Grid className="inactive">
                <Time>Time:</Time>
                <Name style={{ fontSize: '1rem', fontWeight: 'normal' }}>Livestream</Name>
                <Speaker style={{ fontSize: '1rem', fontWeight: 'normal' }}>
                  Keynote Speaker
                </Speaker>
              </Grid>
            )}

            {slots.map((slot) => (
              <Grid key={slot.orderNumber} $colors={colors}>
                <Time>
                  {windowWidth < 768 && <span>Time:</span>}
                  {slot.timeSlot}
                </Time>
                <Name>
                  <Markup content={slot.title} />
                </Name>
                <Speaker>
                  <Markup content={slot.speaker} />
                </Speaker>
              </Grid>
            ))}
          </Entry>
        </GridContainer>
      </AgendaList>
    </Container>
  );
}

const Container = styled.section`
  grid-column: 1/13;
  padding: 3.75rem 1.25rem;

  @media only screen and (min-width: 768px) {
    padding: 6rem 1.25rem;
  }

  @media only screen and (min-width: 1441px) {
    padding: 6rem 0;
  }
`;

const GridTitle = styled(H5)`
  color: ${({ $theme, $colors }) => ($theme.className === 'contrast' ? 'yellow' : '#fff')};
  grid-column: 2/6;
  margin-bottom: 0;

  @media only screen and (min-width: 768px) {
    grid-column: 2/12;
  }
  @media only screen and (min-width: 1441px) {
    grid-column: 3/11;
  }

  > div {
    background: ${({ $colors }) => $colors.primary};
    box-shadow: 0px 1px 7px #e3007d, 0px 0px 4px #db017b;
    height: 2px;
    margin: 8px 0 50px;
    width: 90px;
  }
`;

const Name = styled(P)`
  grid-column: 1/7;
  strong,
  ul {
    font-weight: 600;
  }
  ul {
    margin-left: 2rem;
  }
  @media only screen and (min-width: 768px) {
    grid-column: 3/7;
  }
  @media only screen and (min-width: 1150px) {
    grid-column: 2/7;
  }
`;

const Speaker = styled(P)`
  grid-column: 1/7;
  @media only screen and (min-width: 768px) {
    grid-column: 8/11;
  }
  /* @media only screen and (min-width: 1150px) {
    grid-column: 9/11;
  } */
`;

const Time = styled(P)`
  grid-column: 1/2;
  span {
    margin-right: 0.375rem;
  }
  @media only screen and (min-width: 768px) {
    grid-column: 1/3;
  }
  @media only screen and (min-width: 1150px) {
    grid-column: 1/2;
  }
`;

const Grid = styled.div`
  color: ${({ className, $colors }) => (className === 'inactive' ? 'initial' : '#fff')};
  display: grid;
  grid-column: 1/7;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(6, 1fr);
  margin-bottom: 1.75rem;
  padding: 0.5rem 1.5rem;
  transition: 0.25s;
  &:not(:first-of-type):hover {
    background: ${({ className, $colors }) =>
      className === 'inactive' ? 'initial' : hexToRGB({ color: $colors.primary, alpha: 0.2 })};
    box-shadow: 0 13px 13px -13px #e5007e;
  }
  @media only screen and (min-width: 768px) {
    margin-bottom: 0.75rem;
    grid-template-columns: repeat(10, 1fr);
    grid-column: 2/12;
    grid-gap: 2rem;
  }
  @media only screen and (min-width: 1441px) {
    grid-column: 3/11;
  }
`;

const Title = styled(H5)`
  color: #00004e;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.3em;
  margin-bottom: 2rem;
`;

const Entry = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  left: -1.5rem;
  position: relative;
  width: calc(100% + 1.5rem);

  ${Title}, ${Name}, ${Time}, ${Speaker} {
    color: ${({ $theme, $colors }) => ($theme.className === 'contrast' ? 'yellow' : '#fff')};
  }
`;

const Selector = styled.button`
  background: ${({ $active, $colors, $theme }) =>
    $active && $theme.className === 'contrast'
      ? 'yellow'
      : $active && $theme.className !== 'contrast'
      ? $colors.primary
      : 'transparent'};
  border: 1px solid;
  border-color: ${({ $active, $colors, $theme }) =>
    $active && $theme.className === 'contrast'
      ? 'black'
      : $active && $theme.className !== 'contrast'
      ? 'white'
      : !$active && $theme.className === 'contrast'
      ? 'yellow'
      : $colors.tertiary};
  border-radius: 2rem;
  color: ${({ $active, $colors, $theme }) =>
    $active && $theme.className === 'contrast'
      ? 'black'
      : $active && $theme.className !== 'contrast'
      ? 'white'
      : !$active && $theme.className === 'contrast'
      ? 'yellow'
      : $colors.tertiary};
  cursor: pointer;
  font-size: 1rem;
  font-weight: 400;
  height: 3rem;
  text-align: center;
  transition: 0.25s;
`;

const Days = styled.div`
  display: grid;
  grid-gap: 1.25rem;
  grid-template-columns: 7.5rem 7.5rem;
  margin: 2rem 0;
  width: auto;
`;

const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1/7;
  height: auto;
  justify-content: flex-start;
  width: 100%;
  @media only screen and (min-width: 1150px) {
    grid-column: 2/12;
  }
`;

const AgendaList = styled.div`
  display: grid;
  font-family: proxima-nova, sans-serif;
  grid-column: 1/13;
  grid-column-gap: 1rem;
  grid-template-columns: repeat(6, 1fr);
  height: auto;
  margin: 3rem auto;
  max-width: 1440px;
  padding: 1rem;
  position: relative;
  width: 100%;
  @media only screen and (min-width: 1150px) {
    grid-column-gap: 1.25rem;
    grid-template-columns: repeat(12, 1fr);
    margin: 0 auto;
  }
`;

export default Agenda;
