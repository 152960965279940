import React, { useState, useRef } from 'react';
import { AnimatePresence, Reorder } from 'framer-motion';
import { PollsAnswer as Answer } from 'components';

function Answers({
  answers,
  handleAnswerTextChange,
  isMarkCorrectAnswersEnabled,
  selectedEvent,
  setAnswers
}) {
  const [currentlyHoveredAnswer, setCurrentlyHoveredAnswer] = useState(null);

  const answersRef = useRef(null);

  const handleAnswerCheckboxChange = (e) =>
    setAnswers((currentState) =>
      currentState.map((answer) => {
        if (answer.id === e.target.parentElement.id) {
          answer.isCorrect = !answer.isCorrect;
        }
        return answer;
      })
    );

  const handleDeleteAnswer = ({ target }) =>
    setAnswers((currentState) =>
      currentState.filter((answer) => answer.id !== target.parentElement.id)
    );

  return (
    <Reorder.Group
      ref={answersRef}
      axis="y"
      values={answers}
      onReorder={setAnswers}
      style={{ listStyleType: 'none' }}>
      <AnimatePresence initial={false}>
        {answers.map((answer, i, arr) => (
          <Answer
            answer={answer}
            answersRef={answersRef}
            arr={arr}
            currentlyHoveredAnswer={currentlyHoveredAnswer}
            handleAnswerCheckboxChange={handleAnswerCheckboxChange}
            handleAnswerTextChange={handleAnswerTextChange}
            handleDeleteAnswer={handleDeleteAnswer}
            i={i}
            isMarkCorrectAnswersEnabled={isMarkCorrectAnswersEnabled}
            key={answer.id}
            selectedEvent={selectedEvent}
            setCurrentlyHoveredAnswer={setCurrentlyHoveredAnswer}
          />
        ))}
      </AnimatePresence>
    </Reorder.Group>
  );
}

export default Answers;
