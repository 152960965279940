import React, { useState, useContext, useEffect } from 'react';
import { navigate } from 'gatsby';
import { useWindowSize } from 'hooks';
import styled, { css } from 'styled-components';
import { Button, ErrorMessage, LoadingSpinner, Notification } from 'components';
import { DefaultAvatar } from 'assets/animated-svgs';
import SvgPlus from 'assets/svgs/Plus';
import { capitaliseFirstLetterOfText } from 'utils';
import { isMobile } from 'react-device-detect';
import { LocalContext, FirebaseContext } from 'context';
import { AnimatePresence, motion } from 'framer-motion';
import { updateEmail } from 'firebase/auth';
import { defaultColors, fadeInAndOutAndAnimateHeightVariants, glowModal } from 'styles';
import { FormContainer, FormInput, FormInputLabel, HiddenFileUpload } from '../FormComponents';
import {
  FbFooterIcon,
  IgFooterIcon,
  LinkedinFooterIcon,
  TwitterFooterIcon
} from '../../../assets/svgs';

let fileReader;

if (typeof window !== 'undefined') {
  fileReader = new FileReader();
}

const formInputLoadingSpinnerStyle = {
  position: 'absolute',
  color: '#fff',
  top: '0.6rem',
  left: 'calc(100% - 2.25rem)',
  width: '1.5rem'
};

function UserProfile({ handleLogout }) {
  const { selectedEvent, setShowUserAccountDeletedModal, theme, siteName } =
    useContext(LocalContext);
  const { user, firebase, loading } = useContext(FirebaseContext);
  const [avatar, setAvatar] = useState(user.avatarUrl);
  const [currentFormInput, setCurrentFormInput] = useState('');
  const { windowWidth } = useWindowSize();

  const [addSocials, setAddSocials] = useState(
    windowWidth > 767 ||
      !!user.socials?.facebook ||
      !!user.socials?.twitter ||
      !!user.socials?.linkedIn ||
      !!user.socials?.instagram
  );
  const [formValues, setFormValues] = useState({
    avatarFile: '',
    name: user.name,
    email: user.email,
    profession: user.profession,
    company: user.company,
    socials: {
      facebook: user.socials?.facebook,
      twitter: user.socials?.twitter,
      linkedIn: user.socials?.linkedIn,
      instagram: user.socials?.instagram
    }
  });
  const [savingChanges, setSavingChanges] = useState({
    name: false,
    email: false,
    avatar: false,
    profession: false,
    company: false,
    socials: {
      facebook: false,
      twitter: false,
      linkedIn: false,
      instagram: false
    }
  });
  const [notification, setNotification] = useState('');
  const [error, setError] = useState({
    code: '',
    message: ''
  });

  const [generatingCSVReport, setGeneratingCSVReport] = useState(false);
  // const [generatingEventCommentsReport, setGeneratingEventCommentsReport] = useState(false);
  const [requestAccountDeletionConfirmation, setRequestAccountDeletionConfirmation] =
    useState(false);
  const [deletingAccount, setDeletingAccount] = useState(false);
  const timeToWaitBeforeSavingChanges = 750;
  const colors = selectedEvent?.colors || defaultColors;

  useEffect(() => {
    fileReader.addEventListener('load', () => {
      const avatarFile = fileReader.result;
      setFormValues((currentStates) => ({
        ...currentStates,
        avatarFile
      }));
    });
  }, []);

  useEffect(() => {
    if (formValues.avatarFile) {
      setSavingChanges((currentState) => ({
        ...currentState,
        avatar: true
      }));

      /* We wait 2.5 seconds, just to buy the backend some time uploading the image and resizing it.
      Othwerise the user might wonder why the avatar has updated instantly on their profile popup,
      but not in their navbar or any comments. */
      setTimeout(() => {
        /* We set the local file in memory as the avatar here first, if the file is still being
        resized and uploaded to the database for persistence. */
        setAvatar(formValues.avatarFile);

        setSavingChanges((currentState) => ({
          ...currentState,
          avatar: false
        }));
      }, 2500);

      firebase
        .uploadAvatarToDatabase(formValues.avatarFile)
        .then(() => firebase?.auth?.currentUser?.reload());
    }
  }, [formValues.avatarFile]);

  useEffect(() => {
    if (user.avatarUrl !== avatar) {
      setAvatar(user.avatarUrl);
    }
  }, [user.avatarUrl]);

  useEffect(() => {
    if (formValues.name !== user.name) {
      setFormValues((currentStates) => ({
        ...currentStates,
        name: user.name
      }));
    }
    setSavingChanges((currentState) => ({
      ...currentState,
      name: false
    }));
  }, [user.name]);

  useEffect(() => {
    if (formValues.email !== user.email) {
      setFormValues((currentStates) => ({
        ...currentStates,
        email: user.email
      }));
    }
    setSavingChanges((currentState) => ({
      ...currentState,
      email: false
    }));
  }, [user.email]);

  useEffect(() => {
    if (formValues.profession !== user.profession) {
      setFormValues((currentValues) => ({
        ...currentValues,
        profession: user.profession
      }));
    }
    setSavingChanges((currentState) => ({
      ...currentState,
      profession: false
    }));
  }, [user.profession]);

  useEffect(() => {
    if (formValues.company !== user.company) {
      setFormValues((currentStates) => ({
        ...currentStates,
        company: user.company
      }));
    }
    setSavingChanges((currentState) => ({
      ...currentState,
      company: false
    }));
  }, [user.company]);

  useEffect(() => {
    if (formValues.facebook !== user.socials?.facebook) {
      setFormValues((currentState) => ({
        ...currentState,
        socials: {
          ...currentState.socials,
          facebook: user.socials?.facebook
        }
      }));
    }
    setSavingChanges((currentState) => ({
      ...currentState,
      socials: {
        ...currentState.socials,
        facebook: false
      }
    }));
  }, [user.socials?.facebook]);

  useEffect(() => {
    if (formValues.linkedIn !== user.socials?.linkedIn) {
      setFormValues((currentState) => ({
        ...currentState,
        socials: {
          ...currentState.socials,
          linkedIn: user.socials?.linkedIn
        }
      }));
    }
    setSavingChanges((currentState) => ({
      ...currentState,
      socials: {
        ...currentState.socials,
        linkedIn: false
      }
    }));
  }, [user.socials?.linkedIn]);

  useEffect(() => {
    if (formValues.twitter !== user.socials?.twitter) {
      setFormValues((currentState) => ({
        ...currentState,
        socials: {
          ...currentState.socials,
          twitter: user.socials?.twitter
        }
      }));
    }
    setSavingChanges((currentState) => ({
      ...currentState,
      socials: {
        ...currentState.socials,
        twitter: false
      }
    }));
  }, [user.socials?.twitter]);

  useEffect(() => {
    if (formValues.instagram !== user.socials?.instagram) {
      setFormValues((currentState) => ({
        ...currentState,
        socials: {
          ...currentState.socials,
          instagram: user.socials?.instagram
        }
      }));
    }
    setSavingChanges((currentState) => ({
      ...currentState,
      socials: {
        ...currentState.socials,
        instagram: false
      }
    }));
  }, [user.socials?.instagram]);

  useEffect(() => {
    if (error.message) {
      setError({
        code: '',
        message: ''
      });
    }

    const timer = setTimeout(() => {
      if (!savingChanges.name && formValues.name !== user.name) {
        setSavingChanges((currentState) => ({
          ...currentState,
          name: true
        }));
      }

      const changeName = async () => {
        await firebase
          .updateNameInDatabase({ uid: user.uid, name: formValues.name })
          .then(() => setNotification('Name updated'))
          .catch((err) => {
            console.error(err);
            setError({
              code: err.code,
              message: err.message
            });
            setSavingChanges((currentState) => ({
              ...currentState,
              name: false
            }));
          });
      };

      if (formValues.name !== '' && formValues.name !== user.name) {
        changeName();
      }
    }, timeToWaitBeforeSavingChanges);

    return () => clearTimeout(timer);
  }, [formValues.name]);

  useEffect(() => {
    if (error.message) {
      setError({
        code: '',
        message: ''
      });
    }

    const timer = setTimeout(() => {
      if (!savingChanges.email && formValues.email !== user.email) {
        setSavingChanges((currentState) => ({
          ...currentState,
          email: true
        }));
      }

      const changeEmail = async () => {
        await updateEmail(firebase.auth.currentUser, formValues.email)
          .then(() => firebase.updateEmailInDatabase({ uid: user.uid, email: formValues.email }))
          .then(() => {
            setNotification('Email address updated');
          })
          .catch((err) => {
            console.error(err);
            switch (err.code) {
              case 'auth/requires-recent-login':
                setError({
                  code: err.code,
                  message:
                    'Changing your email address is a sensitive operation and requires recent authentication.<br />Please log out and then log in again before retrying.'
                });
                break;
              default:
                setError({
                  code: err.code,
                  message: err.message
                });
                break;
            }
            setSavingChanges((currentState) => ({
              ...currentState,
              email: false
            }));
          });
      };

      if (formValues.email !== '' && formValues.email !== user.email) {
        changeEmail();
      }
    }, timeToWaitBeforeSavingChanges);

    return () => clearTimeout(timer);
  }, [formValues.email]);

  useEffect(() => {
    if (error.message) {
      setError({
        code: '',
        message: ''
      });
    }

    const timer = setTimeout(() => {
      if (!savingChanges.profession && formValues.profession !== user.profession) {
        setSavingChanges((currentState) => ({
          ...currentState,
          profession: true
        }));
      }

      const changeProfession = async () => {
        await firebase
          .updateProfessionInDatabase({ uid: user.uid, profession: formValues.profession })
          .then(() => setNotification('Profession updated'))
          .catch((err) => {
            console.error(err);
            setError({
              code: err.code,
              message: err.message
            });
          })
          .finally(() =>
            setSavingChanges((currentState) => ({
              ...currentState,
              profession: false
            }))
          );
      };

      if (formValues.profession !== user.profession) {
        changeProfession();
      }
    }, timeToWaitBeforeSavingChanges);

    return () => clearTimeout(timer);
  }, [formValues.profession]);

  useEffect(() => {
    if (error.message) {
      setError({
        code: '',
        message: ''
      });
    }

    const timer = setTimeout(() => {
      if (!savingChanges.company && formValues.company !== user.company) {
        setSavingChanges((currentState) => ({
          ...currentState,
          company: true
        }));
      }
      const changeCompany = async () => {
        await firebase
          .updateCompanyInDatabase({ uid: user.uid, company: formValues.company })
          .then(() => setNotification('Company updated'))
          .catch((err) => {
            console.error(err);
            setError({
              code: err.code,
              message: err.message
            });
            setSavingChanges((currentState) => ({
              ...currentState,
              company: false
            }));
          });
      };
      if (formValues.company !== '' && formValues.company !== user.company) {
        changeCompany();
      }
    }, timeToWaitBeforeSavingChanges);
    return () => clearTimeout(timer);
  }, [formValues.company]);

  useEffect(() => {
    if (error.message) {
      setError({
        code: '',
        message: ''
      });
    }

    const timer = setTimeout(() => {
      if (
        !savingChanges[currentFormInput] &&
        user.socials &&
        formValues[currentFormInput] !== user.socials[currentFormInput]
      ) {
        setSavingChanges((currentState) => ({
          ...currentState,
          socials: {
            ...currentState.socials,
            [currentFormInput]: true
          }
        }));
      }

      const changeSocials = async () => {
        await firebase
          .updateSocialsInDatabase({
            uid: user.uid,
            socials: {
              facebook: formValues.socials.facebook,
              linkedIn: formValues.socials.linkedIn,
              twitter: formValues.socials.twitter,
              instagram: formValues.socials.instagram
            }
          })
          .then(() =>
            setNotification(`${capitaliseFirstLetterOfText(currentFormInput)} handle updated`)
          )
          .catch((err) => {
            console.error(err);
            setError({
              code: err.code,
              message: err.message
            });
          })
          .finally(() =>
            setSavingChanges((currentState) => ({
              ...currentState,
              socials: {
                ...currentState.socials,
                [currentFormInput]: false
              }
            }))
          );
      };

      if (
        formValues.socials.facebook !== user.socials?.facebook ||
        formValues.socials.linkedIn !== user.socials?.linkedIn ||
        formValues.socials.twitter !== user.socials?.twitter ||
        formValues.socials.instagram !== user.socials?.instagram
      ) {
        changeSocials();
      }
    }, timeToWaitBeforeSavingChanges);

    return () => clearTimeout(timer);
  }, [
    formValues.socials.facebook,
    formValues.socials.linkedIn,
    formValues.socials.twitter,
    formValues.socials.instagram
  ]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'facebook' || name === 'linkedIn' || name === 'twitter' || name === 'instagram') {
      setFormValues((currentValues) => ({
        ...currentValues,
        socials: {
          ...currentValues.socials,
          [name]:
            value && (name === 'twitter' || name === 'instagram')
              ? value.charAt(0) === '@'
                ? value
                : `@${value}`
              : value
        }
      }));
    } else {
      setFormValues((currentValues) => ({
        ...currentValues,
        [name]: value
      }));
    }
    setCurrentFormInput(name);
  };

  const handleGenerateCSVReport = () => {
    setGeneratingCSVReport(true);
    firebase
      .generateCSVReport({ selectedEvent })
      .then(({ data }) => {
        setGeneratingCSVReport(false);
        return window.open(data, '_blank') || window.location.replace(data);
      })
      .catch(console.error);
  };

  // const handleGenerateEventCommentsReport = () => {
  //   setGeneratingEventCommentsReport(true);
  //   firebase
  //     .generateEventCommentsReport({ email: user.email, selectedEvent: selectedEvent?.name })
  //     .then(({ data }) => {
  //       setGeneratingEventCommentsReport(false);
  //       return window.open(data, '_blank') || window.location.replace(data);
  //     })
  //     .catch(console.error);
  // };

  const handleAccountDeletion = async () => {
    setDeletingAccount(true);
    try {
      await firebase?.auth?.currentUser.delete().then(() => {
        setShowUserAccountDeletedModal(true);
        navigate('/');
      });
    } catch (err) {
      console.error(err);
      switch (err.code) {
        case 'auth/requires-recent-login':
          setError({
            code: err.code,
            message:
              'Deleting your account is a sensitive operation and requires recent authentication. Please log out and then log in again before retrying.'
          });
          break;
        default:
          setError({
            code: err.code,
            message: err.message
          });
          break;
      }
    } finally {
      setDeletingAccount(false);
    }
  };

  return (
    <CustomContainer
      $width={isMobile ? '100%' : requestAccountDeletionConfirmation ? '500px' : '726px'}
      colors={colors}
      $theme={theme}
      requestAccountDeletionConfirmation={requestAccountDeletionConfirmation}
      style={{ paddingBottom: 0 }}>
      {requestAccountDeletionConfirmation ? (
        <>
          <p
            style={{
              fontSize: '1.25rem',
              fontWeight: 600,
              lineHeight: '1.75rem',
              marginBottom: '2.25rem',
              textAlign: 'center'
            }}>
            Are you sure you want to delete
            <br />
            your Virtuosity account?
          </p>
          <ErrorMessage
            errorMessage={error.message}
            style={{
              margin: '-0.75rem auto 2rem',
              maxWidth: '360px'
            }}
            variants={fadeInAndOutAndAnimateHeightVariants()}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              maxWidth: '420px',
              width: '100%',
              margin: '0 auto',
              marginBottom: '2.25rem'
            }}>
            <Button
              colors={colors}
              onClick={() => {
                if (error.message) {
                  setError({
                    code: '',
                    message: ''
                  });
                }
                setRequestAccountDeletionConfirmation(false);
              }}
              style={{
                // backgroundColor: selectedEvent?.colors.tertiary || defaultColors.tertiary,
                width: '200px'
              }}>
              Go Back
            </Button>
            {error.code === 'auth/requires-recent-login' ? (
              <Button
                colors={colors}
                savingChanges={savingChanges}
                type="button"
                onClick={() => handleLogout()}
                width="8rem"
                style={{
                  // backgroundColor: selectedEvent?.colors.tertiary || defaultColors.tertiary,
                  width: '200px'
                }}>
                Logout
              </Button>
            ) : (
              <Button
                colors={colors}
                onClick={handleAccountDeletion}
                style={{
                  // backgroundColor: selectedEvent?.colors.tertiary || defaultColors.tertiary,
                  width: '200px'
                }}>
                {deletingAccount ? (
                  <LoadingSpinner style={{ color: '#fff', width: '2.5rem' }} />
                ) : (
                  'Confirm Deletion'
                )}
              </Button>
            )}
          </div>
        </>
      ) : (
        <>
          <ProfileImageWrapper $theme={theme} $colors={colors}>
            {savingChanges.avatar ? (
              <AnimatePresence>
                {savingChanges.avatar && (
                  <LoadingSpinner
                    style={{
                      width: '6rem',
                      color: selectedEvent?.colors.secondary || defaultColors.secondary
                    }}
                  />
                )}
              </AnimatePresence>
            ) : (
              <AnimatePresence>
                {!savingChanges.avatar &&
                  (avatar ? (
                    <ProfileImage
                      src={avatar}
                      alt={user.name}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                    />
                  ) : (
                    <DefaultAvatar
                      width="70"
                      fill={
                        theme.className === 'contrast'
                          ? 'yellow'
                          : selectedEvent?.colors.secondary || defaultColors.secondary
                      }
                    />
                  ))}
              </AnimatePresence>
            )}
          </ProfileImageWrapper>
          {savingChanges.avatar ? (
            <AnimatePresence>
              {savingChanges.avatar && (
                <SavingNewAvatar
                  saving={savingChanges.avatar}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}>
                  Uploading
                </SavingNewAvatar>
              )}
            </AnimatePresence>
          ) : (
            <AnimatePresence>
              {!savingChanges.avatar && (
                <>
                  <SetOrChangeAvatarButton
                    htmlFor="avatarFile"
                    whileHover={{
                      backgroundColor: selectedEvent?.colors.secondary || defaultColors.secondary,
                      borderColor: selectedEvent?.colors.secondary || defaultColors.secondary
                    }}
                    whileTap={{ scale: 0.95 }}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}>
                    {`${avatar ? 'Change' : 'Set'} Profile Picture`}
                  </SetOrChangeAvatarButton>
                  <HiddenFileUpload
                    type="file"
                    name="avatarFile"
                    id="avatarFile"
                    onChange={(e) =>
                      e.target.files.length && fileReader.readAsDataURL(e.target.files[0])
                    }
                  />
                </>
              )}
            </AnimatePresence>
          )}
          <FieldContainer>
            <FieldSection>
              <FormInputLabel htmlFor="name" style={{ fontWeight: 400 }}>
                Full Name
              </FormInputLabel>
              <div style={{ position: 'relative' }}>
                <FormInput
                  id="name"
                  name="name"
                  type="text"
                  value={formValues.name}
                  onChange={handleInputChange}
                  style={{
                    marginBottom: '1.25rem',
                    height: '2.75rem'
                  }}
                  autoComplete="off"
                />
                {savingChanges.name && <LoadingSpinner style={formInputLoadingSpinnerStyle} />}
              </div>
              <FormInputLabel htmlFor="email" style={{ fontWeight: 400 }}>
                Email
              </FormInputLabel>
              <div style={{ position: 'relative' }}>
                <FormInput
                  id="email"
                  name="email"
                  type="email"
                  value={formValues.email}
                  onChange={handleInputChange}
                  style={{
                    marginBottom: '1.25rem',
                    height: '2.75rem'
                  }}
                  autoComplete="off"
                />
                {savingChanges.email && <LoadingSpinner style={formInputLoadingSpinnerStyle} />}
              </div>
              <FormInputLabel htmlFor="profession" style={{ fontWeight: 400 }}>
                Profession/Role
              </FormInputLabel>
              <div style={{ position: 'relative' }}>
                <FormInput
                  id="profession"
                  name="profession"
                  type="text"
                  value={formValues.profession}
                  onChange={handleInputChange}
                  style={{
                    marginBottom: '1.25rem',
                    height: '2.75rem'
                  }}
                  autoComplete="off"
                />
                {savingChanges.profession && (
                  <LoadingSpinner style={formInputLoadingSpinnerStyle} />
                )}
              </div>
              <FormInputLabel htmlFor="company" style={{ fontWeight: 400 }}>
                Company/Workplace
              </FormInputLabel>
              <div style={{ position: 'relative' }}>
                <FormInput
                  id="company"
                  name="company"
                  type="text"
                  value={formValues.company}
                  onChange={handleInputChange}
                  style={{
                    marginBottom: '1.25rem',
                    height: '2.75rem'
                  }}
                  autoComplete="off"
                />
                {savingChanges.company && <LoadingSpinner style={formInputLoadingSpinnerStyle} />}
              </div>
            </FieldSection>
            <FieldSection>
              {!addSocials ? (
                <AddField
                  onClick={() => setAddSocials(true)}
                  whileHover={{
                    scale: 1.01
                  }}
                  whileTap={{
                    scale: 0.95
                  }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}>
                  <SvgPlus height="12px" width="12px" />
                  <p>Add Social Media</p>
                </AddField>
              ) : (
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                  <FormInputLabel htmlFor="social" style={{ fontWeight: 400 }}>
                    Social Media
                  </FormInputLabel>
                  <SocialField>
                    <SocialLink colors={colors}>
                      <LinkedinFooterIcon width={16} />
                    </SocialLink>
                    <div style={{ position: 'relative', width: '100%' }}>
                      <FormInput
                        id="social"
                        name="linkedIn"
                        type="text"
                        value={formValues.socials.linkedIn}
                        onChange={handleInputChange}
                        style={{
                          marginBottom: '0.95rem',
                          width: '100%',
                          height: '2.75rem'
                        }}
                        placeholder="LinkedIn Profile URL"
                        autoComplete="off"
                      />
                      {savingChanges.socials.linkedIn && (
                        <LoadingSpinner style={formInputLoadingSpinnerStyle} />
                      )}
                    </div>
                  </SocialField>
                  <SocialField>
                    <SocialLink colors={colors}>
                      <FbFooterIcon width={10} />
                    </SocialLink>
                    <div style={{ position: 'relative', width: '100%' }}>
                      <FormInput
                        id="social"
                        name="facebook"
                        type="text"
                        value={formValues.socials.facebook}
                        onChange={handleInputChange}
                        style={{
                          marginBottom: '0.95rem',
                          width: '100%',
                          height: '2.75rem'
                        }}
                        placeholder="Facebook Profile URL"
                        autoComplete="off"
                      />
                      {savingChanges.socials.facebook && (
                        <LoadingSpinner style={formInputLoadingSpinnerStyle} />
                      )}
                    </div>
                  </SocialField>
                  <SocialField>
                    <SocialLink colors={colors}>
                      <TwitterFooterIcon width={18} />
                    </SocialLink>
                    <div style={{ position: 'relative', width: '100%' }}>
                      <FormInput
                        id="social"
                        name="twitter"
                        type="text"
                        value={formValues.socials.twitter}
                        onChange={handleInputChange}
                        style={{
                          marginBottom: '1rem',
                          height: '2.75rem'
                        }}
                        placeholder="Twitter Handle"
                        autoComplete="off"
                      />
                      {savingChanges.socials.twitter && (
                        <LoadingSpinner style={formInputLoadingSpinnerStyle} />
                      )}
                    </div>
                  </SocialField>
                  <SocialField>
                    <SocialLink colors={colors}>
                      <IgFooterIcon width={18} />
                    </SocialLink>
                    <div style={{ position: 'relative', width: '100%' }}>
                      <FormInput
                        id="social"
                        name="instagram"
                        type="text"
                        value={formValues.socials.instagram}
                        onChange={handleInputChange}
                        style={{
                          marginBottom: '0.95rem',
                          height: '2.75rem'
                        }}
                        placeholder="Instagram Handle"
                        autoComplete="off"
                      />
                      {savingChanges.socials.instagram && (
                        <LoadingSpinner style={formInputLoadingSpinnerStyle} />
                      )}
                    </div>
                  </SocialField>
                </motion.div>
              )}
            </FieldSection>
          </FieldContainer>
          <Notification
            style={{ margin: '0.75rem 0' }}
            notification={notification}
            callback={() => setNotification('')}
            timeout={3000}
          />
          <ErrorMessage
            errorMessage={error.message}
            variants={fadeInAndOutAndAnimateHeightVariants()}
            style={{ margin: '0.75rem 0' }}
          />
          <div style={{ alignSelf: 'center', marginTop: '1.5rem', marginBottom: '1.313rem' }}>
            <Button
              colors={colors}
              savingChanges={savingChanges}
              type="button"
              onClick={() => handleLogout()}
              width="8rem">
              Logout
            </Button>
          </div>
          {!loading && user.isAdmin && (
            <div
              style={{
                display: 'flex',
                height: '1.5rem',
                justifyContent: 'center',
                fontSize: '0.825rem',
                marginBottom: '10px',
                position: 'relative',
                width: '100%'
              }}>
              <AnimatePresence>
                {generatingCSVReport && (
                  <LoadingSpinner
                    style={{
                      color: '#fff',
                      position: 'absolute',
                      top: '-0.5rem',
                      width: '2.25rem'
                    }}
                  />
                )}
              </AnimatePresence>
              <AnimatePresence>
                {!generatingCSVReport && (
                  <GenerateReport
                    $theme={theme}
                    type="button"
                    whileHover={{
                      scale: 1.05
                    }}
                    whileTap={{
                      scale: 0.95
                    }}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    onClick={() => handleGenerateCSVReport()}>
                    {`User Report For ${selectedEvent?.name || siteName}`}
                  </GenerateReport>
                )}
              </AnimatePresence>
              {/* <AnimatePresence>
                {!generatingEventCommentsReport && (
                  <GenerateReport
                    type="button"
                    whileHover={{
                      scale: 1.05
                    }}
                    whileTap={{
                      scale: 0.95
                    }}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    onClick={() => handleGenerateEventCommentsReport()}>
                    {`Comments Report For ${selectedEvent?.name}`}
                  </GenerateReport>
                )}
              </AnimatePresence> */}
            </div>
          )}
          <DeleteAccount
            $theme={theme}
            type="button"
            whileHover={{
              scale: 1.05
            }}
            whileTap={{
              scale: 0.95
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{
              fontSize: '0.825rem',
              marginBottom: '1.75em'
            }}
            onClick={() => setRequestAccountDeletionConfirmation(true)}>
            Delete Account
          </DeleteAccount>
        </>
      )}
    </CustomContainer>
  );
}

const ProfileImageWrapper = styled.div`
  align-items: center;
  align-self: center;
  background: #c4c4c4;
  border: 0.2rem solid;
  border-color: ${({ $theme, $colors }) =>
    $theme.className === 'contrast' ? 'yellow' : $colors.secondary};
  border-radius: 50%;
  display: flex;
  height: 10rem;
  justify-content: center;
  margin-bottom: 1.75rem;
  margin-top: -7.5rem;
  overflow: hidden;
  width: 10rem;
`;

const ProfileImage = styled(motion.img)`
  background: transparent;
  height: 100%;
  object-fit: cover;
  width: 100%;
`;

const SavingNewAvatar = styled(motion.p)`
  align-items: center;
  display: flex;
  height: 2rem;
  justify-content: center;
  margin: 0 auto 2rem;
  text-align: center;
  text-transform: uppercase;
  width: 100%;

  ${({ saving }) =>
    saving &&
    css`
      &:after {
        animation: dots 1s steps(5, end) infinite;
        content: ' .';
        margin-left: 0.175rem;
      }

      @keyframes dots {
        0%,
        20% {
          color: rgba(0, 0, 0, 0);
          text-shadow: 0.35rem 0 0 rgba(0, 0, 0, 0), 0.7rem 0 0 rgba(0, 0, 0, 0);
        }
        40% {
          color: #fff;
          text-shadow: 0.35rem 0 0 rgba(0, 0, 0, 0), 0.7rem 0 0 rgba(0, 0, 0, 0);
        }
        60% {
          text-shadow: 0.35rem 0 0 #fff, 0.7rem 0 0 rgba(0, 0, 0, 0);
        }
        80%,
        100% {
          text-shadow: 0.35rem 0 0 #fff, 0.7rem 0 0 #fff;
        }
      }
    `}
`;

const SetOrChangeAvatarButton = styled(motion.label)`
  align-items: center;
  align-self: center;
  border: 1px solid #fff;
  border-radius: 3rem;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  height: 2rem;
  justify-content: center;
  margin-bottom: 2rem;
  max-width: 17rem;
  text-transform: uppercase;
  width: 100%;
`;

const CustomContainer = styled(FormContainer)`
  /* background-color: ${({ $theme, $colors }) =>
    $theme.className === 'contrast' ? 'black' : $colors.tertiary}; */
  margin-bottom: 1.25rem;
  margin-top: ${({ requestAccountDeletionConfirmation }) =>
    requestAccountDeletionConfirmation ? 0 : '5.25rem'};
  width: ${({ $width }) => $width};
  ${({ style }) => style}
  ${({ colors }) => glowModal(colors.secondary)}
`;

const GenerateReport = styled(motion.button)`
  background-color: transparent;
  color: ${({ $theme }) => ($theme.className === 'contrast' ? 'yellow' : 'white')};
  cursor: pointer;
  font-size: 0.825rem;
  margin: 0 auto;
  position: absolute;
  text-decoration: underline;
  top: 0;
`;

const DeleteAccount = styled(motion.button)`
  background-color: transparent;
  color: ${({ $theme }) => ($theme.className === 'contrast' ? 'yellow' : 'white')};
  cursor: pointer;
  font-size: 1rem;
  margin: 0 auto;
  text-decoration: underline;
`;

const SocialLink = styled.a`
  align-items: center;
  background-color: ${({ colors }) => colors.secondary};
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  height: 2.25rem;
  justify-content: center;
  margin-bottom: 1rem;
  margin-right: 0.85rem;
  transition: 0.5s;
  width: 2.25rem;

  svg {
    circle {
      transition: 0.5s;
      fill: #fff;
    }
    path {
      fill: #fff;
      transition: 0.5s;
    }
  }
`;

const SocialField = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
`;

const AddField = styled(motion.div)`
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-bottom: 1rem;
  p {
    font-weight: bold;
    margin-left: 0.5rem;
  }
`;

const FieldSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
`;

const FieldContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  @media only screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export default UserProfile;
