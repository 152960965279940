import React, { useContext, useState } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { Modal, SpeakerPopup, HelloImg } from 'components';
import { LocalContext } from 'context';
import { motion } from 'framer-motion';
import { useIntersection } from 'hooks';
import { GbBall, FbIcon, EmailIcon, LinkedinIcon, TwitterIcon } from 'assets/svgs';

function Speaker({ data, buttonText, speakers, colors, theme }) {
  const speakerImage = getImage(data.image);
  const [elRef, observerEntry] = useIntersection({ threshold: 0 });
  const [showHelloImg, setShowHelloImg] = useState(false);

  return (
    <Modal
      hideCloseButton
      trigger={
        <StyledCard
          $theme={theme}
          colors={colors}
          ref={elRef}
          initial={{ opacity: 0 }}
          onMouseEnter={() => setShowHelloImg(true)}
          onMouseLeave={() => setShowHelloImg(false)}
          animate={observerEntry.isIntersecting ? { opacity: 1 } : { opacity: 0 }}>
          <CardImgContainer>
            <YellowBall color={colors.tertiary} />
            <BlueBall color={colors.secondary} />
            <CardImg image={speakerImage} alt={data.name} />
            <StyledGBBall />
            <HelloImg showHelloImg={showHelloImg} />
          </CardImgContainer>
          <CardTitle colors={colors}>{data.name}</CardTitle>
          <CardSubtitle>{data.position}</CardSubtitle>
          <SpeakerSocials>
            <SpeakerSocial href={data.fbLink} $theme={theme} colors={colors}>
              <FbIcon />
            </SpeakerSocial>
            <SpeakerSocial href={data.linkedinLink} $theme={theme} colors={colors}>
              <LinkedinIcon />
            </SpeakerSocial>
            <SpeakerSocial href={data.twLink} $theme={theme} colors={colors}>
              <TwitterIcon />
            </SpeakerSocial>
            <SpeakerSocial href={data.emailLink} $theme={theme} colors={colors}>
              <EmailIcon />
            </SpeakerSocial>
          </SpeakerSocials>
          <CardTopics>
            <CardTopic>{data.title}</CardTopic>
          </CardTopics>
          <CardFooter>
            <div style={{ alignSelf: 'center' }}>
              <CardButton $theme={theme} colors={colors} hover>
                {buttonText}
              </CardButton>
            </div>
          </CardFooter>
        </StyledCard>
      }
      modalContent={<SpeakerPopup startPoint={data.id - 1} speakers={speakers} colors={colors} />}
    />
  );
}

export const YellowBall = styled.div`
  background-color: ${({ color }) => color};
  border-radius: 50%;
  box-shadow: 0px 4px 19px ${({ color }) => color};
  height: 82px;
  left: -1rem;
  opacity: 0;
  position: absolute;
  top: 2.5rem;
  width: 82px;
`;

export const BlueBall = styled.div`
  background-color: ${({ color }) => color};
  border-radius: 50%;
  bottom: 3rem;
  box-shadow: 0px 4px 19px ${({ color }) => color};
  height: 150px;
  left: -1rem;
  opacity: 0;
  position: absolute;
  width: 150px;
`;

export const StyledGBBall = styled(GbBall)`
  bottom: 1.75rem;
  opacity: 0;
  position: absolute;
  right: 1.2rem;
  width: 54px;
`;

const CardImgContainer = styled(motion.div)`
  filter: none;
  left: 50%;
  max-width: 300px;
  overflow: visible;
  position: relative;
  transform: translateX(-50%);
  transition-duration: 1s;
`;

const CardImg = styled(GatsbyImage)`
  border-radius: 50%;
  cursor: pointer;
  margin-bottom: 1.25rem;
  object-fit: cover;
  object-position: center;
  transition: 0.5s;
`;

const CardTitle = styled.h3`
  color: #333;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 0px;
  margin-top: 0px;
  text-align: center;
`;

const CardSubtitle = styled.p`
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2rem;
  margin-bottom: 0.375rem;
  margin-top: 0px;
  text-align: center;
`;

const CardTopics = styled.p`
  margin-bottom: 2rem;
  text-align: center;
`;

const CardTopic = styled.span`
  font-size: 1.25rem;
  line-height: 2rem;
  margin-bottom: 0;
  margin-right: 0.375rem;

  &:after {
    content: ',';
  }

  &:last-child {
    &:after {
      content: '';
    }
  }
`;

const CardButton = styled.div`
  align-items: center;
  background-color: transparent;
  color: ${({ $theme }) => $theme.textColor};
  cursor: pointer;
  display: flex;
  font-family: Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  height: 3rem;
  justify-content: center;
  text-transform: uppercase;
  transition: 0.5s;
  width: 100%;

  :hover {
    color: ${({ $theme }) => $theme.fg};
  }

  @media only screen and (min-width: 1150px) {
    justify-content: center;
  }
`;

const CardFooter = styled.div``;

const SpeakerSocial = styled.a`
  margin-right: 0.625rem;

  svg {
    width: 1.5rem;
    circle {
      fill: #c4c4c4;
      transition: 0.5s;
    }
    path {
      fill: white;
      transition: 0.5s;
    }
  }
  :last-child {
    svg {
      path {
        fill: none;
        stroke: white;
        transition: 0.5s;
      }
    }
  }
`;

const StyledCard = styled(motion.div)`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 18px 4.95rem;
  max-width: 264px;
  width: 100%;

  ${CardTitle}, ${CardSubtitle}, ${CardButton}, ${CardImg}, ${SpeakerSocial}, ${StyledGBBall}, ${YellowBall}, ${BlueBall} {
    transition: all 0.375s ease-in-out;
  }

  p,
  h3 {
    color: #fff;
  }

  &:hover {
    ${CardTitle}, ${CardSubtitle}, ${CardButton} {
      color: ${({ colors }) => colors.primary};
    }

    ${CardImg} {
      box-shadow: 0px 0.375rem 0.75rem rgba(0, 0, 0, 0.25);
    }

    ${SpeakerSocial} {
      svg {
        circle {
          fill: ${({ colors }) => colors.secondary};
        }
        path {
          fill: white;
        }
      }
      :last-child {
        svg {
          path {
            fill: none;
            stroke: white;
            transition: 0.5s;
          }
        }
      }
    }

    @media only screen and (min-width: 1150px) {
      ${StyledGBBall} {
        opacity: 1;
      }

      ${YellowBall} {
        opacity: 1;
        top: 1rem;
      }

      ${BlueBall} {
        opacity: 1;
        bottom: 1.25rem;
      }
    }
  }
`;

const SpeakerSocials = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
`;

export default Speaker;
