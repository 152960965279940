import React, { useContext, useEffect, useState, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { DateButton, Livestream, Interaction } from 'components';
import styled from 'styled-components';
import { AnimatePresence } from 'framer-motion';
import { LocalContext, FirebaseContext } from 'context';
import { Markup } from 'interweave';

function EventHeader({ event }) {
  const { theme } = useContext(LocalContext);
  const { firebase, user } = useContext(FirebaseContext);
  const {
    colors,
    eid,
    name,
    streamUrl,
    isChatEnabled,
    isQAndAEnabled,
    isPollsEnabled,
    isParticipantsEnabled
  } = event;
  const [questionCurrentlyBeingAnsweredLive, setQuestionCurrentlyBeingAnsweredLive] =
    useState(null);
  const [forcedActiveTab, setForcedActiveTab] = useState(null);
  const [currentlyOpenWordCloudPollId, setCurrentlyOpenWordCloudPollId] = useState(null);

  const { brickWallImg } = useStaticQuery(graphql`
    query {
      brickWallImg: file(extension: { eq: "jpg" }, name: { eq: "header_background" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
        }
      }
    }
  `);

  /* TODO: Refactor/fix this */
  // useEffect(() => {
  //   const keyDownListener = (e) => {
  //     if (e.code === 'Enter' || e.code === 'NumpadEnter') {
  //       if (activeTab === 'Chat') {
  //         handleUserCommentSubmit(e);
  //       } else if (activeTab === 'Q&A') {
  //         handleSubmitNewQuestion(e);
  //       }
  //     }
  //   };
  //   document.addEventListener('keydown', keyDownListener);
  //   return () => {
  //     document.removeEventListener('keydown', keyDownListener);
  //   };
  // }, [activeTab, userCommentText, textArea]);

  useEffect(() => {
    let unsubscribeFromEventUpdates;

    if (firebase) {
      unsubscribeFromEventUpdates = firebase.subscribeToEventUpdates({
        eid,
        snapshot: (snapshot) => {
          if (snapshot.exists) {
            const {
              forcedActiveTab: _forcedActiveTab,
              questionCurrentlyBeingAnsweredLive: _questionCurrentlyBeingAnsweredLive,
              currentlyOpenWordCloudPollId: _currentlyOpenWordCloudPollId
            } = snapshot.data();

            if (_forcedActiveTab || _forcedActiveTab === null) {
              setForcedActiveTab(_forcedActiveTab);
            }

            if (_questionCurrentlyBeingAnsweredLive) {
              setQuestionCurrentlyBeingAnsweredLive(_questionCurrentlyBeingAnsweredLive);
            } else {
              setQuestionCurrentlyBeingAnsweredLive(null);
            }

            if (_currentlyOpenWordCloudPollId) {
              setCurrentlyOpenWordCloudPollId(_currentlyOpenWordCloudPollId);
            } else {
              setCurrentlyOpenWordCloudPollId(null);
            }
          } else if (!snapshot.exists) {
            /* CONOR TODO: Is there a better way of doing this? Can we automatically add the event doc somehow? Hmmmm */
            console.error(
              "You need to add a doc for this event in the 'Events' collection in Firebase."
            );
          }
        }
      });
      return () => {
        if (unsubscribeFromEventUpdates) {
          unsubscribeFromEventUpdates();
        }
      };
    }
  }, [firebase]);

  return (
    <Container
      $theme={theme}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 2 }}>
      <BrickWallImg image={getImage(brickWallImg)} alt="Brick Wall" />
      <Details>
        <H3 colors={event.colors}>
          <Markup content={event.date} noWrap />
        </H3>
        <H3>Virtual Conference</H3>
        <H1 colors={event.colors}>
          <Markup content={`Welcome to ${event.name}`} noWrap />
        </H1>
      </Details>
      <AnimatePresence>
        {user && (
          <Livestream
            eid={eid}
            colors={colors}
            eventName={event.name}
            streamUrl={event.streamUrl}
            questionCurrentlyBeingAnsweredLive={questionCurrentlyBeingAnsweredLive}
            currentlyOpenWordCloudPollId={currentlyOpenWordCloudPollId}
          />
        )}
      </AnimatePresence>
      {isChatEnabled && (
        <Interaction
          eid={eid}
          colors={colors}
          forcedActiveTab={forcedActiveTab}
          questionCurrentlyBeingAnsweredLive={questionCurrentlyBeingAnsweredLive}
          isQAndAEnabled={isQAndAEnabled}
          isPollsEnabled={isPollsEnabled}
          isParticipantsEnabled={isParticipantsEnabled}
        />
      )}
    </Container>
  );
}

const Container = styled.header`
  display: grid;
  font-family: proxima-nova, sans-serif;
  grid-column: 1/13;
  grid-row-gap: 2rem;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: minmax(min-content, max-content);
  padding: 6.45rem 14px 20px;
  position: relative;
  width: 100%;

  @media screen and (min-width: 500px) {
    padding: 6.45rem 1.25rem 1.25rem;
  }

  @media screen and (min-width: 768px) {
    padding: 6.45rem 1.25rem 7.75rem;
  }

  @media screen and (min-width: 1150px) {
    grid-row-gap: 2rem;
    grid-column-gap: 1.25rem;
    padding: 8.9rem 1.25rem 3.25rem;
  }
`;

const BrickWallImg = styled(GatsbyImage)`
  height: 100%;
  left: 0;
  position: absolute !important;
  top: 0;
  width: 100%;
  z-index: -1;
`;

const Details = styled.div`
  grid-column: 1 / -1;
  grid-row: 1 / 2;

  @media screen and (min-width: 1150px) {
    grid-column: 2 / -1;
  }
`;

const H1 = styled.h1`
  color: #fff;
  font-size: 3rem;
  margin: 0.25em 0 0.45em;
`;

const H3 = styled.h3`
  color: ${({ colors }) => (colors ? colors.primary : '#fff')};
  font-size: 1.5rem;
  margin-bottom: 0.225em;
`;

export default EventHeader;
