import React, { useContext } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql } from 'gatsby';
import { motion } from 'framer-motion';
import { RegistrationForm, Button, Modal, Video } from 'components';
// import { FlickeringLogo } from 'assets/animated-svgs';
import { LocalContext, FirebaseContext, SmootherContext } from 'context';
import { hexToRGB, scrollToRef } from 'utils';

function HubHeader(props) {
  const { isMobile, theme, registerRef } = useContext(LocalContext);
  const { firebase } = useContext(FirebaseContext);
  const smoother = useContext(SmootherContext);
  const currentUser = firebase?.auth?.currentUser;

  const { brickWallImg, virtuosityLogo } = useStaticQuery(graphql`
    query {
      brickWallImg: file(extension: { eq: "jpg" }, name: { eq: "header_background" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      virtuosityLogo: file(extension: { eq: "png" }, name: { eq: "virtuosity_logo" }) {
        childImageSharp {
          gatsbyImageData(width: 860, placeholder: BLURRED)
        }
      }
    }
  `);

  return (
    <Container
      $theme={theme}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 2 }}>
      <BrickWallImg image={getImage(brickWallImg)} alt="Brick Wall" />
      <Overlay />
      <Text>
        <FlickeringH2
          glowColor={hexToRGB({ color: props.colors.secondary, alpha: 0.35 })}
          duration={3}>
          Get Broadcasting Presents
        </FlickeringH2>
        {/* <FlickeringLogo intensity={2} blur={10} /> */}
        <PNGLogo>
          <GatsbyImage image={getImage(virtuosityLogo)} alt="Virtuosity Wall" />
        </PNGLogo>
        <FlickeringH2
          glowColor={hexToRGB({ color: props.colors.primary, alpha: 0.3 })}
          duration={3.5}>
          Get Virtual. Go Live. Get Broadcasting!
        </FlickeringH2>
        {!currentUser &&
          (isMobile ? (
            <Modal
              trigger={
                <ButtonWrapper>
                  <Button width="11rem" height="40px" colors={props.colors}>
                    Register Here
                  </Button>
                </ButtonWrapper>
              }
              modalContent={<RegistrationForm colors={props.colors} />}
            />
          ) : (
            <ButtonWrapper>
              <Button
                width="11rem"
                height="40px"
                glow
                onClick={() => scrollToRef({ smoother, ref: registerRef })}
                colors={props.colors}>
                Register Here
              </Button>
            </ButtonWrapper>
          ))}
      </Text>
      <StyledVideo
        src="/videos/homepage_banner_video.mp4"
        autoPlay
        muted
        controls
        colors={props.colors}
      />
    </Container>
  );
}

const Container = styled.header`
  display: flex;
  grid-column: 1/13;
  justify-content: center;
  position: relative;
  z-index: 1;
`;

const BrickWallImg = styled(GatsbyImage)`
  max-height: 75rem;
  min-height: 49.375rem;
  width: 100%;
`;

const Overlay = styled.div`
  background-color: rgba(000, 000, 000, 0.125);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

const StyledVideo = styled(Video)`
  animation: initVideoFadeIn 1s 1s ease-in-out forwards;
  bottom: -1.875rem;
  max-width: 49.125rem;
  opacity: 0;
  position: absolute;
  width: calc(100vw - 1.5rem);

  @keyframes initVideoFadeIn {
    from {
      opacity: 0;
      bottom: -4rem;
    }
    to {
      bottom: -1.875rem;
      opacity: 1;
    }
  }

  @media screen and (min-width: 34.375rem) {
    width: 33.375rem;
  }

  @media screen and (min-width: 48rem) {
    bottom: -11.625rem;
    width: 64.44vw;
    max-width: 82.875rem;

    @keyframes initVideoFadeIn {
      from {
        opacity: 0;
        bottom: -15rem;
      }
      to {
        bottom: -11.625rem;
        opacity: 1;
      }
    }
  }
`;

const Text = styled.div`
  align-items: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 21%;
  width: 100%;
`;

const textFlicker = (glowColor) => keyframes`
  0%, 19%, 21%, 23%, 25%, 54%, 56%, 100% {
    text-shadow:
      -0.05em -0.05em 0.25em rgba(255, 255, 255, 0.75),
      0.05em 0.05em 0.25em rgba(255, 255, 255, 0.75),
      0 0.25em 0.25em ${glowColor},
      0 0.25em 0.5em ${glowColor},
      0 0.25em 0.75em ${glowColor},
      0 0.25em 1em ${glowColor},
      0 0.25em 1.25em ${glowColor};
  }

  20%, 24%, 55% {
    text-shadow: none;
  }
`;

const FlickeringH2 = styled(motion.h2).attrs({
  initial: {
    y: '1rem',
    opacity: 0
  },
  animate: {
    y: 0,
    opacity: 1
  },
  transition: {
    duration: 0.5,
    delay: 1
  }
})`
  animation: ${({ glowColor, duration }) =>
    css`
      ${textFlicker(glowColor)} ${duration}s infinite alternate
    `};
  font-family: proxima-nova, sans-serif;
  font-size: 1.5rem;
  text-align: center;

  &:first-of-type {
    margin: 0 0.75rem 0.688rem;
    text-transform: uppercase;
  }

  &:last-of-type {
    margin: 1rem 0.75rem 2.5rem;
  }
`;

const PNGLogo = styled(motion.div).attrs({
  initial: {
    opacity: 0,
    scale: 0
  },
  animate: {
    opacity: 1,
    scale: [0, 1.15, 1]
  },
  transition: {
    delay: 1.2,
    duration: 0.7
  }
})`
  animation: flicker 3s linear infinite;
  margin: 0 0.75rem;

  @keyframes flicker {
    from {
      opacity: 0;
    }

    3% {
      opacity: 0.9;
    }

    6% {
      opacity: 0.85;
    }

    8% {
      opacity: 0.95;
    }

    10% {
      opacity: 0.9;
    }

    11% {
      opacity: 0.922;
    }

    12% {
      opacity: 0.9;
    }

    14% {
      opacity: 0.95;
    }

    16% {
      opacity: 0.98;
    }

    17% {
      opacity: 0.9;
    }

    19% {
      opacity: 0.93;
    }

    20% {
      opacity: 0.99;
    }

    24% {
      opacity: 1;
    }

    26% {
      opacity: 0.94;
    }

    28% {
      opacity: 0.98;
    }

    37% {
      opacity: 0.93;
    }

    38% {
      opacity: 0.5;
    }

    39% {
      opacity: 0.96;
    }

    42% {
      opacity: 1;
    }

    44% {
      opacity: 0.97;
    }

    46% {
      opacity: 0.94;
    }

    56% {
      opacity: 0.9;
    }

    58% {
      opacity: 0.9;
    }

    60% {
      opacity: 0.99;
    }

    68% {
      opacity: 1;
    }

    70% {
      opacity: 0.9;
    }

    72% {
      opacity: 0.95;
    }

    93% {
      opacity: 0.93;
    }

    95% {
      opacity: 0.95;
    }

    97% {
      opacity: 0.93;
    }

    to {
      opacity: 1;
    }
  }
`;

const ButtonWrapper = styled(motion.div).attrs({
  initial: {
    opacity: 0
  },
  animate: {
    opacity: 1
  },
  transition: {
    duration: 0.5,
    delay: 1.7
  }
})``;

export default HubHeader;
