import * as React from 'react';

function SvgQAndA(props) {
  return (<svg viewBox="0 0 270 193" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M149.8 0H6a6 6 0 0 0-6 6v105.69a6.003 6.003 0 0 0 6 6h15.8v23.83l29.42-23.87h98.58a6.003 6.003 0 0 0 4.243-1.757 6.003 6.003 0 0 0 1.757-4.243V6a6 6 0 0 0-6-6Z"
      fill="#36D1B7"
    />
    <path
      d="M263.599 50.95h-143.8a6 6 0 0 0-6 6v105.69a6.002 6.002 0 0 0 6 6h98.58l29.42 23.88v-23.86h15.8a6 6 0 0 0 6-6V56.95a5.997 5.997 0 0 0-6-6Z"
      fill="#2D9CDB"
    />
    <path
      d="m99 79.75-3.87 4.31-6.6-5.76a19.332 19.332 0 0 1-5.26 2.09 23.876 23.876 0 0 1-5.39.63 21.261 21.261 0 0 1-9.09-1.87 19.882 19.882 0 0 1-6.81-5.1 22.641 22.641 0 0 1-4.28-7.54 27.997 27.997 0 0 1-1.49-9.18 27.878 27.878 0 0 1 1.49-9.13 22.829 22.829 0 0 1 4.28-7.54 20.48 20.48 0 0 1 6.81-5.13 20.998 20.998 0 0 1 9.09-1.9c3.133-.053 6.24.596 9.09 1.9a20.38 20.38 0 0 1 6.81 5.13 22.829 22.829 0 0 1 4.28 7.54 27.881 27.881 0 0 1 1.49 9.13 30.333 30.333 0 0 1-.38 4.72 28.694 28.694 0 0 1-1.14 4.65 22.655 22.655 0 0 1-2 4.34 15.702 15.702 0 0 1-2.91 3.65L99 79.75ZM78.41 69.52l3.8-4.37 5.51 4.81a15.271 15.271 0 0 0 3-5.89c.59-2.188.89-4.444.89-6.71a25.442 25.442 0 0 0-.79-6.34 17.503 17.503 0 0 0-2.44-5.54 12.25 12.25 0 0 0-4.28-3.9 12.679 12.679 0 0 0-6.24-1.46c-2.171-.056-4.32.447-6.24 1.46a12.25 12.25 0 0 0-4.28 3.9 17.259 17.259 0 0 0-2.43 5.54 25.438 25.438 0 0 0-.8 6.34c0 2.135.268 4.262.8 6.33a17.41 17.41 0 0 0 2.43 5.55 12.32 12.32 0 0 0 4.28 3.89 12.68 12.68 0 0 0 6.24 1.46 14.164 14.164 0 0 0 2.57-.25c.864-.172 1.704-.45 2.5-.83l-4.52-3.99ZM187.449 83.14h8.43l17.42 45.24h-8.49l-4.24-12h-18.04l-4.24 12h-8.17l17.33-45.24Zm-2.78 27.25h13.86l-6.78-19.46h-.22l-6.86 19.46Z"
      fill="#fff"
    />
  </svg>)
}

export default SvgQAndA;
