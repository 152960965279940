import React, { useContext } from 'react';
import styled from 'styled-components';
import { Redirect } from '@reach/router';
import { LoadingSpinner, Modal, LoginModal } from 'components';
import { FirebaseContext } from 'context';
import { AnimatePresence, motion } from 'framer-motion';
import { defaultColors } from 'styles';

function ProtectedRoute({ children }) {
  const { firebase, loading } = useContext(FirebaseContext);
  const currentUser = firebase?.auth?.currentUser;
  return (
    <>
      <AnimatePresence>
        {loading && (
          <LoadingSpinnerWrapper animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <LoadingSpinner style={{ width: '8rem' }} />
          </LoadingSpinnerWrapper>
        )}
      </AnimatePresence>
      {!loading &&
        firebase &&
        (currentUser ? (
          children
        ) : !currentUser ? (
          <Modal
            openWithoutTrigger
            redirectIfNotAuthorised
            hideCloseButton
            modalContent={<LoginModal colors={defaultColors} />}
          />
        ) : (
          <Redirect to="/" noThrow />
        ))}
    </>
  );
}

const LoadingSpinnerWrapper = styled(motion.div)`
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 100vw;
`;

export default ProtectedRoute;
