import React, { useState, useContext, useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import {
  Navbar,
  EmailNotVerifiedNotification,
  Modal,
  AccountDeleted,
  EmailHandler,
  Footer
} from 'components';
import { LocalContext, SmootherContext } from 'context';
import { gsap } from 'gsap';
import { useLocation } from '@reach/router';
import queryString from 'query-string';
import { FirebaseContext, useAuth } from 'firebase';
import ScrollTrigger from 'gsap/ScrollTrigger';
import ScrollSmoother from 'gsap/ScrollSmoother';
import { defaultColors } from 'styles';
import { useIsomorphicLayoutEffect } from 'hooks';
import '../../assets/fonts/fonts.css';

const GlobalStyles = createGlobalStyle`
:root {
  --primary-color: #E4097E;
  --secondary-color: #189BD7;
  --tertiary-color: #FFEC00;
}
  * {
      margin:0;
      padding:0;
      box-sizing:border-box;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  html{
    font-size: ${({ font }) => font};
  }

  body {
    background: ${({ $theme }) => ($theme.className === 'contrast' ? 'black' : 'white')};
    overflow-x:hidden;
    font-family: 'Helvetica Neue', sans-serif;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  div [class*="PageWrapper"] {
    background: ${({ $theme }) => ($theme.className === 'contrast' ? 'black' : '#161616')};
  }

  a{
    text-decoration: none;
  }

  button{
    border:none;
    outline:none;
    &:focus{
        outline:none;
        border:none;
    }
  }
  .scroll-lock {
    overflow: hidden;
  }

  /* Address autocomplete dropdown style overrides */
  .pac-container {
    background: ${({ selectedEvent }) =>
      selectedEvent ? selectedEvent.colors.tertiary : 'var(--secondary-color)'};
    border: 0.063rem solid #fff;
    border-top-width: 0;
    border-radius: 0;
    box-shadow: none;

    @media screen and (min-width: 1439px) {
      max-width: 423px !important;
      width: 100% !important;
    }
  }

.pac-target-input {
  outline: none;
  width: 100%;
}

.pac-target-input:focus {
  border: 0.2rem solid #fff !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.pac-item {
  color: #fff;
  background-color: transparent;
  font-family: 'Noto Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: bold;
  letter-spacing: 0.031rem;
  padding: 0.188rem 1.25rem 0;
  border: none;
  height: 2.3rem;
}

.pac-item:hover {
  background-color: ${({ selectedEvent }) =>
    selectedEvent ? selectedEvent.colors.primary : 'var(--primary-color)'};
  cursor: pointer;
}

.pac-logo {
  box-sizing: content-box;
  border: 0.188rem solid #fff;
  border-top: none;

  @media screen and (min-width: 1440px) {
    box-sizing: border-box;
  }
}

.pac-logo:after {
  padding: 0;
  height: 0;
}

.hdpi.pac-logo:after {
  padding: 0;
  height: 0;
}

.pac-icon,
.hdpi .pac-icon {
  display: none;
}

.pac-item-query {
  color: #fff;
  font-size: 1rem;
}

.pac-item-query:after {
  content: ','
}

.pac-selected {
  background-color: ${({ selectedEvent }) =>
    selectedEvent ? selectedEvent.colors.tertiary : 'var(--secondary-color)'};
}
`;

const Wrapper = styled.div`
  background: #161616;
  box-sizing: border-box;
  display: grid;
  filter: ${({ $theme }) => ($theme.className === 'grayscale' ? 'grayscale(100%)' : 'none')};
  grid-column-gap: 0.625rem;
  grid-template-columns: repeat(6, 1fr);
  @media only screen and (min-width: 1150px) {
    grid-column-gap: 1.25rem;
    grid-template-columns: repeat(12, 1fr);
  }
`;

function Layout({ children }) {
  const {
    theme,
    fontSizing,
    selectedEvent,
    aNewUserHasRegisteredForAnEvent,
    showUserAccountDeletedModal,
    setShowUserAccountDeletedModal,
    siteName
  } = useContext(LocalContext);
  const [smoother, setSmoother] = useState();
  const [
    returningUserHasNotConfirmedTheirEmailAddressYet,
    setReturningUserHasNotConfirmedTheirEmailAddressYet
  ] = useState(false);
  const [newUserLocalStorageData, setNewUserLocalStorageData] = useState(null);
  const { user, firebase, loading } = useAuth();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const { mode } = queryParams;
  const colors = selectedEvent ? selectedEvent.colors : defaultColors;

  useIsomorphicLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

    const _smoother = ScrollSmoother.create({
      smooth: 1,
      normalizeScroll: true, // prevents address bar from showing/hiding on most devices, solves various other browser inconsistencies
      ignoreMobileResize: true, // skips ScrollTrigger.refresh() on mobile resizes from address bar showing/hiding
      effects: true
    });

    setSmoother(_smoother);
  }, []);

  // newUserLocalStorageData is cleared from the browser's local storage as soon as their 'user' doc
  // is first created in the database, so if we can still get 'newUserPanacea' from local
  // storage below, then we know they haven't clicked the link yet. We then pass that set
  // newUserLocalStorageData as state, which triggers the second useEffect, which in turn mounts
  // the EmailNotVerifiedNotification component again, which re-sends the email and notifies the user.
  useEffect(() => {
    if (!loading && firebase) {
      const data = JSON.parse(window.localStorage.getItem(`newUser${siteName}`));
      if (!firebase?.auth?.currentUser && data && !mode) {
        setNewUserLocalStorageData(data);
      }
    }
    return () => {
      // This is just for safety, though hopefully not necessary.
      if (firebase?.auth?.currentUser) {
        window.localStorage.removeItem(`newUser${siteName}`);
        window.localStorage.removeItem(`existingUser${siteName}`);
        window.localStorage.removeItem(`referringUrl${siteName}`);
      }
    };
  }, [loading, firebase]);

  useEffect(() => {
    if (newUserLocalStorageData) {
      setReturningUserHasNotConfirmedTheirEmailAddressYet(true);
    }
  }, [newUserLocalStorageData]);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <FirebaseContext.Provider value={{ user, firebase, loading }}>
      <SmootherContext.Provider value={smoother}>
        <GlobalStyles $theme={theme} font={fontSizing} selectedEvent={selectedEvent} />
        <Navbar colors={colors} />
        <Wrapper $theme={theme} id="smooth-content">
          <EmailHandler />
          {children}
          {!mode &&
            (aNewUserHasRegisteredForAnEvent ||
              returningUserHasNotConfirmedTheirEmailAddressYet) && (
              <EmailNotVerifiedNotification
                colors={colors}
                newUserLocalStorageData={newUserLocalStorageData}
                returningUserHasNotConfirmedTheirEmailAddressYet={
                  returningUserHasNotConfirmedTheirEmailAddressYet
                }
              />
            )}
          <Footer colors={colors} />
          <Modal
            hideCloseButton
            openWithoutTrigger={showUserAccountDeletedModal}
            modalContent={
              <AccountDeleted
                setShowUserAccountDeletedModal={setShowUserAccountDeletedModal}
                onClickOutside={() => setShowUserAccountDeletedModal(false)}
              />
            }
          />
        </Wrapper>
      </SmootherContext.Provider>
    </FirebaseContext.Provider>
  );
}

export default Layout;
