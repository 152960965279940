import * as React from 'react';

function SvgGbBall(props) {
  return (<svg viewBox="0 0 54 53" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#gb_ball_svg__a)">
      <path
        d="M50.505 26.058a14.36 14.36 0 0 0 .845-2.171c.054-.186.099-.374.143-.56.036-.147.057-.268.09-.386 3.685-13.844-23.71-33.014-41.568-16.657a16.666 16.666 0 0 1 4.915-.722c1.054 0 1.92.094 2.503.183 6.973.495 14.031 4.442 16.69 5.93 1.902 1.064 15.28 9.457 16.245 13.682.054.239.101.471.137.701Z"
        fill="#189BD7"
      />
      <path
        d="M37.26 40.39c-1.94 1.209-15.611 8.836-19.877 7.652-.575-.16-1.17-.35-1.78-.572.19.263.387.513.589.755 2 2.38 5.162 4.778 10.845 4.778 14.87 0 26.966-11.805 26.966-26.317 0-2.201-.27-4.355-.813-6.443a17.617 17.617 0 0 1-.47 3.344c-.057.235-.107.441-.167.648-.437 1.484-1.155 2.999-2.128 4.492-3.793 5.83-10.601 10.07-13.162 11.664h-.003Z"
        fill="#E4097E"
      />
      <path
        d="M0 26.748c0 11.366 7.472 21.144 17.993 24.759a13.652 13.652 0 0 1-2.766-2.499 17.266 17.266 0 0 1-1.753-2.542c-3.177-5.589-6.288-31.612.295-38.874.324-.36.726-.571 1.06-.786-2.114.02-5.323.132-7.824 2.242C1.932 13.33 0 20.184 0 26.748Z"
        fill="#FDEB01"
      />
    </g>
    <defs>
      <clipPath id="gb_ball_svg__a">
        <path fill="#fff" d="M0 0h54v53H0z" />
      </clipPath>
    </defs>
  </svg>)
}

export default SvgGbBall;
