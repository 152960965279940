import React from 'react';
import styled, { css } from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';

function HelloImg({ showHelloImg, observerEntry, delay = 0, stiffness }) {
  const { helloOffImg, helloOnImg } = useStaticQuery(graphql`
    query {
      helloOffImg: file(extension: { eq: "png" }, name: { eq: "hello_off" }) {
        childImageSharp {
          gatsbyImageData(width: 233, placeholder: BLURRED, quality: 100)
        }
      }
      helloOnImg: file(extension: { eq: "png" }, name: { eq: "hello_on" }) {
        childImageSharp {
          gatsbyImageData(width: 233, placeholder: BLURRED, quality: 100)
        }
      }
    }
  `);

  return (
    <Container
      observerEntry={observerEntry}
      custom={{ showHelloImg, observerEntry }}
      transition={{
        delay,
        type: 'spring',
        stiffness: stiffness || showHelloImg ? 300 : 100
      }}>
      <HelloOffImg image={getImage(helloOffImg)} alt="Neon 'Hello' sign off" />
      <HelloOnImg image={getImage(helloOnImg)} alt="Neon 'Hello' sign om" />
    </Container>
  );
}

const Container = styled(motion.div).attrs({
  initial: { opacity: 0, rotate: 45, y: 100 },
  animate: ({ showHelloImg, observerEntry }) =>
    showHelloImg || observerEntry?.isIntersecting
      ? { opacity: 1, rotate: 0, y: 0 }
      : { opacity: 0, rotate: 45, y: 100 }
})`
  position: absolute;
  z-index: 3;

  ${({ observerEntry }) =>
    observerEntry
      ? css`
          right: -7rem;
          top: -8.75rem;
        `
      : css`
          right: -1.25rem;
          top: -2rem;
          width: 117px;
        `}
`;

const HelloOffImg = styled(GatsbyImage)`
  left: 0;
  position: absolute !important;
  transform: rotate(-8.62deg);
`;

const HelloOnImg = styled(GatsbyImage)`
  animation: blink 2.25s ease-in-out infinite alternate-reverse;
  transform: rotate(-8.62deg);

  @keyframes blink {
    0% {
      opacity: 0;
    }

    33% {
      opacity: 1;
    }

    50% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`;

export default HelloImg;
