import { signOut, getAuth, checkActionCode, applyActionCode } from 'firebase/auth';
import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  collectionGroup,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  startAfter,
  updateDoc,
  increment as firestoreIncrement,
  where,
  writeBatch
} from 'firebase/firestore';
import {
  getDatabase,
  increment,
  onChildAdded,
  onValue,
  orderByChild,
  push,
  query as rtQuery,
  ref,
  set,
  startAfter as rtStartAfter,
  update,
  onDisconnect,
  serverTimestamp as rtdbServerTimestamp
} from 'firebase/database';
import { httpsCallable, getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getStorage } from 'firebase/storage';

let firebaseInstance;
class Firebase {
  constructor(app) {
    if (!firebaseInstance) {
      this.auth = getAuth(app);
      this.rtdb = getDatabase(app);
      this.fsdb = getFirestore(app);
      this.storage = getStorage(app);
      this.functions = getFunctions(app, 'europe-west1');

      // if (process.env.NODE_ENV === 'development') {
      //   connectFunctionsEmulator(this.functions, 'localhost', 5001);
      // }
    }
  }

  async createUserInDatabase({ email, name, profession, uid, referringUrl, company }) {
    const createUserInDatabaseCallable = httpsCallable(this.functions, 'createUserInDatabase');
    return createUserInDatabaseCallable({
      email: email.toLowerCase().trim(),
      name,
      profession,
      uid,
      referringUrl,
      company
    });
  }

  async logout() {
    return update(ref(this.rtdb, `/presence/${this.auth.currentUser.uid}`), {
      online: false,
      selectedEventId: '',
      lastChanged: rtdbServerTimestamp()
    }).then(() => signOut(this.auth));
  }

  async fetchAllFirebaseAuthUserData() {
    const fetchAllUsersCallable = httpsCallable(this.functions, 'fetchAllFirebaseAuthUserData');
    return fetchAllUsersCallable();
  }

  async getUserFromDatabaseWithUID({ uid }) {
    return getDocs(query(collection(this.fsdb, 'users'), where('uid', '==', uid), limit(1)));
  }

  async checkIfUserAlreadyExists({ email }) {
    const checkIfUserAlreadyExistsCallable = httpsCallable(
      this.functions,
      'checkIfUserAlreadyExists'
    );
    return checkIfUserAlreadyExistsCallable({ email: email.toLowerCase().trim() });
  }

  async sendEmoji({ eid, emojiType }) {
    return set(push(ref(this.rtdb, `/emojis/${eid}`)), {
      emojiType,
      timestamp: rtdbServerTimestamp()
    });
  }

  async saveNewPoll({ eid, poll }) {
    const pid = push(ref(this.rtdb, `polls/${eid}`)).key;

    if (poll.type === 'general poll') {
      const answers = {};
      poll.answers.forEach((answer) => {
        answers[`${answer.id}`] = 0;
      });

      return update(ref(this.rtdb), {
        [`/polls/${eid}/${pid}`]: poll,
        [`/pollAnalytics/${eid}/${pid}`]: {
          newPollType: poll.type,
          question: poll.question.text,
          correctAnswers: poll.answers.some((answer) => answer.isCorrect)
            ? poll.answers.filter((answer) => answer.isCorrect).map((answer) => answer.id)
            : [],
          answers,
          totalParticipants: 0
        }
      });
    }

    if (poll.type === 'word cloud') {
      return update(ref(this.rtdb), {
        [`/polls/${eid}/${pid}`]: poll,
        [`/pollAnalytics/${eid}/${pid}`]: {
          newPollType: poll.type,
          question: poll.question.text,
          totalParticipants: 0
        }
      });
    }

    if (poll.type === 'feedback poll') {
      const answers = {};
      poll.ratingOption.ratingOptions.forEach((answer) => {
        answers[`${answer.rid}`] = 0;
      });
      return update(ref(this.rtdb), {
        [`/polls/${eid}/${pid}`]: poll,
        [`/pollAnalytics/${eid}/${pid}`]: {
          newPollType: poll.type,
          question: poll.question.text,
          answers,
          totalParticipants: 0
        }
      });
    }
  }

  async editPoll({ eid, poll, pid }) {
    if (poll.type === 'general poll') {
      const answers = {};

      poll.answers.forEach((answer) => {
        answers[`${answer.id}`] = 0;
      });

      return update(ref(this.rtdb), {
        [`/polls/${eid}/${pid}`]: poll,
        [`/pollAnalytics/${eid}/${pid}`]: {
          newPollType: poll.type,
          question: poll.question.text,
          correctAnswers: poll.answers.some((answer) => answer.isCorrect)
            ? poll.answers.filter((answer) => answer.isCorrect).map((answer) => answer.id)
            : [],
          answers,
          totalParticipants: 0
        }
      });
    }

    if (poll.type === 'word cloud') {
      return update(ref(this.rtdb), {
        [`/polls/${eid}/${pid}`]: poll,
        [`/pollAnalytics/${eid}/${pid}`]: {
          newPollType: poll.type,
          question: poll.question.text,
          totalParticipants: 0
        }
      });
    }

    if (poll.type === 'feedback poll') {
      const answers = {};
      poll.ratingOption.ratingOptions.forEach((answer) => {
        answers[`${answer.rid}`] = 0;
      });
      return update(ref(this.rtdb), {
        [`/polls/${eid}/${pid}`]: poll,
        [`/pollAnalytics/${eid}/${pid}`]: {
          newPollType: poll.type,
          question: poll.question.text,
          totalParticipants: 0,
          answers
        }
      });
    }
  }

  async deleteWordCloudEntry({ eid, pid, entries }) {
    set(ref(this.rtdb, `/pollAnalytics/${eid}/${pid}/entries`), entries);
  }

  async deletePoll({ eid, poll }) {
    const { pid, type } = poll;

    const promises = [];

    if (type === 'word cloud') {
      promises.push(
        updateDoc(doc(this.fsdb, 'events', eid), {
          currentlyOpenWordCloudPollId: null
        })
      );
    }

    promises.push(
      update(ref(this.rtdb), {
        [`/polls/${eid}/${pid}`]: null,
        [`/pollAnalytics/${eid}/${pid}`]: null
      })
    );

    return Promise.all(promises);
  }

  async submitPollAnswer({ eid, poll, uid, selectedAnswerIds }) {
    const { pid } = poll;

    const answers = {};

    selectedAnswerIds.forEach((id) => {
      answers[`${id}`] = increment(1);
    });

    const totalParticipants = increment(1);

    /* TODO: Finish the fsdb query */
    /* Make each answer an object, with answer.id and answer.correct on it */
    return Promise.all([
      update(ref(this.rtdb, `/pollAnalytics/${eid}/${pid}/answers`), answers),
      update(ref(this.rtdb, `/pollAnalytics/${eid}/${pid}`), {
        totalParticipants
      }),
      setDoc(doc(this.fsdb, 'users', uid, 'pollAnalytics', `${eid}_${pid}`), {
        uid,
        answers: selectedAnswerIds
      })
    ]);
  }

  async submitFeedbackAnswer({ eid, poll, uid, rating, personalMessage, contactAgreement }) {
    const { pid } = poll;

    const pollRef = ref(this.rtdb, `/pollAnalytics/${eid}/${pid}`);
    const answersRef = ref(this.rtdb, `/pollAnalytics/${eid}/${pid}/answers`);
    return Promise.all([
      update(pollRef, {
        totalParticipants: increment(1)
      }),
      update(answersRef, {
        [rating.id]: increment(1)
      }),
      setDoc(doc(this.fsdb, 'users', uid, 'pollAnalytics', `${eid}_${pid}`), {
        uid,
        rating: {
          position: rating.position + 1,
          id: rating.id
        },
        message: personalMessage,
        contactAgreement
      })
    ]);
  }

  async answerWordCloudPoll({ eid, poll, uid, entries }) {
    const { pid } = poll;
    const totalParticipants = increment(1);

    const _entries = {};

    entries.forEach((entry) => {
      _entries[entry] = increment(1);
    });

    return Promise.all([
      update(ref(this.rtdb, `/pollAnalytics/${eid}/${pid}`), {
        totalParticipants
      }),
      update(ref(this.rtdb, `/pollAnalytics/${eid}/${pid}/entries`), _entries),
      setDoc(doc(this.fsdb, 'users', uid, 'pollAnalytics', `${eid}_${pid}`), {
        uid,
        entries
      })
    ]);
  }

  async checkIfUserHasAlreadyAnsweredThisPoll({ uid, eid, pid }) {
    return getDoc(doc(this.fsdb, 'users', uid, 'pollAnalytics', `${eid}_${pid}`));
  }

  async generateCSVReport({ selectedEvent }) {
    const generateCSVReportCallable = httpsCallable(this.functions, 'generateCSVReport');
    return generateCSVReportCallable({ selectedEvent });
  }

  async generateLoggedInOnTheDayReport({ users, eventName }) {
    const generateLoggedInOnTheDayReportCallable = httpsCallable(
      this.functions,
      'generateLoggedInOnTheDayReport'
    );
    return generateLoggedInOnTheDayReportCallable({ users, eventName });
  }

  async generateEventCommentsReport({ eid, eventName }) {
    const generateEventCommentsReportCallable = httpsCallable(
      this.functions,
      'generateEventCommentsReport'
    );
    return generateEventCommentsReportCallable({ eid, eventName });
  }

  async sendEventReminderEmail({ event, templateAlias }) {
    const sendEventReminderEmailCallable = httpsCallable(this.functions, 'sendEventReminderEmail');
    return sendEventReminderEmailCallable({ event, templateAlias });
  }

  async sendEventCancellationEmail({ event }) {
    const sendEventCancellationEmailCallable = httpsCallable(
      this.functions,
      'sendEventCancellationEmail'
    );
    return sendEventCancellationEmailCallable({ event });
  }

  async sendSignInWithMagicLinkEmail({ firstName, email, actionCodeSettings }) {
    const sendSignInWithEmailLinkEmailCallable = httpsCallable(
      this.functions,
      'sendSignInWithMagicLinkEmail'
    );
    return sendSignInWithEmailLinkEmailCallable({
      firstName,
      email: email.toLowerCase().trim(),
      actionCodeSettings
    });
  }

  async updateNameInDatabase({ uid, name }) {
    return updateDoc(doc(this.fsdb, 'users', uid), {
      name
    });
  }

  async updateEmailInDatabase({ uid, email }) {
    const usersRef = doc(this.fsdb, 'users', uid);
    const userEmailsRef = doc(this.fsdb, 'userEmails', uid);
    const batch = writeBatch(this.fsdb);

    batch.update(usersRef, {
      email: email.toLowerCase().trim()
    });

    batch.update(userEmailsRef, {
      email: email.toLowerCase().trim()
    });

    return batch.commit();
  }

  async updateProfessionInDatabase({ uid, profession }) {
    return updateDoc(doc(this.fsdb, 'users', uid), {
      profession
    });
  }

  async updateCompanyInDatabase({ uid, company }) {
    return updateDoc(doc(this.fsdb, 'users', uid), {
      company
    });
  }

  async send48HourReminderEmail({ eventTitle, eid, slug }) {
    const send48HourReminderEmailCallable = httpsCallable(
      this.functions,
      'send48HourReminderEmail'
    );
    return send48HourReminderEmailCallable({ eventTitle, eid, slug });
  }

  async sendTodayReminderEmail({ eventTitle, eid, slug }) {
    const sendTodayReminderEmailCallable = httpsCallable(this.functions, 'sendTodayReminderEmail');
    return sendTodayReminderEmailCallable({ eventTitle, eid, slug });
  }

  async updateSocialsInDatabase({ uid, socials }) {
    return updateDoc(doc(this.fsdb, 'users', uid), {
      socials: {
        facebook: socials.facebook || '',
        linkedIn: socials.linkedIn || '',
        twitter: socials.twitter || '',
        instagram: socials.instagram || ''
      }
    });
  }

  async applyActionCode(actionCode) {
    return applyActionCode(this.auth, actionCode);
  }

  async checkActionCode(actionCode) {
    return checkActionCode(this.auth, actionCode);
  }

  async uploadAvatarToDatabase(avatarFile) {
    const uploadAvatarToDatabaseCallable = httpsCallable(this.functions, 'uploadAvatarToDatabase');
    return uploadAvatarToDatabaseCallable({ avatarFile });
  }

  async sendAttendanceCerts({ participants, eid, title, colors, speakers, date, streamDuration }) {
    const sendAttendanceCertsCallable = httpsCallable(this.functions, 'sendAttendanceCerts');
    return sendAttendanceCertsCallable({
      participants,
      eid,
      title,
      colors,
      speakers,
      date,
      streamDuration
    });
  }

  async sendReminderEmail() {
    const sendReminderEmailCallable = httpsCallable(this.functions, 'sendReminderEmail');
    return sendReminderEmailCallable();
  }

  async fetchUser({ uid }) {
    return getDoc(doc(this.fsdb, 'users', uid));
  }

  async addDummyUsers({ amountOfDummyUsers, eventIds }) {
    const addDummyUsersCallable = httpsCallable(this.functions, 'addDummyUsers');
    return addDummyUsersCallable({ amountOfDummyUsers, eventIds });
  }

  async deleteAllDummyUsers() {
    const deleteAllDummyUsersCallable = httpsCallable(this.functions, 'deleteAllDummyUsers');
    return deleteAllDummyUsersCallable();
  }

  async fetchPaginatedEventParticipants({
    eid,
    lastFetchedCurrentlyParticipatingUserDoc,
    lastFetchedNotCurrentlyParticipatingUserDoc,
    initialFetch
  }) {
    // The below logic bascially acts like a logical OR query. See 'Query limitations' here:
    // https://firebase.google.com/docs/firestore/query-data/queries#query_limitations
    // If we didn't do this then users would not be listed as non-available participants on event
    // pages if watching a concurrent livestream on another event page.
    let currentlyParticipatingUsersQuery;
    let notCurrentlyParticipatingUsersQuery;

    if (lastFetchedCurrentlyParticipatingUserDoc) {
      currentlyParticipatingUsersQuery = query(
        collection(this.fsdb, 'users'),
        where('eventsUserCanAccess', 'array-contains', eid),
        where('presence.selectedEventId', '==', eid),
        orderBy('name'),
        startAfter(lastFetchedCurrentlyParticipatingUserDoc),
        limit(20)
      );
    } else {
      currentlyParticipatingUsersQuery = query(
        collection(this.fsdb, 'users'),
        where('eventsUserCanAccess', 'array-contains', eid),
        where('presence.selectedEventId', '==', eid),
        orderBy('name'),
        limit(20)
      );
    }

    if (!initialFetch) {
      if (lastFetchedNotCurrentlyParticipatingUserDoc) {
        notCurrentlyParticipatingUsersQuery = query(
          collection(this.fsdb, 'users'),
          where('eventsUserCanAccess', 'array-contains', eid),
          where('presence.selectedEventId', '!=', eid),
          orderBy('presence.selectedEventId'),
          orderBy('name'),
          startAfter(lastFetchedNotCurrentlyParticipatingUserDoc),
          limit(20)
        );
      } else {
        notCurrentlyParticipatingUsersQuery = query(
          collection(this.fsdb, 'users'),
          where('eventsUserCanAccess', 'array-contains', eid),
          where('presence.selectedEventId', '!=', eid),
          orderBy('presence.selectedEventId'),
          orderBy('name'),
          limit(20)
        );
      }
    }

    const promises = [];

    if (currentlyParticipatingUsersQuery) {
      promises.push(getDocs(currentlyParticipatingUsersQuery));
    }

    if (notCurrentlyParticipatingUsersQuery) {
      promises.push(getDocs(notCurrentlyParticipatingUsersQuery));
    }

    const [currentlyParticipatingUsersSnapshot, notCurrentlyParticipatingUsersSnapshot] =
      await Promise.all(promises);

    return {
      currentlyParticipatingUsersSnapshot,
      notCurrentlyParticipatingUsersSnapshot
    };
  }

  async fetchAllEventParticipants({ eid }) {
    return getDocs(
      query(
        collection(this.fsdb, 'users'),
        where('eventsUserCanAccess', 'array-contains', eid),
        orderBy('name')
      )
    );
  }

  async submitEventComment({
    eid,
    avatarUrl,
    name,
    text,
    profession,
    company,
    socials,
    uid,
    tags
  }) {
    return addDoc(collection(this.fsdb, 'events', eid, 'comments'), {
      avatarUrl,
      name,
      text,
      timestamp: serverTimestamp(),
      profession,
      company,
      socials,
      uid,
      pinned: {
        status: false,
        timestamp: 0
      },
      likes: {
        total: 0,
        likedBy: []
      },
      tags
    });
  }

  async deleteEventComment({ eid, cid }) {
    return deleteDoc(doc(this.fsdb, 'events', eid, 'comments', cid));
  }

  async editEventComment({ eid, cid, updatedText, updatedTags }) {
    return updateDoc(doc(this.fsdb, 'events', eid, 'comments', cid), {
      text: updatedText,
      tags: updatedTags
    });
  }

  async likeEventComment({ eid, cid, uid }) {
    return Promise.all([
      updateDoc(doc(this.fsdb, 'events', eid, 'comments', cid), {
        'likes.total': firestoreIncrement(1),
        'likes.likedBy': arrayUnion(uid)
      })
    ]);
  }

  async pinEventComment({ eid, cid }) {
    return updateDoc(doc(this.fsdb, 'events', eid, 'comments', cid), {
      pinned: {
        status: true,
        timestamp: serverTimestamp()
      }
    });
  }

  async unpinEventComment({ eid, cid }) {
    return updateDoc(doc(this.fsdb, 'events', eid, 'comments', cid), {
      pinned: {
        status: false,
        timestamp: 0
      }
    });
  }

  async upvoteQuestion({ quid, qid, uid }) {
    return Promise.all([
      updateDoc(doc(this.fsdb, 'users', quid, 'questions', qid), {
        'upvotes.total': firestoreIncrement(1),
        'upvotes.upvoters': arrayUnion(uid)
      })
    ]);
  }

  async markQuestionSeen({ uid, qid }) {
    return updateDoc(doc(this.fsdb, 'users', uid, 'questions', qid), {
      seen: {
        status: true,
        timestamp: serverTimestamp()
      }
    });
  }

  async starQuestion({ uid, qid }) {
    return updateDoc(doc(this.fsdb, 'users', uid, 'questions', qid), {
      starred: {
        status: true,
        timestamp: serverTimestamp()
      }
    });
  }

  async unstarQuestion({ uid, qid }) {
    return updateDoc(doc(this.fsdb, 'users', uid, 'questions', qid), {
      starred: {
        status: false,
        timestamp: 0
      }
    });
  }

  async grantRegistrantAccessToTheseEvents({ uid, eids }) {
    return updateDoc(doc(this.fsdb, 'users', uid), {
      eventsUserWantsToAccess: arrayRemove(...eids),
      eventsUserCanAccess: arrayUnion(...eids),
      eventsUserHasBeenDeniedAccessTo: arrayRemove(...eids),
      pendingSiteRegistrant: false
    });
  }

  async denyRegistrantAccessToTheseEvents({ uid, eids }) {
    return updateDoc(doc(this.fsdb, 'users', uid), {
      eventsUserHasBeenDeniedAccessTo: arrayUnion(...eids),
      eventsUserCanAccess: arrayRemove(...eids),
      eventsUserWantsToAccess: arrayRemove(...eids)
    });
  }

  async denyRegistrantAccessToSite({ uid }) {
    return updateDoc(doc(this.fsdb, 'users', uid), {
      pendingSiteRegistrant: false
    });
  }

  async sendSiteAccessGrantedEmail({ name, email }) {
    const sendSiteAccessGrantedEmailCallable = httpsCallable(
      this.functions,
      'sendSiteAccessGrantedEmail'
    );
    return sendSiteAccessGrantedEmailCallable({
      name,
      email: email.toLowerCase().trim()
    });
  }

  async sendEventAccessGrantedEmail({ name, email, event }) {
    const sendEventAccessGrantedEmailCallable = httpsCallable(
      this.functions,
      'sendEventAccessGrantedEmail'
    );
    return sendEventAccessGrantedEmailCallable({
      name,
      email: email.toLowerCase().trim(),
      event
    });
  }

  async registerForEventIfUserAlreadyHasAnAccount({ eid, uid }) {
    const registerForEventIfUserAlreadyHasAnAccountCallable = httpsCallable(
      this.functions,
      'registerForEventIfUserAlreadyHasAnAccount'
    );
    return registerForEventIfUserAlreadyHasAnAccountCallable({
      eid,
      uid
    });
  }

  async sendEventWelcomeEmail({ name, email, event }) {
    const sendEventWelcomeEmailCallable = httpsCallable(this.functions, 'sendEventWelcomeEmail');
    return sendEventWelcomeEmailCallable({
      name,
      email: email.toLowerCase().trim(),
      event
    });
  }

  async searchMeili({ tag }) {
    const searchMeiliCallable = httpsCallable(this.functions, 'searchMeili');
    return searchMeiliCallable({
      tag
    });
  }

  async submitNewQuestion({ uid, eid, text, name }) {
    return setDoc(
      doc(this.fsdb, 'users', uid, 'questions', `${uid}_${Date.now()}`),
      name
        ? {
            eid,
            text,
            timestamp: serverTimestamp(),
            uid,
            name,
            starred: {
              status: false,
              timestamp: 0
            },
            seen: {
              status: false,
              timestamp: 0
            },
            upvotes: {
              total: 0,
              upvoters: []
            }
          }
        : {
            eid,
            text,
            timestamp: serverTimestamp(),
            uid,
            starred: {
              status: false,
              timestamp: 0
            },
            seen: {
              status: false,
              timestamp: 0
            },
            upvotes: {
              total: 0,
              upvoters: []
            }
          }
    );
  }

  async answerThisQuestionLive({ eid, text }) {
    return updateDoc(doc(this.fsdb, 'events', eid), {
      questionCurrentlyBeingAnsweredLive: text
    });
  }

  async stopShowingAnswerLiveOverlay({ eid }) {
    return updateDoc(doc(this.fsdb, 'events', eid), {
      questionCurrentlyBeingAnsweredLive: null
    });
  }

  async submitAnswer({ text, qid, uid }) {
    return updateDoc(doc(this.fsdb, 'users', uid, 'questions', qid), {
      answer: {
        text,
        timestamp: serverTimestamp()
      }
    });
  }

  async convertPDFtoPNG({ fileName }) {
    const convertPDFtoPNGCallable = httpsCallable(this.functions, 'convertPDFtoPNG');
    return convertPDFtoPNGCallable({
      fileName
    });
  }

  async grantUserAdminPermissions({ uid }) {
    const grantUserAdminPermissionsCallable = httpsCallable(
      this.functions,
      'grantUserAdminPermissions'
    );
    return grantUserAdminPermissionsCallable({ uid });
  }

  async grantUserModeratorPermissions({ uid, eid }) {
    const grantUserModeratorPermissionsCallable = httpsCallable(
      this.functions,
      'grantUserModeratorPermissions'
    );
    return grantUserModeratorPermissionsCallable({ uid, eid });
  }

  async openPoll({ eid, selectedPoll, currentlyOpenPoll }) {
    if (currentlyOpenPoll?.pid) {
      const pollRef = ref(this.rtdb, `polls/${eid}`);

      if (selectedPoll.type === 'general poll' && selectedPoll.timer?.enabled) {
        return update(pollRef, {
          [`${currentlyOpenPoll?.pid}/isOpen`]: false,
          [`${selectedPoll.pid}/isOpen`]: true,
          [`${selectedPoll.pid}/timer`]: {
            ...selectedPoll.timer,
            startedAt: new Date().getTime()
          }
        });
      }

      const promises = [];

      if (currentlyOpenPoll.type === 'word cloud') {
        if (selectedPoll.type === 'word cloud') {
          promises.push(
            updateDoc(doc(this.fsdb, 'events', eid), {
              currentlyOpenWordCloudPollId: selectedPoll.pid
            })
          );
        } else if (selectedPoll.type !== 'word cloud') {
          promises.push(
            updateDoc(doc(this.fsdb, 'events', eid), {
              currentlyOpenWordCloudPollId: null
            })
          );
        }
      }

      promises.push(
        update(pollRef, {
          [`${currentlyOpenPoll?.pid}/isOpen`]: false,
          [`${selectedPoll.pid}/isOpen`]: true
        })
      );

      return Promise.all(promises);
    }

    if (selectedPoll.type === 'general poll' && selectedPoll.timer?.enabled) {
      return update(ref(this.rtdb, `polls/${eid}/${selectedPoll.pid}`), {
        isOpen: true,
        timer: {
          ...selectedPoll.timer,
          startedAt: new Date().getTime()
        }
      });
    }

    const promises = [];
    if (selectedPoll.type === 'word cloud') {
      promises.push(
        updateDoc(doc(this.fsdb, 'events', eid), {
          currentlyOpenWordCloudPollId: selectedPoll.pid
        })
      );
    }

    promises.push(
      update(ref(this.rtdb, `polls/${eid}/${selectedPoll.pid}`), {
        isOpen: true
      })
    );

    return Promise.all(promises);
  }

  async closePoll({ eid, poll }) {
    const promises = [];

    if (poll.type === 'word cloud') {
      promises.push(
        updateDoc(doc(this.fsdb, 'events', eid), {
          currentlyOpenWordCloudPollId: null
        })
      );
    }

    promises.push(
      update(ref(this.rtdb, `polls/${eid}/${poll.pid}`), { isOpen: false, isQueued: false })
    );

    return Promise.all(promises);
  }

  async sharePollAnalytics({ eid, poll }) {
    return update(ref(this.rtdb, `polls/${eid}/${poll.pid}`), {
      shareResults: true
    });
  }

  async stopSharingPollAnalytics({ eid, poll }) {
    return update(ref(this.rtdb, `polls/${eid}/${poll.pid}`), {
      shareResults: false
    });
  }

  async forceActiveTab({ eid, tabName }) {
    return updateDoc(doc(this.fsdb, 'events', eid), {
      forcedActiveTab: tabName
    });
  }

  async updateVideoSessionData({ eid, uid, timeRange }) {
    return setDoc(
      doc(this.fsdb, 'users', uid, 'videoSessionData', eid),
      {
        eid,
        timeRanges: arrayUnion(...timeRange)
      },
      { merge: true }
    );
  }

  async updateUserPresence({ uid, eid }) {
    const userPresenceRTDBRef = ref(this.rtdb, `/presence/${uid}`);

    if (!eid) {
      return update(userPresenceRTDBRef, {
        selectedEventId: '',
        lastChanged: rtdbServerTimestamp()
      });
    }

    return onDisconnect(userPresenceRTDBRef)
      .set({ selectedEventId: '', lastChanged: rtdbServerTimestamp() })
      .then(() =>
        update(userPresenceRTDBRef, {
          selectedEventId: eid,
          lastChanged: rtdbServerTimestamp()
        })
      );
  }

  subscribeToRTDBServer({ snapshot }) {
    return onValue(ref(this.rtdb, '.info/connected'), snapshot);
  }

  subscribeToServerTimeOffset({ snapshot }) {
    const serverTimeOffsetRef = ref(this.rtdb, '.info/serverTimeOffset');
    return onValue(serverTimeOffsetRef, snapshot);
  }

  subscribeToEventQuestions({ eid, snapshot }) {
    return onSnapshot(
      query(collectionGroup(this.fsdb, 'questions'), where('eid', '==', eid)),
      snapshot
    );
  }

  subscribeToEventEmojis({ eid, snapshot }) {
    return onValue(ref(this.rtdb, `/emojis/${eid}`), snapshot);
  }

  subscribeToEventData({ eid, snapshot }) {
    return onSnapshot(
      query(collection(this.fsdb, 'users'), where('eventsUserCanAccess', 'array-contains', eid)),
      snapshot
    );
  }

  subscribeToUserUpdates({ uid, snapshot }) {
    return onSnapshot(doc(this.fsdb, 'users', uid), snapshot);
  }

  subscribeToEventUpdates({ eid, snapshot }) {
    return onSnapshot(doc(this.fsdb, 'events', eid), snapshot);
  }

  subscribeToEventComments({ eid, snapshot }) {
    return onSnapshot(
      query(
        collection(this.fsdb, 'events', eid, 'comments'),
        orderBy('pinned.status', 'desc'),
        orderBy('pinned.timestamp', 'asc'),
        orderBy('timestamp', 'desc'),
        limit(100)
      ),
      snapshot
    );
  }

  subscribeToEmojis({ eid, snapshot }) {
    const emojisRef = ref(this.rtdb, `/emojis/${eid}`);
    return onChildAdded(
      rtQuery(emojisRef, orderByChild('timestamp'), rtStartAfter(Date.now())),
      snapshot
    );
  }

  subscribeToSubmittedQuestions({ snapshot, eid, isModerator }) {
    let q;
    if (isModerator) {
      q = query(
        collectionGroup(this.fsdb, 'questions'),
        where('eid', '==', eid),
        orderBy('starred.status', 'desc'),
        orderBy('starred.timestamp', 'asc'),
        orderBy('upvotes.total', 'desc'),
        orderBy('timestamp', 'desc')
      );
    } else {
      q = query(
        collectionGroup(this.fsdb, 'questions'),
        where('eid', '==', eid),
        orderBy('upvotes.total', 'desc'),
        orderBy('timestamp', 'desc')
      );
    }
    return onSnapshot(q, snapshot);
  }

  subscribeToPolls({ eid, snapshot }) {
    const pollsRef = ref(this.rtdb, `polls/${eid}`);
    return onValue(pollsRef, snapshot);
  }

  subscribeToPollAnalytics({ eid, pid, snapshot }) {
    const pollAnalyticsRef = ref(this.rtdb, `/pollAnalytics/${eid}/${pid}`);
    return onValue(pollAnalyticsRef, snapshot);
  }
}

function getFirebaseInstance(app) {
  if (!firebaseInstance && app) {
    firebaseInstance = new Firebase(app);
    return firebaseInstance;
  }

  if (firebaseInstance) {
    return firebaseInstance;
  }

  return null;
}

export default getFirebaseInstance;
