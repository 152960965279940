import * as React from 'react';

function SvgVeryHappyEmoji(props) {
  return (<svg viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M16.706 11.059a1.235 1.235 0 1 0 0-2.47 1.235 1.235 0 0 0 0 2.47ZM9.294 11.059a1.235 1.235 0 1 0 0-2.47 1.235 1.235 0 0 0 0 2.47Z"
      fill="#2D9CDB"
    />
    <path
      d="M13 25c6.627 0 12-5.373 12-12S19.627 1 13 1 1 6.373 1 13s5.373 12 12 12Z"
      stroke="#2D9CDB"
      strokeWidth={2}
      strokeMiterlimit={10}
    />
    <path
      d="M18.295 16.106c0 2.33-2.436 4.235-5.47 4.235-3.036 0-5.471-1.906-5.471-4.235h10.94Z"
      stroke="#2D9CDB"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>)
}

export default SvgVeryHappyEmoji;
