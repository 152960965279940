import React, { useContext } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { hexToRGB } from 'utils';
import { FirebaseContext, LocalContext } from 'context';
import { Button } from 'components';
import { fadeInAndOutVariants } from 'styles';

function ConfirmDeleteModal({
  deletePoll,
  setDeletePoll,
  selectedPoll,
  setSelectedPoll,
  currentlyOpenPoll,
  setCurrentlyOpenPoll
}) {
  const { selectedEvent } = useContext(LocalContext);
  const { firebase } = useContext(FirebaseContext);
  const handleDeletePoll = (e) => {
    e.stopPropagation();
    if (firebase) {
      if (selectedPoll?.pid === deletePoll?.poll.pid) {
        setSelectedPoll(null);
      }
      if (currentlyOpenPoll?.pid === deletePoll?.poll.pid) {
        firebase.forceActiveTab({ eid: selectedEvent.eid, tabName: null });
        setCurrentlyOpenPoll(null);
      }
      firebase.deletePoll({ eid: selectedEvent.eid, poll: deletePoll?.poll }).then(() => {
        setDeletePoll({ open: false, poll: {} });
      });
    }
  };
  return (
    <Underlay
      data-underlay
      colors={selectedEvent.colors}
      onClick={(e) => {
        if (e.target.dataset.underlay === 'true') {
          setDeletePoll({ open: false, poll: {} });
        }
      }}>
      <Modal colors={selectedEvent.colors}>
        <Question colors={selectedEvent.colors}>
          <p>Delete this poll?</p>
        </Question>
        <h4>The poll will be permanently deleted and you wont be able to recover it!</h4>
        <Button
          type="button"
          onClick={handleDeletePoll}
          whileHover={{
            backgroundColor: selectedEvent.colors.tertiary
          }}
          whileTap={{
            scale: 0.95
          }}
          style={{
            alignSelf: 'flex-end',
            backgroundColor: selectedEvent.colors.secondary,
            borderRadius: '0.125rem',
            height: '2.5rem',
            marginTop: '0.45rem',
            width: '12rem'
          }}>
          DELETE
        </Button>
        <motion.button
          whileHover={{
            color: selectedEvent.colors.secondary
          }}
          whileTap={{
            scale: 0.95
          }}
          type="button"
          onClick={() => setDeletePoll({ open: false, poll: {} })}>
          Cancel
        </motion.button>
      </Modal>
    </Underlay>
  );
}

const Underlay = styled(motion.div).attrs(fadeInAndOutVariants())`
  backdrop-filter: blur(2px);
  background: rgba(0, 0, 0, 0.6);
  height: 100%;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 1.625rem 1.625rem;
  position: absolute;
  top: 0;
  width: 100%;

  ::-webkit-scrollbar {
    width: 0.5rem;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0.31rem grey;
    border-radius: 0.625rem;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ colors }) => colors.secondary};
    border-radius: 0.625rem;
  }
`;

const Modal = styled.div`
  align-items: center;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 3rem auto 0;
  max-width: 23.75rem;
  position: relative;
  h4 {
    color: #4f4f4f;
    font-size: 1rem;
    font-weight: 400;
    padding: 1.75rem 1.5rem 1.25rem 1.5rem;
    text-align: center;
  }
  button {
    cursor: pointer;
    margin: 0 auto 1rem auto;
    &:nth-of-type(2) {
      background: transparent;
      color: #c4c4c4;
      font-size: 0.875rem;
      font-weight: 300;
      text-decoration: underline;
    }
  }
`;

const Question = styled.div`
  background-color: ${({ colors }) => hexToRGB({ color: colors.secondary, alpha: 0.2 })};
  color: ${({ colors }) => colors.secondary};
  padding: 0.75rem 1.5rem;
  text-align: center;
  width: 100%;
  p:nth-of-type(1) {
    font-weight: 700;
  }

  p:nth-of-type(2) {
    font-weight: 300;
  }
`;

export default ConfirmDeleteModal;
