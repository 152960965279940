import React, { useContext, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { Participant } from 'components';
import { FirebaseContext } from 'context';
import { motion } from 'framer-motion';

function Participants({ colors, eid, handleParticipantAvatarOrNameClick }) {
  const { firebase } = useContext(FirebaseContext);
  const [lastFetchedCurrentlyParticipatingUserDoc, setLastFetchedCurrentlyParticipatingUserDoc] =
    useState();
  const [
    lastFetchedNotCurrentlyParticipatingUserDoc,
    setLastFetchedNotCurrentlyParticipatingUserDoc
  ] = useState();
  // 'eventParticipants' includes anyone who has registered for the event, whether they are
  // currently on the event livestream page or not. If they are currently on the page they'll have a
  // green <AvailabilityIndicator /> by their avatar. If they've registered for this event but are
  // offline or are currently on another event livestream page then they'll still be listed as a
  // participant for this event, just without the <AvailabilityIndicator /> by their avatar.
  // If not for the logical OR logic in fetchPaginatedEventParticipants() then users would not be
  // listed as non-available participants on event pages if watching a concurrent livestream on
  // another event page.
  const [eventParticipants, setEventParticpants] = useState([]);

  const particpantsContainerRef = useRef();

  const populateEventParticipantsList = async ({
    currentlyParticipatingUsersSnapshot,
    notCurrentlyParticipatingUsersSnapshot
  }) => {
    const nextParticipants = [];

    if (!currentlyParticipatingUsersSnapshot?.empty) {
      currentlyParticipatingUsersSnapshot.docs?.forEach((doc) => nextParticipants.push(doc));
    }

    if (
      currentlyParticipatingUsersSnapshot?.empty &&
      !notCurrentlyParticipatingUsersSnapshot?.empty
    ) {
      notCurrentlyParticipatingUsersSnapshot.docs?.forEach((doc) => nextParticipants.push(doc));
    }

    if (nextParticipants.length) {
      setEventParticpants((currrentValue) => [
        ...currrentValue,
        ...nextParticipants.map((doc) => {
          const { name, profession, company, socials, avatarUrl, eventRole, uid, presence } =
            doc.data();
          return {
            name,
            profession,
            company,
            socials,
            avatarUrl,
            eventRole,
            uid,
            presence
          };
        })
      ]);
      if (!currentlyParticipatingUsersSnapshot?.empty) {
        setLastFetchedCurrentlyParticipatingUserDoc(
          currentlyParticipatingUsersSnapshot.docs[
            currentlyParticipatingUsersSnapshot.docs.length - 1
          ]
        );
      }
      if (
        currentlyParticipatingUsersSnapshot?.empty &&
        !notCurrentlyParticipatingUsersSnapshot?.empty
      ) {
        setLastFetchedNotCurrentlyParticipatingUserDoc(
          notCurrentlyParticipatingUsersSnapshot.docs[
            notCurrentlyParticipatingUsersSnapshot.docs.length - 1
          ]
        );
      }
    }
  };

  useEffect(() => {
    firebase
      .fetchPaginatedEventParticipants({ eid, initialFetch: true })
      .then(populateEventParticipantsList);
    return () => {
      setEventParticpants([]);
      setLastFetchedCurrentlyParticipatingUserDoc(null);
      setLastFetchedNotCurrentlyParticipatingUserDoc(null);
    };
  }, []);

  const handleLastFetchedParticipantIsInView = () =>
    firebase
      .fetchPaginatedEventParticipants({
        eid,
        lastFetchedCurrentlyParticipatingUserDoc,
        lastFetchedNotCurrentlyParticipatingUserDoc,
        initialFetch: false
      })
      .then(populateEventParticipantsList);

  return (
    <Wrapper ref={particpantsContainerRef} colors={colors}>
      {eventParticipants.length > 0 &&
        eventParticipants.map((participant, i, arr) => (
          <Participant
            key={participant.uid}
            participant={participant}
            particpantsContainerRef={particpantsContainerRef}
            handleLastFetchedParticipantIsInView={handleLastFetchedParticipantIsInView}
            handleParticipantAvatarOrNameClick={handleParticipantAvatarOrNameClick}
            eid={eid}
            colors={colors}
            isLastFetchedParticipant={arr.length === i + 1}
          />
        ))}
    </Wrapper>
  );
}

const Wrapper = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
})`
  background-color: #fff;
  padding: 1.25rem;
  min-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0.5rem;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0.31rem grey;
    border-radius: 0.625rem;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${({ colors }) => colors.secondary};
    border-radius: 0.625rem;
  }
`;

export default Participants;
