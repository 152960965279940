/* eslint-disable import/extensions */
import React, { useContext } from 'react';
import styled from 'styled-components';
import { LocalContext } from 'context';
import { Link } from 'gatsby';
import {
  GetBroadcastingLogo,
  AgencyXLogo,
  LinkedinFooterIcon,
  FbFooterIcon,
  TwitterFooterIcon,
  IgFooterIcon
} from 'assets/svgs';
import EmailSVG from 'assets/svgs/contact_email.svg';
import MarkSVG from 'assets/svgs/contact_marker.svg';
import WebsiteSVG from 'assets/svgs/contact_website.svg';

function Footer({ colors }) {
  const { contactRef } = useContext(LocalContext);
  return (
    <Container colors={colors} ref={contactRef}>
      <Contact>
        <CentralColumn>
          <H3 colors={colors}>Contact</H3>
          <ContactDetails>
            <li>
              <a href="mailto:contact@getbroadcasting.com" target="_blank" rel="noreferrer">
                contact@getbroadcasting.com
              </a>
            </li>
            <li>
              <a
                href="https://www.google.com/maps/place/Get+Broadcasting/@53.3417486,-6.2629893,15z/data=!4m5!3m4!1s0x0:0x8a039f5731e76619!8m2!3d53.3417403!4d-6.2629563"
                target="_blank"
                rel="noreferrer">
                Media House, 24 South William Street, D02 YC42
              </a>
            </li>
            <li>
              <a href="https://www.getbroadcasting.com/" target="_blank" rel="noreferrer">
                getbroadcasting.com
              </a>
            </li>
          </ContactDetails>
          <SocialMedia colors={colors}>
            <a
              href="https://www.linkedin.com/company/get-broadcasting/"
              target="_blank"
              rel="noreferrer">
              <LinkedinFooterIcon width={14} />
            </a>
            <a
              href="https://www.facebook.com/GetBroadcastingStudios"
              target="_blank"
              rel="noreferrer">
              <FbFooterIcon width={7.4} />
            </a>
            <a href="https://twitter.com/GetBroadcasting" target="_blank" rel="noreferrer">
              <TwitterFooterIcon width={15} />
            </a>
            <a href="https://www.instagram.com/get_broadcasting/" target="_blank" rel="noreferrer">
              <IgFooterIcon width={15.24} />
            </a>
          </SocialMedia>
          <StyledGetBroadcastingLogo
            href="https://www.getbroadcasting.com/"
            target="_blank"
            rel="noreferrer">
            <GetBroadcastingLogo />
          </StyledGetBroadcastingLogo>
          <StyledAgencyXLogo href="https://www.agencyx.ie/" target="_blank" rel="noreferrer">
            <AgencyXLogo />
          </StyledAgencyXLogo>
          <Copywright>
            All videos are copyright of Get Broadcasting
            <br />© {new Date().getFullYear()} Copyright Get Broadcasting
          </Copywright>
        </CentralColumn>
      </Contact>
      <SmallPrint>
        <CentralColumn>
          <p>
            Virtuosity and associated Live Stream Events are brought to you by Get Broadcasting in
            collaboration with Agency X
          </p>
          <p>
            <Link to="/terms-and-conditions">Terms & Conditions</Link>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </p>
        </CentralColumn>
      </SmallPrint>
    </Container>
  );
}

const Container = styled.footer`
  border-top: 0.125rem solid ${({ colors }) => colors.primary};
  display: grid;
  grid-column: 1/13;
  grid-column-gap: 1.25rem;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(2, auto);
`;

const Contact = styled.section`
  background-color: #000;
  grid-column: 1/13;
  padding: 2rem 1.25rem 1.5rem;

  @media screen and (min-width: 890px) {
    padding: 3rem 0;
  }
`;

const CentralColumn = styled.div`
  display: grid;
  grid-column: 1/13;
  grid-column-gap: 1.25rem;
  grid-template-columns: repeat(12, 1fr);
  margin: 0 auto;
  width: 100%;
`;

const H3 = styled.h3`
  color: ${({ colors }) => colors.secondary};
  font-family: proxima-nova, sans-serif;
  margin-bottom: 1.85rem;

  @media screen and (min-width: 890px) {
    grid-column: 2/4;
  }

  @media screen and (min-width: 2500px) {
    grid-column: 3/4;
  }
`;

const ContactDetails = styled.ul`
  grid-column: 1/13;
  list-style-type: none;
  margin-bottom: 2.5rem;

  @media screen and (min-width: 890px) {
    grid-column: 2/6;
  }

  @media screen and (min-width: 2500px) {
    grid-column: 3/7;
  }

  li {
    display: block;
    font-size: 0.875rem;
    margin-bottom: 0.75em;

    @media screen and (min-width: 25rem) {
      white-space: nowrap;
    }

    a {
      color: #c4c4c4;
      text-decoration: underline;
    }

    &::before {
      margin-right: 0.75em;
    }

    &:nth-of-type(1)::before {
      content: url(${EmailSVG});
    }

    &:nth-of-type(2)::before {
      content: url(${MarkSVG});
    }

    &:nth-of-type(3)::before {
      content: url(${WebsiteSVG});
    }
  }
`;

const SocialMedia = styled.div`
  display: grid;
  grid-column: 1/13;
  grid-gap: 1.25rem;
  grid-template-columns: repeat(auto-fit, 2rem);

  a {
    align-items: center;
    background-color: ${({ colors }) => colors.primary};
    border-radius: 50%;
    display: inline-flex;
    height: 2rem;
    justify-content: center;
  }

  @media screen and (min-width: 890px) {
    grid-template-columns: repeat(auto-fit, 2.5rem);
    grid-column: 2/6;
    grid-row: 3/4;

    a {
      height: 2.5rem;
    }
  }

  @media screen and (min-width: 2500px) {
    grid-column: 3/7;
  }
`;

const StyledGetBroadcastingLogo = styled.a`
  grid-column: 1/12;
  margin: 3.25rem 0 3rem;

  svg {
    max-width: 20rem;
    min-width: 13rem;
    width: 100%;
  }

  @media screen and (min-width: 890px) {
    grid-column: 9/12;
    grid-row: 1/3;
    margin: unset;
    justify-self: end;
  }

  @media screen and (min-width: 2500px) {
    grid-column: 8/11;
  }
`;

const StyledAgencyXLogo = styled.a`
  grid-column: 1/12;
  margin-bottom: 2rem;

  svg {
    max-width: 13rem;
    width: 100%;
  }

  @media screen and (min-width: 890px) {
    align-self: end;
    grid-column: 9/12;
    grid-row: 2/3;
    margin-bottom: 2.45rem;
    justify-self: end;
  }

  @media screen and (min-width: 2500px) {
    grid-column: 8/11;
  }
`;

const Copywright = styled.p`
  color: #c4c4c4;
  font-size: 0.875rem;
  grid-column: 1/12;
  line-height: 1.429em;
  white-space: nowrap;

  @media screen and (min-width: 890px) {
    align-self: end;
    grid-column: 7/12;
    grid-row: 3/4;
    text-align: right;
  }

  @media screen and (min-width: 2500px) {
    grid-column: 9/11;
  }
`;

const SmallPrint = styled.section`
  background: #fff;
  display: grid;
  grid-column: 1/13;
  grid-column-gap: 1.25rem;
  grid-row: 2;
  grid-template-columns: repeat(12, 1fr);
  padding: 1rem 1.25rem;

  p:first-of-type {
    grid-column: 1/13;
    font-size: 0.875rem;
    margin-bottom: 2em;
    max-width: 369px;
  }

  p:last-of-type {
    align-self: center;
    font-size: 0.75rem;
    grid-column: 1/13;
    grid-row: 2;
    justify-self: start;

    a {
      color: #000;
      text-decoration: underline;

      &:first-of-type {
        margin-right: 1rem;
      }
    }
  }

  @media screen and (min-width: 890px) {
    padding: 1rem 0;

    p:first-of-type,
    p:last-of-type {
      grid-row: 1;
    }

    p:first-of-type {
      grid-column: 2/10;
      margin-bottom: unset;
      margin-right: 3rem;
      max-width: unset;
    }

    p:last-of-type {
      grid-row: 1;
      grid-column: 8/12;
      justify-self: end;
    }
  }

  @media screen and (min-width: 2500px) {
    p:first-of-type {
      grid-column: 3/8;
    }

    p:last-of-type {
      grid-column: 8/11;
    }
  }
`;

export default Footer;
