import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { Button } from 'components';
import { FirebaseContext, LocalContext, SmootherContext } from 'context';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql } from 'gatsby';
import { motion, AnimatePresence } from 'framer-motion';
import { scrollToRef } from 'utils';
import { useLocation } from '@reach/router';
import ReactPlayer from 'react-player/lazy';
import { Markup } from 'interweave';

function VideoGallery({ colors }) {
  const [playingVideoSrc, setPlayingVideoSrc] = useState('/videos/gallery_video_3.mp4');
  const { videosRef, contactRef } = useContext(LocalContext);
  const { user } = useContext(FirebaseContext);
  const smoother = useContext(SmootherContext);
  const { pathname } = useLocation();

  const { videos } = useStaticQuery(graphql`
    query {
      videos: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(src/data/video-gallery)/" } }
        sort: { fields: frontmatter___src, order: [ASC] }
      ) {
        edges {
          node {
            id
            frontmatter {
              title
              description
              src
              thumbnail {
                childImageSharp {
                  gatsbyImageData(width: 192, placeholder: BLURRED)
                }
              }
              gated
            }
          }
        }
      }
    }
  `);

  const selectedVideo = videos.edges.find(
    ({ node: video }) => playingVideoSrc === video.frontmatter.src
  ).node;

  return (
    <Container ref={videosRef} pathname={pathname}>
      <H3 colors={colors}>
        Video Gallery
        <div />
      </H3>
      <StyledVideo colors={colors}>
        <ReactPlayer
          url={playingVideoSrc}
          playing
          muted
          controls
          width="100%"
          height="100%"
          style={{ position: 'absolute', top: 0, left: 0 }}
        />
      </StyledVideo>
      <Thumbnails colors={colors}>
        {videos.edges
          .filter(({ node: video }) => (user ? video : !video.frontmatter.gated))
          .map(({ node: video }, i) => (
            <span
              key={video.id}
              tabIndex={-1}
              role="presentation"
              onClick={() => setPlayingVideoSrc(video.frontmatter.src)}>
              <Thumbnail
                image={getImage(video.frontmatter.thumbnail)}
                colors={colors}
                alt={`Virtuosity Gallery Video ${i}`}
              />
              <AnimatePresence>
                {playingVideoSrc === video.frontmatter.src && <Overlay>Now Playing</Overlay>}
              </AnimatePresence>
            </span>
          ))}
      </Thumbnails>
      <Text>
        <VideoTitle>{selectedVideo.frontmatter.title}</VideoTitle>
        <HR colors={colors} />
        <VideoDescription>
          <Markup content={selectedVideo.frontmatter.description} />
        </VideoDescription>
        <Button
          width="146px"
          height="40px"
          colors={colors}
          glow
          onClick={() =>
            scrollToRef({ smoother, ref: contactRef, duration: 1, position: 'bottom bottom' })
          }>
          Contact Us
        </Button>
      </Text>
    </Container>
  );
}

const Container = styled.section`
  background-color: rgba(0, 0, 0, ${({ pathname }) => (pathname === '/' ? 0.8 : 0)});
  color: #fff;
  display: grid;
  grid-column: 1/13;
  grid-column-gap: 20px;
  grid-template-columns: repeat(12, 1fr);
  padding: 4.125rem 20px 3.938rem;
  position: relative;
  z-index: 1;

  @media screen and (min-width: 1100px) {
    padding: 4.125rem 0 3.938rem;
  }
`;

const H3 = styled.h3`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 1.5rem;
  grid-column: 6/8;
  grid-row: 1/2;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: 100%;

  > div {
    background: ${({ colors }) => colors.primary};
    box-shadow: 0px 1px 7px #e3007d, 0px 0px 4px #db017b;
    height: 2px;
    margin: 8px 0 50px;
    width: 158px;
  }
`;

const StyledVideo = styled.div`
  border: 0.188rem solid ${({ colors }) => colors.primary};
  border-radius: 1.25rem;
  grid-column: 1/13;
  grid-row: 2/3;
  height: 100%;
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
  width: 100%;

  @media screen and (min-width: 1100px) {
    grid-column: 2/9;
  }

  @media screen and (min-width: 2500px) {
    grid-column: 3/8;
  }
`;

const Thumbnails = styled.div`
  display: flex;
  grid-column: 1 / 13;
  grid-row: 3/4;
  justify-content: center;
  margin-top: 18px;

  @media screen and (min-width: 1100px) {
    margin: 18px 0 0;
    grid-row: 3;
    grid-column: 2 / 9;
    justify-content: flex-start;
  }

  @media screen and (min-width: 2500px) {
    grid-column: 3/8;
  }

  > span {
    cursor: pointer;
    flex-shrink: 1;
    margin: 0 9px 18px 0;
    max-width: 192px;
    position: relative;

    @media screen and (max-width: 1099px) {
      &:last-of-type {
        margin: 0 0px 18px 0;
      }
    }
  }
`;

const Thumbnail = styled(GatsbyImage)`
  border: 2px solid ${({ colors }) => colors.primary};
  border-radius: 20px;
`;

const Overlay = styled(motion.div).attrs({
  initial: {
    opacity: 0
  },
  animate: {
    opacity: 1
  }
})`
  align-items: center;
  background: rgba(228, 9, 126, 0.5);
  border-radius: 20px;
  border: 1px solid transparent;
  box-shadow: 0px 0px 5px #db017b, 0px 0px 8px #e3007d;
  color: #fff;
  display: flex;
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  font-size: 1rem;
  height: 100%;
  justify-content: center;
  left: 0;
  padding: 0.5em;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;
`;

const Text = styled.section`
  grid-column: 1/13;
  grid-row: 4;
  margin: 1rem 0 0 0;

  @media screen and (min-width: 768px) {
    grid-column: 1/8;
  }

  @media screen and (min-width: 1100px) {
    grid-row: 2/3;
    grid-column: 9/12;
    margin: 1rem 0 0 1.5rem;
  }

  @media screen and (min-width: 2500px) {
    grid-column: 8/10;
  }
`;

const VideoTitle = styled.h3`
  font-size: 1.5rem;
  line-height: 29px;
  position: relative;
`;

const VideoDescription = styled.p`
  font-size: 1rem;
  margin-bottom: 2rem;
`;

const HR = styled.div`
  border-bottom: 0.125rem solid ${({ colors }) => colors.primary};
  box-shadow: 0px 1px 7px #e3007d, 0px 0px 4px #db017b;
  margin: 0.75rem 0 1.25rem;
  width: 80px;
`;

export default VideoGallery;
