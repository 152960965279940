import React, { useContext, useState, useEffect } from 'react';
import { Button, ErrorMessage, LoadingSpinner, Modal, LoginModal } from 'components';
import styled from 'styled-components';
import { LocalContext, FirebaseContext } from 'context';
import Autocomplete from 'react-google-autocomplete';
import BaseSelect from 'react-select';
import { motion, AnimatePresence } from 'framer-motion';
import { hexToRGB, capitaliseFirstLetterOfText, checkIfNameIncludesOnlyStandardChars } from 'utils';
import { fadeInAndOutVariants, fadeInAndOutAndAnimateHeightVariants, glowModal } from 'styles';
import { FormContainer, FormInput } from '../FormComponents';
// import FixRequiredSelect from '../FixRequiredSelect';

// function FormSelect(props) {
//   return <FixRequiredSelect {...props} SelectComponent={BaseSelect} options={props.options} />;
// }

const resetForm = ({ siteName }) => ({
  name: '',
  email: '',
  company: '',
  profession: '',
  termsAndConditions: false,
  referringUrl:
    typeof window !== 'undefined' &&
    JSON.parse(window.localStorage.getItem(`referringUrl${siteName}`))?.data
});

// const professionOptions = [
//   {
//     value: 'Consultant',
//     label: 'Consultant'
//   },
//   {
//     value: 'Intern',
//     label: 'Intern'
//   },
//   {
//     value: 'Nurse',
//     label: 'Nurse'
//   },
//   {
//     value: 'Pharmacist',
//     label: 'Pharmacist'
//   },
//   {
//     value: 'Physician',
//     label: 'Physician'
//   },
//   {
//     value: 'Registrar',
//     label: 'Registrar'
//   },
//   {
//     value: 'Technician',
//     label: 'Technician'
//   }
// ];

// const customDropdownMenuStyles = (colors) => ({
//   container: (provided, state) => ({
//     ...provided,
//     alignItems: 'center',
//     background: 'transparent',
//     border: state.isFocused ? '0.188em solid #fff' : '0.063em solid #fff',
//     display: 'flex',
//     fontFamily: "'Noto Sans', sans-serif",
//     fontSize: '1rem',
//     fontWeight: 'bold',
//     height: '3.75rem',
//     letterSpacing: '0.031rem',
//     padding: '0'
//   }),
//   control: (provided) => ({
//     ...provided,
//     background: 'transparent',
//     border: 'none',
//     boxShadow: 'none',
//     width: '100%',
//     padding: '0 0 0 1.25rem',
//     cursor: 'pointer',
//     height: '100%'
//   }),
//   dropdownIndicator: (provided) => ({
//     ...provided,
//     color: '#fff',
//     '&:hover': {
//       color: '#fff'
//     }
//   }),
//   indicatorSeparator: () => ({
//     display: 'none'
//   }),
//   input: (provided) => ({
//     ...provided,
//     color: '#fff'
//   }),
//   menu: (provided) => ({
//     ...provided,
//     background: colors.secondary,
//     border: '0.214em solid #fff',
//     borderRadius: '0',
//     fontFamily: "'Noto Sans', sans-serif",
//     fontSize: '0.875rem',
//     fontWeight: 'bold',
//     left: '-0.214em',
//     letterSpacing: '0.071em',
//     margin: '0',
//     width: 'calc(100% + 0.429em)'
//   }),
//   noOptionsMessage: (provided) => ({
//     ...provided,
//     color: '#fff'
//   }),
//   option: (provided, state) => ({
//     ...provided,
//     backgroundColor: state.isSelected ? colors.tertiary : 'transparent',
//     paddingLeft: '1.25rem',
//     '&:hover': {
//       cursor: 'pointer',
//       backgroundColor: colors.primary
//     }
//   }),
//   placeholder: (provided) => ({
//     ...provided,
//     fontFamily: "'Noto Sans', sans-serif",
//     fontStyle: 'italic',
//     fontWeight: 'bold',
//     fontSize: '1rem',
//     color: 'white'
//   }),
//   singleValue: (provided) => ({
//     ...provided,
//     color: '#fff'
//   }),
//   valueContainer: (provided) => ({
//     ...provided,
//     padding: '0'
//   })
// });

const includesSurname = (name) => /\s/.test(name.trim());

function RegistrationForm({ event, colors }) {
  const { theme, siteName } = useContext(LocalContext);
  const { firebase } = useContext(FirebaseContext);
  const { setANewUserHasRegisteredForAnEvent } = useContext(LocalContext);
  const [formValues, setFormValues] = useState(resetForm({ siteName }));
  const [errorMessage, setErrorMessage] = useState('');
  const [userAlreadyExists, setUserAlreadyExists] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState({
    submitting: false,
    submitted: false
  });
  const [disableSubmitButtonWhileCheckingEmailAddress, setDisableSubmitButtonWhileCheckingInputs] =
    useState(false);
  let isMounted = true;
  let debounce;
  const debounceTimeout = 750;

  useEffect(
    () => () => {
      isMounted = false;
    },
    []
  );

  useEffect(() => {
    const { name } = formValues;
    if (name) {
      setDisableSubmitButtonWhileCheckingInputs(true);
      debounce = setTimeout(() => {
        if (!checkIfNameIncludesOnlyStandardChars(name)) {
          setErrorMessage('Please include only standard characters in your name');
        } else if (!includesSurname(name)) {
          setErrorMessage('Please include a surname');
        }
        setDisableSubmitButtonWhileCheckingInputs(false);
      }, debounceTimeout);
    }
    return () => clearTimeout(debounce);
  }, [formValues.name]);

  useEffect(() => {
    const { email } = formValues;
    if (email) {
      setDisableSubmitButtonWhileCheckingInputs(true);
      debounce = setTimeout(async () => {
        try {
          const { data: _userAlreadyExists } = await firebase.checkIfUserAlreadyExists({ email });
          if (_userAlreadyExists) {
            setUserAlreadyExists(true);
            throw new Error(
              "This email address already belongs to a Virtuosity account. Click 'Login' above if it belongs to you."
            );
          } else if (userAlreadyExists) {
            setUserAlreadyExists(false);
            setErrorMessage('');
          }
        } catch (error) {
          setErrorMessage(error.message);
        } finally {
          setDisableSubmitButtonWhileCheckingInputs(false);
        }
      }, debounceTimeout);
    }
    return () => clearTimeout(debounce);
  }, [formValues.email]);

  const handleInputChange = (e) => {
    e.persist();
    const { name, type, checked, value } = e.target;
    if (errorMessage && !userAlreadyExists && name !== 'email') {
      setErrorMessage('');
    }
    setFormValues((currentStates) => ({
      ...currentStates,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleCompanyAutocompleteSelection = (res) => {
    const { name } = res;
    if (errorMessage && !userAlreadyExists) {
      setErrorMessage('');
    }
    setFormValues((currentStates) => ({
      ...currentStates,
      company: name.split(',')[0]
    }));
  };

  async function handleSubmit(e) {
    e.preventDefault();

    if (errorMessage || submissionStatus.submitting) {
      return;
    }

    const { name, email, company, profession, termsAndConditions } = formValues;

    if (!name) {
      setErrorMessage('Please include your name');
      return;
    }

    if (!includesSurname(name)) {
      setErrorMessage('Please include a surname');
      return;
    }

    if (!email) {
      setErrorMessage('Please include an email address');
      return;
    }

    if (userAlreadyExists) {
      setErrorMessage(
        "This email address already belongs to a Virtuosity account. Click 'Login' above if it belongs to you."
      );
      return;
    }

    if (!profession) {
      setErrorMessage('Please include your profession');
      return;
    }

    if (!company) {
      setErrorMessage('Please include your workplace name');
      return;
    }

    // if (extraFields?.includes('address') && !address) {
    //   setErrorMessage('Please enter your address');
    //   return;
    // }

    // if (extraFields?.includes('company') && !company) {
    //   setErrorMessage('Please enter your company or institution name');
    //   return;
    // }

    // if (!termsAndConditions) {
    //   setErrorMessage('You must agree to our Terms & Conditions to register');
    //   return;
    // }

    try {
      setSubmissionStatus({
        submitting: true,
        submitted: false
      });

      const actionCodeSettings = {
        url: window.location.href,
        handleCodeInApp: true
      };

      const firstName = name.split(' ')[0];

      const { data } = await firebase.sendSignInWithMagicLinkEmail({
        firstName: capitaliseFirstLetterOfText(firstName),
        email,
        actionCodeSettings
      });

      if (data.Message === 'OK') {
        setSubmissionStatus({
          submitting: false,
          submitted: true
        });
        window.localStorage.setItem(
          `newUser${siteName}`,
          JSON.stringify({
            ...formValues,
            actionCodeSettings
          })
        );
        setANewUserHasRegisteredForAnEvent(true);
      } else {
        setSubmissionStatus({
          submitting: false,
          submitted: false
        });
        setErrorMessage('Error sending email. Please try again');
      }

      setFormValues(resetForm({ siteName }));
    } catch (error) {
      console.error(error);

      if (isMounted) {
        setErrorMessage(error.message);
      }

      setSubmissionStatus({
        submitting: false,
        submitted: false
      });
    }
  }

  return (
    <StyledFormContainer onSubmit={(e) => handleSubmit(e)} colors={colors} $theme={theme}>
      <div
        style={{
          fontSize: '1.75rem',
          fontWeight: 600,
          lineHeight: '1.75rem',
          marginBottom: '0.55rem',
          textAlign: 'center'
        }}>
        Register Here
      </div>
      <div
        style={{
          color: `#fff`,
          display: 'flex',
          fontSize: '0.875rem',
          justifyContent: 'center',
          marginBottom: '2.625em'
        }}>
        Already have an account?&nbsp;
        <Modal
          trigger={
            <span
              style={{
                fontSize: '0.875rem',
                textAlign: 'center',
                marginBottom: '1.5em',
                textDecoration: 'underline'
              }}>
              Click here!
            </span>
          }
          disableCloseOnClickOutside
          hideCloseButton
          modalContent={<LoginModal colors={colors} />}
        />
      </div>
      <div
        style={{
          position: 'relative',
          display: 'grid',
          gridTemplateRows: '1fr',
          gridTemplateColumns: '1fr'
        }}>
        <motion.div
          style={{
            gridRow: 1,
            gridColumn: 1
          }}
          variants={fadeInAndOutVariants()}
          initial="initial"
          animate="animate"
          exit="exit">
          <Fields>
            <FormInput
              id="name"
              name="name"
              onChange={handleInputChange}
              placeholder="Full Name*"
              type="text"
              value={formValues.name}
              required
            />
            <FormInput
              id="email"
              name="email"
              onChange={handleInputChange}
              placeholder="Email*"
              type="email"
              value={formValues.email}
              required
            />
            <FormInput
              id="profession"
              name="profession"
              onChange={handleInputChange}
              placeholder="Role/Profession*"
              type="text"
              value={formValues.profession}
              required
            />
            {/* <FormSelect
              id="profession"
              name="profession"
              required
              defaultValue={formValues.profession}
              value={professionOptions.filter(({ value }) => value === formValues.profession)}
              controlShouldRenderValue
              onChange={({ value }) => {
                setFormValues((currentStates) => ({
                  ...currentStates,
                  profession: value
                }));
              }}
              placeholder="Role/Profession*"
              styles={customDropdownMenuStyles(colors)}
              options={professionOptions}
            /> */}
            <FormCompanyAutoComplete
              id="company"
              name="company"
              apiKey={process.env.GATSBY_GOOGLE_MAPS_API_KEY}
              options={{
                types: ['establishment'],
                componentRestrictions: { country: 'ie' },
                fields: ['name']
              }}
              onChange={handleInputChange}
              onPlaceSelected={handleCompanyAutocompleteSelection}
              placeholder="Company/Institution*"
              type="select"
              required
              value={formValues.company}
            />
          </Fields>
          {/* <FormChecks>
            <FormInputLabel hide htmlFor="termsAndConditions">
              Terms And Conditions
            </FormInputLabel>
            <FormCheckbox
              $theme={theme}
              checked={formValues.termsAndConditions}
              id="termsAndConditions"
              name="termsAndConditions"
              onChange={handleInputChange}
              required
              colors={colors}
              type="checkbox"
            />
            <FormTerms>
              {event?.termsAndConditions ||
                'This event is reserved exclusively for healthcare professionals resident in the Republic of Ireland. I confirm that I am a healthcare professional resident in the Republic of Ireland.*'}
            </FormTerms>
          </FormChecks> */}
          <ErrorMessage
            errorMessage={errorMessage}
            style={{ color: colors.tertiary }}
            variants={fadeInAndOutAndAnimateHeightVariants()}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '2.45rem'
            }}>
            {submissionStatus.submitted ? (
              <div
                style={{
                  textAlign: 'center',
                  marginBottom: '0.5rem',
                  color: colors?.primary || '#fff',
                  width: '90%'
                }}>
                Check your inbox to verify your email address and complete your registration.
                Can&apos;t find our email? Be sure to check your junk!
              </div>
            ) : (
              <Button
                type="submit"
                glow
                width="7.625rem"
                whileTap={{ scale: 0.95 }}
                disabled={userAlreadyExists || disableSubmitButtonWhileCheckingEmailAddress}
                colors={colors}
                style={{
                  alignItems: 'center',
                  alignSelf: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  position: 'relative'
                }}>
                <AnimatePresence>
                  {submissionStatus.submitting && (
                    <LoadingSpinner
                      style={{ width: '2rem', color: '#fff', position: 'absolute' }}
                    />
                  )}
                </AnimatePresence>
                <AnimatePresence>
                  {!submissionStatus.submitting && (
                    <motion.span
                      variants={fadeInAndOutVariants()}
                      initial="initial"
                      animate="animate"
                      exit="exit">
                      Submit
                    </motion.span>
                  )}
                </AnimatePresence>
              </Button>
            )}
          </div>
        </motion.div>
      </div>
    </StyledFormContainer>
  );
}

const StyledFormContainer = styled(FormContainer)`
  background: #000;
  border: 0.125rem solid ${({ colors }) => colors.tertiary};
  box-shadow: 0.375rem 0.375rem 1.25rem 0.188rem
    ${({ colors }) => hexToRGB({ color: colors.tertiary, alpha: 0 })};
  ${({ colors }) => glowModal(colors.secondary)}

  input {
    margin-bottom: 0;
  }

  button {
    background: ${({ colors }) => colors.secondary};
  }
`;

const Fields = styled.div`
  display: grid;
  grid-row-gap: 1.625rem;
  grid-template-columns: 1fr;
  margin-bottom: 1.625rem;
`;

const FormCompanyAutoComplete = styled(Autocomplete)`
  align-items: center;
  background: transparent;
  border: 0.063rem solid #fff;
  color: white !important;
  display: flex;
  font-family: 'Noto Sans', sans-serif;
  font-size: 1rem;
  font-weight: bold;
  height: 3.75rem;
  letter-spacing: 0.031rem;
  outline: none;
  padding: 0 1.25rem;

  ::placeholder {
    color: white !important;
    font-family: 'Noto Sans', sans-serif;
    font-size: 1rem;
    font-style: italic;
    font-weight: bold;
  }
`;

export default RegistrationForm;
