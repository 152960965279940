import { gsap } from 'gsap';

const scrollToRef = ({
  smoother,
  ref,
  position = 'center center',
  duration = 1.5,
  ease = 'power3'
}) => {
  gsap.to(smoother, {
    scrollTop: smoother.offset(ref.current, position),
    duration,
    ease
  });
};

export default scrollToRef;
