import * as React from 'react';

function SvgTwitterFooterIcon(props) {
  return (<svg viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#twitter_footer_icon_svg__a)" fill="#fff">
      <path d="M14.531 1.385c-.469.184-.937.369-1.5.369.281 0 .563-.462.75-.646.188-.277.375-.554.469-.923V.092h-.094c-.562.277-1.125.554-1.781.646h-.094c0 .093-.094 0-.094 0l-.843-.553C10.875.092 10.5 0 10.03 0s-.844.185-1.219.37a4.16 4.16 0 0 0-1.03.738c-.282.369-.47.83-.563 1.2-.094.461-.094.83-.094 1.292 0 .092 0 .092-.094.092C4.688 3.231 2.72 2.4 1.125.646c-.094-.092-.094-.092-.188 0-.75 1.016-.375 2.677.47 3.508.093.092.28.184.374.277-.093 0-.656-.093-1.125-.277-.094 0-.094 0-.094.092v.37c.094 1.015.844 2.03 1.875 2.4.188 0 .282.092.376.092-.188.092-.47.092-1.126 0-.093 0-.093 0-.093.092.562 1.385 1.593 1.754 2.437 2.03h.282c-.282.37-1.22.74-1.594.832-.75.277-1.688.369-2.438.277H.094v.092l.562.277c.563.277 1.125.461 1.688.646 3 .83 6.375.185 8.531-1.939 1.781-1.753 2.344-4.061 2.344-6.461 0-.092.094-.185.187-.185.469-.369.844-.738 1.125-1.2.094 0 0-.092 0-.184.094 0 0 0 0 0Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.781 11.815c0-.092.282 0 0 0 0 0 .094 0 0 0Z"
      />
    </g>
    <defs>
      <clipPath id="twitter_footer_icon_svg__a">
        <path fill="#fff" d="M0 0h15v12H0z" />
      </clipPath>
    </defs>
  </svg>)
}

export default SvgTwitterFooterIcon;
