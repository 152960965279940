import * as React from 'react';

function SvgLinkedinFooterIcon(props) {
  return (<svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#linkedin_footer_icon_svg__a)">
      <path
        d="M3.08 13.907H.187V4.573H3.08v9.334ZM1.68 3.36C.747 3.36 0 2.613 0 1.68 0 .747.747 0 1.68 0c.933 0 1.68.747 1.68 1.68 0 .933-.747 1.68-1.68 1.68Zm12.227 10.547h-2.894v-4.48c0-1.12 0-2.427-1.493-2.427S7.747 8.213 7.747 9.427V14h-2.8V4.667h2.8v1.306c.373-.746 1.306-1.493 2.706-1.493 2.894 0 3.454 1.96 3.454 4.387v5.04Z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="linkedin_footer_icon_svg__a">
        <path fill="#fff" d="M0 0h14v14H0z" />
      </clipPath>
    </defs>
  </svg>)
}

export default SvgLinkedinFooterIcon;
