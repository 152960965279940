import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FirebaseContext } from 'context';
import { AnimatePresence, motion } from 'framer-motion';
import { PinComment } from 'components/Events/Interaction/Chat';
import { useIntersection } from 'hooks';
import { hexToRGB } from 'utils';
import { format } from 'date-fns';
import { Star, Upvote } from 'assets/animated-svgs';

function SubmittedQuestion({
  eid,
  submittedQuestion,
  selectedQuestion,
  setSelectedQuestion,
  submittedQuestionsRef,
  index,
  arr,
  colors
}) {
  const { firebase, user } = useContext(FirebaseContext);
  const [upvotedAlready, setUpvotedAlready] = useState(false);

  const [ref, observerEntry] = useIntersection({
    root: submittedQuestionsRef.current,
    threshold: 1
  });

  useEffect(() => {
    if (submittedQuestion.upvotes?.upvoters?.length > 0) {
      if (submittedQuestion.upvotes.upvoters.includes(user.uid)) {
        setUpvotedAlready(true);
      }
    }
  }, [submittedQuestion]);

  useEffect(() => {
    if (
      user?.isModerator?.includes(eid) &&
      !submittedQuestion.seen.status &&
      observerEntry.isIntersecting
    ) {
      firebase.markQuestionSeen({ uid: submittedQuestion.uid, qid: submittedQuestion.qid });
    }
  }, [user?.isModerator, observerEntry.isIntersecting]);

  const handleQuestionSelection = () => {
    if (user?.isModerator?.includes(eid)) {
      if (selectedQuestion?.qid === submittedQuestion.qid) {
        setSelectedQuestion(null);
      } else {
        setSelectedQuestion(submittedQuestion);
      }
    }
  };

  const handleStarQuestion = ({ e, uid, qid, isStarred }) => {
    e.stopPropagation();
    if (isStarred) {
      firebase.unstarQuestion({ uid, qid });
    } else {
      firebase.starQuestion({ uid, qid });
    }
  };

  const handleUpvote = ({ e, quid, uid, qid }) => {
    e.stopPropagation();
    if (!upvotedAlready) {
      firebase.upvoteQuestion({ quid, uid, qid });
    }
  };

  return (
    <Wrapper
      layout="position"
      layoutDependency={index}
      ref={ref}
      colors={colors}
      isStarred={submittedQuestion.starred.status}
      isModerator={user?.isModerator?.includes(eid)}
      isSelected={selectedQuestion?.qid === submittedQuestion.qid}
      onClick={handleQuestionSelection}>
      {user?.isModerator?.includes(eid) && (
        <SelectQuestionCheckbox
          checked={selectedQuestion?.qid === submittedQuestion.qid}
          id="selectQuestionCheckbox"
          name="selectQuestionCheckbox"
          colors={colors}
          type="checkbox"
          readOnly
        />
      )}
      <p>
        <span>
          {user?.uid === submittedQuestion.uid
            ? `My Question${arr.length > 1 ? ` (${arr.length - index})` : ''}`
            : submittedQuestion.name
            ? `${submittedQuestion.name}`
            : 'Anonymous User'}
        </span>
        {submittedQuestion.timestamp && (
          <span>{format(submittedQuestion.timestamp.toDate(), 'HH:mmaaa')}</span>
        )}
      </p>
      <p>{submittedQuestion.text}</p>
      {!user?.isModerator?.includes(eid) && user.uid !== submittedQuestion.uid && (
        <UpvoteQuestion
          color={upvotedAlready ? colors.tertiary : colors.secondary}
          cursor={upvotedAlready ? 'auto' : 'pointer'}
          whileTap={!upvotedAlready && { scale: 0.9 }}
          onClick={(e) =>
            handleUpvote({
              e,
              qid: submittedQuestion.qid,
              quid: submittedQuestion.uid,
              uid: user.uid
            })
          }>
          <Upvote fill={upvotedAlready ? colors.tertiary : colors.secondary} />
          {submittedQuestion.upvotes.total.toString()}
        </UpvoteQuestion>
      )}
      {user?.isModerator?.includes(eid) && (
        <StarQuestion
          isStarred={submittedQuestion.starred.status}
          onClick={(e) =>
            handleStarQuestion({
              e,
              uid: submittedQuestion.uid,
              qid: submittedQuestion.qid,
              isStarred: submittedQuestion.starred.status
            })
          }>
          <Star
            stroke={submittedQuestion.starred.status ? colors.secondary : '#000'}
            fill={submittedQuestion.starred.status ? colors.secondary : 'transparent'}
          />
          {submittedQuestion.starred.status ? 'Unprioritise' : 'Prioritise'}
        </StarQuestion>
      )}
      {(user?.isModerator?.includes(eid) || user.uid === submittedQuestion.uid) && (
        <UpvoteQuestion
          color="#C4C4C4"
          cursor="auto"
          isModerator={user?.isModerator?.includes(eid)}>
          <Upvote fill="#C4C4C4" />
          {submittedQuestion.upvotes.total.toString()}
        </UpvoteQuestion>
      )}
      <AnimatePresence initial={false}>
        {submittedQuestion.answer && (
          <ModeratorsAnswer
            colors={colors}
            isModerator={user?.isModerator?.includes(eid)}
            isSelected={selectedQuestion?.qid === submittedQuestion.qid}>
            <p>
              <span>Moderator&apos;s Answer:</span>
              {submittedQuestion.answer.timestamp && (
                <span>{format(submittedQuestion.answer.timestamp.toDate(), 'HH:mmaaa')}</span>
              )}
            </p>
            <p>{submittedQuestion.answer.text}</p>
          </ModeratorsAnswer>
        )}
      </AnimatePresence>
    </Wrapper>
  );
}

const Wrapper = styled(motion.div).attrs({
  initial: {
    opacity: 0,
    height: 0
  },
  animate: {
    opacity: 1,
    height: 'auto',
    transition: {
      type: 'tween',
      ease: 'easeInOut',
      height: {
        duration: 0.4
      },
      opacity: {
        duration: 0.65,
        delay: 0.1
      }
    }
  },
  exit: {
    opacity: 0,
    height: 0,
    transition: {
      type: 'tween',
      ease: 'easeInOut',
      height: {
        duration: 0.4,
        delay: 0.55
      },
      opacity: {
        duration: 0.65
      }
    }
  }
})`
  background-color: ${({ isSelected, colors }) =>
    isSelected ? hexToRGB({ color: colors.secondary, alpha: 0.2 }) : 'transparent'};
  color: ${({ isSelected, colors }) => (isSelected ? colors.secondary : '#000')};
  cursor: ${({ isModerator }) => (isModerator ? 'pointer' : 'auto')};
  font-size: 1rem;
  margin-bottom: 0.688em;
  padding: 0.125em 0.25em 0.25em;
  position: relative;
  transition: background-color 200ms ease-in-out, color 200ms ease-in-out;

  p {
    padding-left: ${({ isModerator }) => (isModerator ? '1.375rem' : 0)};
  }

  p:first-of-type {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.313em;

    span:first-of-type {
      font-weight: 700;
    }

    span:last-of-type {
      color: ${({ isSelected }) => (isSelected ? '#000' : '#c4c4c4')};
      transition: color 200ms ease-in-out;
      font-size: 0.75em;
    }
  }

  small {
    color: ${({ isStarred, colors }) => (isStarred ? colors.secondary : '#000')};
    display: inline-block;
    font-size: 0.65rem;
    left: 0.95em;
    position: relative;
  }
`;

export const SelectQuestionCheckbox = styled.input`
  appearance: none;
  background-color: transparent;
  border: 1px solid #bdbdbd;
  cursor: pointer;
  height: 1rem;
  margin-top: 2px;
  outline: none;
  position: absolute;
  transition: all 0.1s;
  width: 1rem;

  &:focus {
    border: 2px solid ${({ colors }) => colors.secondary};
  }

  &:checked {
    border: 1px solid ${({ colors }) => colors.secondary};
  }

  &:checked:before {
    color: ${({ colors }) => colors.secondary};
    content: '✓';
    font-size: 0.8rem;
    height: 0.8rem;
    left: 50%;
    outline: none;
    position: absolute;
    top: 50%;
    transform: translate(-37%, -60%);
    width: 0.8rem;
  }
`;

const ModeratorsAnswer = styled(motion.div).attrs({
  initial: {
    opacity: 0,
    height: 0
  },
  animate: {
    opacity: 1,
    height: 'auto',
    transition: {
      type: 'tween',
      ease: 'easeInOut',
      height: {
        duration: 0.4
      },
      opacity: {
        duration: 0.65,
        delay: 0.1
      }
    }
  },
  exit: {
    opacity: 0,
    height: 0,
    transition: {
      type: 'tween',
      ease: 'easeInOut',
      height: {
        duration: 0.4,
        delay: 0.55
      },
      opacity: {
        duration: 0.65
      }
    }
  }
})`
  color: ${({ isSelected }) => (isSelected ? '#000' : '#c4c4c4')};
  cursor: ${({ isModerator }) => (isModerator ? 'pointer' : 'auto')};
  margin-top: 0.625rem;
  padding: 0.125rem 0 0.25rem 1rem;
  transition: color 200ms ease-in-out;
`;

const StarQuestion = styled(PinComment)`
  > svg {
    bottom: unset;
  }
`;

const UpvoteQuestion = styled(PinComment)`
  > svg {
    bottom: unset;
    font-size: 0.5rem;
    margin-top: 1px;
  }
  color: ${({ color }) => color} !important;
  cursor: ${({ cursor }) => cursor} !important;
  margin-left: ${({ isModerator }) => (isModerator ? '1.25rem' : '0.75rem')};
  text-decoration: none;
`;

export default SubmittedQuestion;
