import React, { useContext } from 'react';
import styled from 'styled-components';
import { LocalContext } from 'context';
import { Add } from 'assets/svgs';
import ICalendarLink from 'react-icalendar-link';

function DateButton({
  eventName,
  eventDescription,
  eventStartTime,
  eventEndTime,
  eventLocation,
  colors,
  transparent
}) {
  const { theme } = useContext(LocalContext);
  const eventDetails = {
    title: eventName,
    description: eventDescription,
    location: eventLocation,
    startTime: eventStartTime,
    endTime: eventEndTime
  };
  return (
    <StyledDateButton $theme={theme} colors={colors} width="250px" transparent={transparent}>
      <Add />
      <ICalendarLink
        event={eventDetails}
        style={{
          height: '3rem',
          background: 'transparent',
          margin: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginLeft: '-30px',
          position: 'relative',
          color: '#fff'
        }}>
        &nbsp;&nbsp;Add To Calendar
      </ICalendarLink>
    </StyledDateButton>
  );
}

const StyledDateButton = styled.div`
  align-items: center;
  background: transparent;
  border-radius: 30px;
  display: flex;
  height: 3.5rem;
  justify-content: center;
  margin-left: -4px;
  position: relative;
  width: 280px;
  z-index: 2;
  a {
    align-items: center;
    background-color: transparent;
    color: ${({ $theme, colors }) =>
      $theme.className === 'contrast' ? 'yellow' : colors.primary} !important;
    cursor: pointer;
    display: flex;
    font-family: Helvetica, sans-serif;
    font-size: 1rem;
    font-weight: 600;
    justify-content: flex-start;
    list-style: none;
    text-transform: uppercase;
    transition: 0.5s;
    width: ${(props) => (props.width ? props.width : '100%')};
  }
  svg {
    max-width: 1.5rem !important;
    circle {
      fill: ${({ $theme, colors }) =>
        $theme.className === 'contrast' ? 'yellow' : colors.primary};
    }
    path {
      stroke: ${({ $theme }) => $theme.bgLight};
    }
  }
`;

export default DateButton;
