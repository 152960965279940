import * as React from 'react';

function SvgBgMoon1(props) {
  return (<svg viewBox="0 0 246 246" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M91.265 214.191c48.283 16.733 100.971-7.208 120.482-53.587a100.69 100.69 0 0 1-12.85-3.515c-49.958-17.313-76.125-71.706-58.91-121.382.87-2.511 1.644-4.744 2.793-7.159C95.536 18.74 47.679 44.981 31.624 91.307c-17.504 50.513 9.124 105.378 59.64 122.884Z"
      fill="#36A9E1"
    />
  </svg>)
}

export default SvgBgMoon1;
