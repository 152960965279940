const getCaretCoordinates = (input, selectionPoint) => {
  const { offsetLeft: inputX, offsetTop: inputY } = input;
  const div = document.createElement('div');
  const copyStyle = getComputedStyle(input);
  const maxWidth = input.getBoundingClientRect().width - 28;
  // eslint-disable-next-line no-restricted-syntax
  for (const prop of copyStyle) {
    div.style[prop] = copyStyle[prop];
  }
  const swap = '.';
  const inputValue = input.tagName === 'INPUT' ? input.value.replace(/ /g, swap) : input.value;
  const textContent = inputValue.substr(0, selectionPoint);
  div.textContent = textContent;
  if (input.tagName === 'INPUT') div.style.width = 'calc(100% - 6.25rem)';
  const span = document.createElement('span');
  span.textContent = inputValue.substr(selectionPoint) || '.';
  div.appendChild(span);
  document.body.appendChild(div);
  const { offsetLeft: spanX, offsetTop: spanY } = span;
  document.body.removeChild(div);
  let maxSpanX;
  if (spanX > maxWidth) {
    maxSpanX = maxWidth;
  } else {
    maxSpanX = null;
  }
  return {
    x: inputX + (maxSpanX || spanX),
    y: inputY + spanY
  };
};

export default getCaretCoordinates;
