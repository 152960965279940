import React, { useContext, useState } from 'react';
import styled, { css } from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { LocalContext } from 'context';
import TextInput from 'components/Events/Interaction/Polls/Shared/TextInput';

function Options({
  allowMultipleAnswers,
  answers,
  enableTimer,
  handleMarkCorrectAnswersOption,
  isMarkCorrectAnswersEnabled,
  numberOfMultipleAnswersAllowed,
  setAllowMultipleAnswers,
  setEnableTimer,
  setNumberOfMultipleAnswersAllowed,
  setTimerSeconds,
  timerSeconds
}) {
  const { selectedEvent } = useContext(LocalContext);
  const [isMultipleAnswersDropdownMenuOpen, setIsMultipleAnswersDropdownMenuOpen] = useState(false);

  return (
    <Wrapper>
      <Option
        initial={{ color: '#4f4f4f' }}
        animate={{
          color:
            isMarkCorrectAnswersEnabled && answers.filter((answer) => answer.isCorrect).length === 0
              ? '#F55151'
              : '#4f4f4f'
        }}>
        <OptionCheckbox
          colors={selectedEvent.colors}
          isEnabled={isMarkCorrectAnswersEnabled}
          onClick={handleMarkCorrectAnswersOption}>
          <div>
            <div />
          </div>
        </OptionCheckbox>
        Mark correct answer/s
      </Option>
      <Option>
        <OptionCheckbox
          colors={selectedEvent.colors}
          isEnabled={allowMultipleAnswers}
          onClick={() => {
            if (isMultipleAnswersDropdownMenuOpen) {
              setIsMultipleAnswersDropdownMenuOpen(false);
            }
            setAllowMultipleAnswers((currentState) => !currentState);
          }}>
          <div>
            <div />
          </div>
        </OptionCheckbox>
        Allow to select multiple answers
        <AnimatePresence>
          {allowMultipleAnswers && (
            <MultipleOptionsSelector
              colors={selectedEvent.colors}
              onBlur={() => setIsMultipleAnswersDropdownMenuOpen(false)}
              onClick={() => setIsMultipleAnswersDropdownMenuOpen(true)}
              tabIndex={0}>
              {numberOfMultipleAnswersAllowed}
              <div
                style={{
                  borderLeft: '0.25em solid transparent',
                  borderRight: '0.25em solid transparent',
                  height: 0,
                  marginLeft: '0.417em',
                  width: 0
                }}
              />
              <AnimatePresence>
                {isMultipleAnswersDropdownMenuOpen && (
                  <MultipleOptionsDropdownMenu
                    colors={selectedEvent.colors}
                    onClick={(e) => {
                      e.stopPropagation();
                      setNumberOfMultipleAnswersAllowed(
                        e.target.innerHTML === 'All'
                          ? e.target.innerHTML
                          : parseInt(e.target.innerHTML, 10)
                      );
                      setIsMultipleAnswersDropdownMenuOpen(false);
                    }}>
                    <div>All</div>
                    {answers.map(
                      (answer, index, arr) =>
                        index !== 0 &&
                        index !== arr.length - 1 && <div key={answer.id}>{index + 1}</div>
                    )}
                  </MultipleOptionsDropdownMenu>
                )}
              </AnimatePresence>
            </MultipleOptionsSelector>
          )}
        </AnimatePresence>
      </Option>
      <Option>
        <OptionCheckbox
          colors={selectedEvent.colors}
          isEnabled={enableTimer}
          onClick={() => setEnableTimer((currentState) => !currentState)}>
          <div>
            <div />
          </div>
        </OptionCheckbox>
        Enable timer&nbsp;&nbsp;
        <AnimatePresence>
          {enableTimer && (
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <TimerSecondsInput
                type="number"
                min={20}
                max={999}
                value={timerSeconds}
                onChange={(e) => setTimerSeconds(parseInt(e.target.value, 10))}
              />
              &nbsp; sec
            </motion.div>
          )}
        </AnimatePresence>
      </Option>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  left: -6px;
  margin-top: 1.1rem;
  position: relative;
`;

const Option = styled(motion.div)`
  align-items: center;
  color: #4f4f4f;
  display: flex;
  font-size: 0.875rem;

  &:first-of-type {
    color: ${({ isMarkCorrectAnswersEnabled }) =>
      isMarkCorrectAnswersEnabled ? '#f55151' : '#4f4f4f'};
  }
`;

const OptionCheckbox = styled.div`
  cursor: pointer;
  margin-right: 8px;
  padding: 6px;

  > div {
    align-items: center;
    border: 1px solid ${({ isEnabled, colors }) => (isEnabled ? colors.secondary : '#c4c4c4')};
    display: flex;
    height: 1rem;
    justify-content: center;
    transition: border 200ms ease-in-out;
    width: 1rem;

    > div {
      background-color: ${({ isEnabled, colors }) =>
        isEnabled ? colors.secondary : 'transparent'};
      height: 8px;
      transition: background-color 200ms ease-in-out;
      width: 8px;
    }

    &,
    > div {
      border-radius: 2px;
    }
  }
`;

const MultipleOptionsSelector = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
})`
  align-items: center;
  border-radius: 14px;
  border: 1px solid #c4c4c4;
  cursor: pointer;
  display: flex;
  font-size: 0.75rem;
  height: 16px;
  justify-content: center;
  margin-left: 10px;
  padding-left: 1px;
  transition: color 2000ms solid ease-in-out;
  outline: none;
  position: relative;
  width: 41px;

  > div:first-of-type {
    border-top: 0.188rem solid #4f4f4f;
    transition: border-top 200ms solid ease-in-out;
  }

  :focus {
    border: 1px solid ${({ colors }) => colors.secondary};
    color: ${({ colors }) => colors.secondary};

    > div:first-of-type {
      border-top: 0.188rem solid ${({ colors }) => colors.secondary};
    }
  }
`;

const MultipleOptionsDropdownMenu = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
})`
  align-items: center;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 1px 1px 12px 2px rgba(196, 196, 196, 0.6);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  left: 8px;
  padding: 4px 0;
  position: absolute;
  top: 5px;
  width: 35px;

  > div {
    align-items: center;
    color: #4f4f4f;
    display: flex;
    font-size: 12px;
    height: 20.5px;
    justify-content: center;
    width: 100%;

    &:hover {
      color: ${({ colors }) => colors.secondary};
    }
  }
`;

const TimerSecondsInput = styled(TextInput)`
  margin-top: 1px;
  width: 40px;
`;

export default Options;
