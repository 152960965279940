import React, { useContext, useEffect, useState } from 'react';
import { LoadingSpinner } from 'components';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { FirebaseContext, LocalContext } from 'context';
import { hexToRGB, capitaliseFirstLetterOfText } from 'utils';
import { fadeInAndOutVariants } from 'styles';
import BarEmojis from './BarEmojis';

const findPercentage = (partialValue, totalValue) => Math.round((100 * partialValue) / totalValue);

const findMostPopularRating = (poll, answers) => {
  const ratings = [];
  poll?.ratingOption.ratingOptions.forEach((answer) =>
    ratings.push({
      rid: answer.rid,
      amount: answers[answer.rid]
    })
  );
  const mostPopular = ratings.reduce((a, b) => (a.amount > b.amount ? a : b));
  return mostPopular.rid;
};

function FeedbackAnalyticsModal({ poll, setIsAnalyticsModalOpen }) {
  const { selectedEvent } = useContext(LocalContext);
  const { eid } = selectedEvent;
  const { pid } = poll;
  const { firebase, user } = useContext(FirebaseContext);
  const [arePollAnalyticsLoading, setArePollAnalyticsLoading] = useState(true);
  const [pollAnalytics, setPollAnalytics] = useState(null);

  useEffect(() => {
    let unsubscribeFromPollAnalytics;

    if (firebase) {
      unsubscribeFromPollAnalytics = firebase.subscribeToPollAnalytics({
        eid,
        pid,
        snapshot: (snapshot) => {
          if (snapshot.exists()) {
            const { answers, totalParticipants } = snapshot.val();
            let count = 0;
            poll.ratingOption.ratingOptions.forEach((option) => {
              if (answers[option.rid]) {
                count += answers[option.rid] * (option.option + 1);
              }
            });
            const average = Math.round((count / totalParticipants) * 10) / 10;
            setPollAnalytics({
              answers,
              average,
              totalParticipants,
              mostPopularRating: findMostPopularRating(poll, answers)
            });
            setArePollAnalyticsLoading(false);
          } else if (!snapshot.exists()) {
            // TODO: No analytics. Do we need to do anything here?
          }
        }
      });
    }
    return () => {
      if (unsubscribeFromPollAnalytics) {
        unsubscribeFromPollAnalytics();
      }
    };
  }, [firebase, eid, pid]);

  return (
    <Underlay
      data-underlay
      colors={selectedEvent.colors}
      onClick={(e) => {
        if (e.target.dataset.underlay === 'true' && user?.isModerator?.includes(eid)) {
          setIsAnalyticsModalOpen(false);
        }
      }}>
      <Modal colors={selectedEvent.colors}>
        {user && !user?.isModerator?.includes(eid) && (
          <Title colors={selectedEvent.colors}>Poll Results</Title>
        )}
        <Question colors={selectedEvent.colors}>
          <p>{capitaliseFirstLetterOfText(poll.question.text)}</p>
        </Question>
        <AnimatePresence>
          {pollAnalytics && (
            <ParticipantsAndAnswers
              variants={fadeInAndOutVariants()}
              initial="initial"
              animate="animate"
              exit="exit">
              <Participants>
                Total participants: <span>{pollAnalytics.totalParticipants}</span>
              </Participants>
              {poll?.ratingOption.ratingOptions
                .map((answer) => (
                  <Answer
                    colors={selectedEvent.colors}
                    id={answer.rid}
                    key={answer.rid}
                    isCorrect={answer.isCorrect}>
                    <div>
                      {poll?.ratingOption?.ratingType === 'stars' && (
                        <p>{answer.option + 1} stars</p>
                      )}
                      {poll?.ratingOption?.ratingType === 'numbers' && <p>{answer.option + 1}</p>}
                      {poll?.ratingOption?.ratingType === 'emojis' && (
                        <BarEmojis poll={poll} pollAnalytics={pollAnalytics} answer={answer} />
                      )}
                      <PercentageAndVotes>
                        <Percentage>
                          {pollAnalytics.totalParticipants > 0 &&
                          pollAnalytics.answers[answer.rid] > 0
                            ? findPercentage(
                                pollAnalytics.answers[answer.rid],
                                pollAnalytics.totalParticipants
                              )
                            : 0}
                          %&nbsp;
                        </Percentage>
                        <Votes>{`(${pollAnalytics.answers[answer.rid] || 0} Votes)`}</Votes>
                      </PercentageAndVotes>
                    </div>
                    <Bar
                      colors={selectedEvent.colors}
                      percentage={findPercentage(
                        pollAnalytics.answers[answer.rid],
                        pollAnalytics.totalParticipants
                      )}>
                      <div />
                    </Bar>
                  </Answer>
                ))
                .reverse()}
            </ParticipantsAndAnswers>
          )}
        </AnimatePresence>
        {arePollAnalyticsLoading && (
          <LoadingSpinnerWrapper
            variants={fadeInAndOutVariants()}
            initial="initial"
            animate="animate"
            exit="exit">
            <LoadingSpinner style={{ width: '6rem', color: selectedEvent.colors.secondary }} />
          </LoadingSpinnerWrapper>
        )}
      </Modal>
    </Underlay>
  );
}

const Underlay = styled(motion.div).attrs(fadeInAndOutVariants())`
  backdrop-filter: blur(2px);
  background: rgba(0, 0, 0, 0.6);
  cursor: default;
  height: 100%;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 1.625rem 1.625rem;
  position: absolute;
  top: 0;
  width: 100%;

  ::-webkit-scrollbar {
    width: 0.5rem;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0.31rem grey;
    border-radius: 0.625rem;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ colors }) => colors.secondary};
    border-radius: 0.625rem;
  }
`;

const Modal = styled.div`
  background-color: #fff;
  margin: 1.125rem auto 0;
  max-width: 23.75rem;
  position: relative;
  z-index: 1000;

  @media screen and (min-width: 1600px) {
    margin: 3rem auto 0;
  }
`;

const Title = styled.div`
  background-color: ${({ colors }) => colors.secondary};
  color: #fff;
  font-weight: 700;
  padding: 0.75rem 1.5rem;
  text-align: center;
  width: 100%;
`;

const Question = styled.div`
  background-color: ${({ colors }) => hexToRGB({ color: colors.secondary, alpha: 0.2 })};
  color: ${({ colors }) => colors.secondary};
  padding: 0.75rem 1.5rem;
  width: 100%;

  p:nth-of-type(1) {
    font-weight: 700;
  }

  p:nth-of-type(2) {
    font-weight: 300;
  }
`;

const ParticipantsAndAnswers = styled(motion.div)`
  display: flex;
  flex-direction: column;
  padding: 1.063rem 1.1rem 1.875rem;
`;

const Participants = styled.p`
  color: #c4c4c4;
  line-height: 1.5rem;
  margin-bottom: 1.25rem;
  padding: 0 0.35rem;
  span {
    color: #000;
  }
`;

const Answer = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:last-of-type) {
    margin-bottom: 1.125rem;
  }

  > div:first-of-type {
    display: flex;
    justify-content: space-between;
    padding-left: 0.425rem;

    p {
      color: #000;
    }
  }
`;

const PercentageAndVotes = styled.div`
  white-space: nowrap;

  span {
    font-size: 0.75rem;
  }
`;

const Percentage = styled.span`
  color: #000;
`;

const Votes = styled.span`
  color: #c4c4c4;
`;

const Bar = styled.div`
  background-color: rgba(196, 196, 196, 0.2);
  border-radius: 2px;
  height: 0.25rem;
  margin-top: 0.375rem;
  width: 100%;
  > div {
    background-color: ${({ colors }) => colors.secondary};
    border-radius: 2px;
    height: 100%;
    transition: width 200ms ease-in-out;
    width: ${({ percentage }) => percentage || 0}%;
  }
`;

const LoadingSpinnerWrapper = styled(motion.div)`
  align-items: center;
  background-color: #fff;
  display: flex;
  height: 100%;
  justify-content: center;
  margin-top: 1.15rem;
  width: 100%;

  > svg {
    margin-bottom: 0.9rem;

    @media screen and (min-width: 410px) {
      margin-bottom: 1.125rem;
    }

    @media screen and (min-width: 1150px) and (max-width: 1365px) {
      margin-bottom: 0.9rem;
    }

    @media screen and (min-width: 1366px) {
      margin-bottom: 1.125rem;
    }
  }
`;

export default FeedbackAnalyticsModal;
