import React from 'react';
import styled from 'styled-components';

function Video({ src, colors, autoPlay = false, muted = false, controls, className }) {
  return (
    <Container
      colors={colors}
      autoPlay={autoPlay}
      muted={muted}
      className={className}
      controls={controls}>
      <source src={src} type="video/mp4" />
    </Container>
  );
}

const Container = styled.video`
  align-items: center;
  background: #000;
  border: 0.188rem solid ${({ colors }) => colors.primary};
  border-radius: 1.25rem;
  display: flex;
  justify-content: center;
`;

export default Video;
