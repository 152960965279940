import * as React from 'react';

function SvgFbIcon(props) {
  return (<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={12} cy={12} r={12} fill="#BDBDBD" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.402 12.05v6.087c0 .1.073.149.145.149h2.228c.097 0 .145-.075.145-.149v-6.186h1.598c.073 0 .145-.075.145-.149l.146-1.856c0-.099-.073-.173-.146-.173H12.92V8.46c0-.321.242-.569.557-.569h1.235c.097 0 .145-.074.145-.148V5.863c0-.074-.072-.149-.145-.149H12.63c-1.235 0-2.228 1.015-2.228 2.277v1.807H9.288c-.073-.025-.145.05-.145.123v1.856c0 .1.072.149.145.149h1.114v.124Z"
      fill="#fff"
    />
  </svg>)
}

export default SvgFbIcon;
